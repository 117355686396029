import { Box } from "@mui/material";
import Copyright from "../components/copyRight";

export default function SimpleLayout(props)
{
    return(
        <Box>
            <Box
                component='main'
                sx={{
                    flexGrow: 1,
                    //height: '100vh',
                    overflow: 'auto',
                    minHeight: '100vh'
                  }}
            >
                {props.children}
                
            </Box>
           
        </Box>
    )
}