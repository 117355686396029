import { Container, Grid, Paper, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import SectionTitle from "../../../../components/sectionTitle";

export default function DepositMethods()
{
    return(
        <Container>
            <CustomBreadcrumbs returnTo='/wallet' crumbs={[{name:'Billetera',link:'/wallet'},{name:'Metodos de Deposito',link:'/wallet/deposit/methods'}]}/>
            <br/>
            <SectionTitle title='Metodos de Deposito'/>
            <br/>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item lg ={3} xl={3}>
                    <Paper>
                        <Typography>
                            PayPal
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item lg ={3} xl={3}>
                    <Paper>
                        <Typography>
                            Tarjeta debito / credito
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item lg ={3} xl={3}>
                    <Paper>
                        <Typography>
                            Criptomonedas
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}