import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useState } from "react";

export default function MapGoogle(props)
{
    const [cameraProps,setCameraProps] = useState(props)
    return(
        <APIProvider
            style={{width: '100%', height: '100%'}}
            //apiKey={'AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE'}
        >
            {
                props.center &&
                <Map zoom={14} center={{lat:props.center.lat,lng:props.center.lng}} gestureHandling={'auto'}>
                    {
                        props.coordinates &&
                        <Marker position={{lat:props.coordinates.lat,lng:props.coordinates.lng}} />
                    }
                </Map>
            }
        </APIProvider>
    )
}