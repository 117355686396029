import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, IconButton, Link, Paper, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import QUDProgress from "../../../components/progress";

import generatePDF, { usePDF, Resolution, Margin } from 'react-to-pdf';
import { getTicket } from "../../../controllers/customer/EventosController";
import { imagesURL } from "../../../apiRoutes/apiBase";
import QRCode from "react-qr-code";
import { BorderColor } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";



export default function Ticket()
{
    const [isLoading,setIsLoading] = useState(true);
    const [ticket,setTicket] = useState(null);
    const { toPDF, targetRef } = usePDF({filename: 'qudTicketing.pdf'});
    const { uuid } = useParams();
    const [isHidden,setIsHidden] = useState(true);

    const theme = useTheme();

    useEffect(()=>{
        handleGetTicket();
    },[]);

    const handleGetTicket = async () => {
        setIsLoading(true);
        let response = await getTicket(uuid);
        if(response.success === true)
        {
            setTicket(response.data);
        }
        setIsLoading(false);
    }

    const options = {
        // default is `save`
        method: 'open',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.HIGH,
        page: {
           // margin is in MM, default is Margin.NONE = 0
           margin: Margin.SMALL,
           // default is 'A4'
           format: 'letter',
           // default is 'portrait'
           orientation: 'landscape',
        },
        canvas: {
           // default is 'image/jpeg' for better size performance
           mimeType: 'image/png',
           qualityRatio: 1,
           
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        overrides: {
           // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
           pdf: {
              compress: true
           },
           // see https://html2canvas.hertzen.com/configuration for more options
           canvas: {
              useCORS: true
           }
        },
     };

    // you can use a function to return the target element besides using React refs

    const handleDownloadTicket = async () => {
        setIsLoading(true);
        setIsHidden(false);
        setTimeout(()=>toPDF({options, overrides:{canvas:{useCORS:true}}}),1000)
        setTimeout(()=>{setIsHidden(true);setIsLoading(false);},3000)
        
    }

    return(
        <Box sx={{overflowX:'hidden',bgcolor:'primary.main'}}>
        <Container maxWidth="lg" sx={{mt:{xs:10,md:10}}}>
            <QUDProgress open={isLoading}/>
            {
                ticket &&
                <div style={{backgroundColor:theme.palette.primary.main}}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item md={6} lg={4} xl={4}>
                            <Paper elevation={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>
                                        <Box sx={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                            <img
                                                style={{width:180,height:180,objectFit:'cover',borderRadius:10}} 
                                                src={imagesURL+'eventos/'+ ticket.imagen} 
                                                alt={ticket.evento}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6" fontWeight={'bold'}>
                                            {ticket.evento}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Typography color={'grey2.main'}>Nombre</Typography>
                                            <Typography>{ticket.customer}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Typography color={'grey2.main'} textAlign={'right'}>Sección</Typography>
                                            <Typography textAlign={'right'}>
                                                {ticket.seccion}
                                                {ticket.asiento !== null && `-${ticket.asiento}`}
                                            </Typography>
                                    </Grid>

                                    {/**Fecha  */}
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Typography color={'grey2.main'}>Fecha</Typography>
                                        <Typography>{ticket.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Typography color={'grey2.main'} textAlign={'right'}>Hora</Typography>
                                        <Typography textAlign={'right'}>{ticket.hora}</Typography>
                                    </Grid>

                                    {/**Ubicacion  */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography color={'grey2.main'}>Ubicación</Typography>
                                        <Typography>
                                            {ticket.direccion}
                                            &nbsp;<Link href={`http://maps.google.com/maps?daddr=${ticket.latitud},${ticket.longitud}`} target="_blank" color={'#147ac2'}>(Como llegar)</Link>
                                        </Typography>
                                    </Grid>

                                     {/**Separador */}
                                    <Box sx={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                        <Box sx={{width:20,height:20,borderRadius:20,marginLeft:-2,bgcolor:'primary.main'}}/>
                                        
                                        <Box sx={ticket.estatus === 'Usado' ? styles.usedTicketDivider : styles.ticketDivider}/>
                                        
                                        <Box sx={{width:20,height:20,borderRadius:20,marginRight:-4,bgcolor:'primary.main'}}/>
                                    </Box>

                                    {
                                        ticket.estatus === 'Usado' &&
                                        <Box style={{padding:15,width:'100%'}}>
                                            <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>Boleto ya usado</Typography>
                                        </Box>
                                    }

                                    {
                                        ticket.estatus === 'Disponible' &&
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid container spacing={3} justifyContent={'center'}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Box sx={{backgroundColor:'#f5f7fb',borderRadius:5}}>
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',p:2}}>
                                                            <Typography component={'pre'}>{`Muesta este código QR \nen la entrada del evento`}</Typography>
                                                            <Box>
                                                                <QRCode value={ticket.uuid} size={70}/>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                               {/*<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Button variant="contained" sx={{bgcolor:'#f5f7fb',color:'primary.main',height:'100%','&:hover':{color:'primary.contrastText',bgcolor:'primary.main'}}}>
                                                        <FontAwesomeIcon icon={faPaperPlane}/>
                                                    </Button>
                                                </Grid>*/}
                                                <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        //sx={{bgcolor:'#f5f7fb',color:'primary.main',height:'100%','&:hover':{color:'primary.contrastText',bgcolor:'primary.main'}}}
                                                        onClick={() =>handleDownloadTicket()}
                                                    >
                                                        <Typography fontWeight={'bold'}>Descargar Boleto</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            }

            <Grid container spacing={3} justifyContent={'center'} mt={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="body2" color={'primary.contrastText'} gutterBottom>
                        Disclaimer <br/>
                        QUD solo provee el servicio de procesamiento de la emisión de boletos y control de acceso, no se
                        hace responsable en ningún caso por modificaciones, reprogramaciones o cancelación de ningún
                        evento. En tal sentido será responsable la productora dueña del evento en cuestión bajo sus
                        términos y condiciones correspondientes.
                    </Typography>
                </Grid>
            </Grid>

            {
                ticket &&
                <div ref={targetRef} hidden={isHidden} style={{marginBottom:0}}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item md={10} lg={7} xl={7}>
                            <Grid container spacing={3} >
                                <Grid item lg={12} xl={12}>
                                    {/*<Box sx={{width:'100%'}}>
                                        <img style={{width:'100%'}} src={imagesURL+'eventos/'+ ticket.header} alt='qud'/>
                                    </Box>*/}
                                </Grid>
                                <Grid item lg={12} xl={12}>
                                    <Typography textAlign={'center'} variant="h4" fontWeight={'bold'}>{ticket.evento}</Typography>
                                </Grid>
                                <Grid item lg={12} xl={12}>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Fecha:</span>
                                        &nbsp;{ticket.fecha}
                                    </Typography>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Hora:</span>
                                        &nbsp;{ticket.hora}
                                    </Typography>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Ciudad:</span>
                                        &nbsp;{ticket.ciudad}
                                    </Typography>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Ubicación:</span>
                                        &nbsp;{ticket.direccion}
                                        &nbsp;<Link href={`http://maps.google.com/maps?daddr=${ticket.latitud},${ticket.longitud}`} target="_blank" color={'#147ac2'}>(Como llegar)</Link>
                                    </Typography>
                                </Grid>

                                {/*<Grid item lg={6} xl={6}>
                                    <Box sx={{width:'100%'}}>
                                        <img style={{width:'100%'}} src={imagesURL+'eventos/'+ ticket.body} alt='qud'/>
                                    </Box>
                                </Grid>*/}
                                <Grid item lg={4} xl={4} ml={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                    <QRCode value={ticket.uuid}/>
                                    <Typography mt={1} fontWeight={'bold'} variant="caption">POWERED BY QUD</Typography>
                                    {/*<Box sx={{width:'80%',mt:4}}>
                                        <img style={{width:'100%'}} src={'https://api.qud.life/apiqud/public/img/powered_by.jpeg'} alt='qud'/>
                                    </Box>*/}
                                </Grid>
                                <Grid item lg={12} xl={12} alignItems={'center'}>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Orden de compra:</span>
                                        &nbsp;#{ticket.orden}
                                    </Typography>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Categoría:</span>
                                        &nbsp;{ticket.seccion}
                                    </Typography>
                                    <Typography variant='h6' >
                                        <span style={{fontWeight:'bold'}}>Número:</span>
                                        &nbsp;{ticket.asiento}
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} xl={12}>
                                    <Typography variant="body2" gutterBottom>
                                        Disclaimer <br/>
                                        QUD solo provee el servicio de procesamiento de la emisión de boletos y control de acceso, no se
                                        hace responsable en ningún caso por modificaciones, reprogramaciones o cancelación de ningún
                                        evento. En tal sentido será responsable la productora dueña del evento en cuestión bajo sus
                                        términos y condiciones correspondientes.
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </div>
                
            }
        </Container>
        </Box>
     )
}

const styles={
    ticketDivider:
    {
        borderBottomWidth:2,
        borderStyle:'dashed',
        width:'89%',
        //backgroundColor:ticket.estatus === 'Usado' ? '#212121' :'#FFF'
    },
    usedTicketDivider:
    {
        borderBottomWidth:8,
        borderStyle:'solid',
        borderColor:'primary.main',
        width:'105%',
        marginLeft:-4,
        marginRight:-4,
        bgcolor:'primary.main',
    }
}

/**
 * <Grid component={'div'} container spacing={3}>
        <Grid component={'div'} item md={10} lg={10} xl={12}>
            <Box component={'div'} sx={{width:'100%',height:300}}>
                <img style={{width:'100%'}} src={imagesURL+'eventos/'+ ticket.header} alt='qud'/>
            </Box>
        </Grid>
        <Typography variant='h6'  variant="h4">{ticket.evento}</Typography>
    </Grid>
 */