import React, { useState } from 'react';
import WebsiteRoutes from '../../apiRoutes/WebsiteRoutes';

function UploadFile() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Indicador de progreso
  const [error, setError] = useState(null); // Para manejar errores
  const [successMessage, setSuccessMessage] = useState(null); // Para mostrar el resultado exitoso

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && !['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) {
      setError('Por favor selecciona un archivo Excel válido.');
      setSelectedFile(null);
    } else {
      setError(null);
      setSelectedFile(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setError('Debes seleccionar un archivo para subir.');
      return;
    }

    setIsUploading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const formData = new FormData();
      formData.append('excel_file', selectedFile);

      const response = await fetch(WebsiteRoutes.Eventos.Tickets.batch, {
        method: 'POST',
        body: formData, // No es necesario especificar `Content-Type` para `FormData`, lo hace automáticamente
      });

      if (!response.ok) {
        throw new Error('Error al subir el archivo. Verifica el servidor.');
      }

      const data = await response.json();
      setSuccessMessage('Archivo subido con éxito.');
      console.log('Respuesta del servidor:', data);
    } catch (error) {
      setError('Hubo un problema al subir el archivo. Inténtalo nuevamente.');
      console.error('Error al subir el archivo:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <h2>Subir Archivo Excel</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="file" 
          accept=".xls,.xlsx" 
          onChange={handleFileChange} 
        />
        <button type="submit" disabled={isUploading}>
          {isUploading ? 'Subiendo...' : 'Subir archivo'}
        </button>
      </form>

      {/* Mensajes de error o éxito */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </div>
  );
}

export default UploadFile;
