import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { GetByUUID } from "../../../controllers/company/AssociatesController";
import { domain, imagesURL } from "../../../apiRoutes/apiBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faContactCard, faDownload, faEnvelope, faInstitution, faPhone, faPhoneVolume, faShare, faShareAlt, faX } from "@fortawesome/free-solid-svg-icons";

import VCard from 'vcard-creator'
import Horarios from "../../../components/horarios";
import Carousel from "../../../components/carousel";

import MapContainer from '../../../components/map';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";


const iconMapping = {
    'Instagram': faInstagram,
    'WhatsApp': faWhatsapp,
    'X' : faX,
    'Facebook': faFacebook,
    'Linkedin': faLinkedin,
    'YouTube' : faYoutube
}

export default function BusinessCard()
{
    const [associate,setAssociate] = useState([]);
    const [socials,setSocials] = useState([]);
    const [products,setProducts] = useState([]);
    const [workSchedueles,setWorkSchedules] = useState([]);

    const { uuid } = useParams();


    useEffect(()=>{
        handleGetAssociate();
    },[])

    const handleGetAssociate = async () => {
        let response = await GetByUUID(uuid);
        if(response.success === true)
        {
            setAssociate(response.data.associate);
            setSocials(response.data.redes);
            setProducts(response.data.productos);
            setWorkSchedules(response.data.horarios);
        }
    }

    const createVCard = (name,lastName,organization,image,title,url,phone,email,address) => {
        // Define a new vCard
        const vCard = new VCard();
        vCard.addName(associate.apellido,associate.nombre)
        .addCompany(associate.company)
        .addJobtitle(associate.cargo)
        .addEmail(associate.email)
        .addPhoneNumber(associate.telefono)
        .addAddress(associate.direccion)
        .addURL('https://qud.life/businesscard/'+associate.uuid)
        .addLogoURL(image);
        
        var _vDoc = document.createElement("a"); //JSON.stringify(vCard)
        _vDoc.href = window.URL.createObjectURL(
            new Blob(
                [vCard.toString()],
                {
                    type:"text/plain"
                }
            ) 
        );
        
        _vDoc.download = associate.nombre+".vcf";
        _vDoc.click();
    }

    return(
        <Box sx={{bgcolor:'primary.main',marginTop:-13}}>
            <Box
                sx={{
                    width:'100%',
                    minHeight:{xs:'20vh',sm:'25vh',md:'35vh',lg:'45vh',xl:'45vh'},
                    maxHeight:{xs:'20vh',sm:'25vh',md:'25vh',lg:'45vh',xl:'45vh'},
                    //background:'#000',
                    display:'flex',
                    marginTop:-13,
                    position:'relative'
                }}
            >
                
            </Box>
            <Container sx={{color:'primary.contrastText'}}>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <Box
                        sx={{
                            width:{xs:150,sm:150,md:200,lg:250,xl:250},
                            height:{xs:150,sm:150,md:200,lg:250,xl:250},
                            border:'solid',
                            borderRadius:250,
                            borderWidth:3,
                            borderColor:'#FFF',
                            zIndex:100,
                            mt:{xs:-3,sm:-8,md:-12,lg:-15,xl:-15}
                        }}
                    >
                        <img style={{width:'100%',height:'100%',borderRadius:'100%',objectFit:'cover'}} src={imagesURL+'users/'+associate.imagen} alt='user'/>
                    </Box>
                </Box>
                <Typography mt={1} fontWeight='bold' fontSize={18} textAlign='center'>{associate.nombre} {associate.apellido}</Typography>
                <Typography textAlign='center' color='primary.contrastAux'>{associate.cargo}</Typography>

                <Grid container spacing={3} justifyContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h4" mt={2} fontWeight='bold' textAlign='center'>{associate.company}</Typography>
                                <Typography variant="body1" textAlign='center'>{associate.descripcion}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={3} justifyContent={'center'}>
                                    {
                                        socials.length !== 0 &&
                                        socials.map(item=>
                                            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} display={'flex'} justifyContent={'center'}>
                                                <IconButton
                                                    color="secondary"
                                                    target="_blank"
                                                    href={item.enlace}
                                                >
                                                    <FontAwesomeIcon icon={iconMapping[item.nombre]}/>
                                                </IconButton>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    fullWidth
                                    sx={{display:'flex',flexDirection:'column'}}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={()=>window.location.href=`mailto:${associate.email}`}
                                >
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                    <Typography>Enviar correo</Typography>
                                </Button>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    fullWidth
                                    sx={{display:'flex',flexDirection:'column'}}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={()=>window.location.href=`tel:${associate.telefono}`}
                                >
                                    <FontAwesomeIcon icon={faPhoneVolume}/>
                                    Llamar
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent='center' alignItems='center'>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{borderRadius:20}}
                                    onClick={()=>createVCard()}
                                >
                                    <FontAwesomeIcon icon={faDownload}/>
                                    &nbsp;<Typography>Guardar contacto</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent='center' alignItems='center'>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="link"
                                    sx={{borderRadius:20}}
                                    target="_blank"
                                    href={`${domain}merchants/${associate.company_uuid}/profile`}
                                >
                                    <Typography>Visitar el perfil de la empresa</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}