import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";

export default function RegisterDialog(props)
{
    const [open,setOpen] = useState(props.open);
    const [email,setEmail] = useState('');

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = async () => {

    }

    return(
        <Dialog open={open} fullWidth={true} maxWidth='sm' onClose={handleClose}>
            <DialogTitle>Se parte de algo nuevo y único</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    únete a QUD y explora un mundo de oportunidades
                </DialogContentText>
                <br/>
                <Grid container spacing={3}>
                    <Grid item lg={12} xl={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>handleClose()}>Cancelar</Button>
                <Button onClick={()=>handleClose()}>Unirme</Button>
            </DialogActions>
        </Dialog>
    )
}