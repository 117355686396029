import { useState, useEffect } from "react";
import { Box, Button, Card, CardActionArea, CardContent, Container, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import { GetCategories, GetMerchants } from "../../../controllers/MerchantsController";
import SectionTitle from "../../../components/sectionTitle";
import Slider from "react-slick";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import Carousel from 'react-elastic-carousel'




export default function Merchants()
{
    const [categories,setCategories] = useState([]);
    const [selected,setSelected] = useState('');
    const [merchants,setMerchants] = useState([]);
    const [progress,setProgress] = useState(false);

    const history = useHistory();

    const theme = useTheme();

    useEffect(() => {
        handleGetCategories();
        handleGetMerchants(1)
    },[]);

    function redireccionar(ruta)
    {
        history.push(ruta);
    }

    const handleGetCategories = async () => {
        let response = await GetCategories();
        if(response.success === true)
        {
            setCategories(response.data.categorias);
        }
    }

    const handleGetMerchants = async (idCategoria) => {
        let response = await GetMerchants(idCategoria);
        if(response.success === true)
        {
            setMerchants(response.data.negocios);
        }
    }

    

    function ShowMerchants(props)
    {
        if(props.merchants.length !== 0)
        {
            let negocios = props.merchants.map((n)=>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={n.id}>
                <Paper
                    sx={{'&:hover':{bgcolor:'#f0f0f0',cursor:'pointer'}}}
                    onClick={()=>redireccionar('/merchants/'+n.id+'/profile')}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                            <Box sx={{width:90,height:90}}>
                                <img style={{width:90,height:90,borderRadius:10}} src={imagesURL+'empresas/'+n.imagen2} alt={n.negocio}/>
                            </Box>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}> </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                                <Typography style={{fontWeight:'bold'}}>{n.negocio}</Typography>
                                <Grid item lg={12} xl={12} sx={{display:'flex',flexDirection:'row'}}>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <Divider orientation="vertical" flexItem sx={{ml:2,mr:2}}/>
                                    <Typography variant='caption' color='#00000099'>927 resenas</Typography>
                                </Grid>
                                <Grid item lg={12} mt={2}>
                                    <Typography variant='caption' color='#00000099'>Lecheria, Venezuela</Typography>
                                </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                
            </Grid>
            /*<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Paper sx={{'&:hover':{cursor:'pointer'},bgcolor:'#000' }} onClick={()=>redireccionar('/merchants/'+n.id+'/menu')}>
                    <Box >
                    <Grid container spacing={3}>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{bgcolor:'#FAF456'}}>
                            <div style={{width:90,height:90}}>
                                <img style={{width:90,height:90,borderRadius:10}} src={imagesURL+'empresas/'+n.imagen2} alt={n.negocio}/>
                            </div>
                        </Grid>
                        <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{bgcolor:'#FAF123'}}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xl={12}>
                                    <Typography style={{fontWeight:'bold'}}>{n.negocio}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    
                    </Grid>
                        
                        <Divider/>
                    </Box>
                </Paper>
        </Grid>*/
                
            );
            return negocios
        }
        
        return <Grid container justifyContent='center' spacing={3}><Grid item><Typography textAlign='center'>No se encontraron comercios</Typography></Grid></Grid>
         
    }

    const settings = {
        itemsToShow:4,
        dots: true,
        infinite: true,
        //enableAutoPlay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: false,
        breakPoints :[
            { width: 1, itemsToShow: 2 },
            { width: 550, itemsToShow: 3, itemsToScroll: 3 },
            { width: 850, itemsToShow: 4 },
            { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
            { width: 1450, itemsToShow: 5 },
            { width: 1750, itemsToShow: 6 },
          ]
        
    };

    
    return(
        <Container sx={{mt:10}}>
            <SectionTitle title='Comercios' subtitle='Seleccione una categoría'/>
            
            <div>
                {
                    categories.length !== 0 &&
                    <Carousel {...settings}>
                        {
                            categories.map((c) => {
                                let color = '#FFF';
                                if(c.nombre === selected)
                                color = theme.palette.secondary.main ;
                                return(
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                            <Button variant='outlined' color='primary'
                                                style={{
                                                    width:75,height:75,borderRadius:75,borderWidth:1,borderColor:color
                                                }}

                                                onClick= { () => { setSelected(c.nombre); handleGetMerchants(c.idcategoria); }}
                                            >
                                                <img style={{width:45,height:45}} src={imagesURL + 'categorias/'+ c.img} alt={'QUD '+c.nombre} />
                                            </Button>
                                        </div>
                                        <Typography style={{textAlign:'center'}} >{c.nombre}</Typography>
                                    </div>
                                )}
                            )
                        }
                    </Carousel>
                }
            </div>
                
            <Typography align='center' variant='h6' mt={5} >{selected}</Typography>

            <Grid container spacing={3} style={{marginTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <ShowMerchants merchants={merchants} selected={selected} />
                
            </Grid>
        </Container>
    )
}