import { forwardRef, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import QRCode from "react-qr-code";
import { domain } from "../../../apiRoutes/apiBase";

import { toPng } from 'html-to-image';
import { Typography } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function DonwloadableQR(props)
{
    //const [open, setOpen] = useState(props.open)
    const qrCodeRef = useRef(null);
    
    const downloadQRCode = async() => {

        if (qrCodeRef.current) {
          toPng(qrCodeRef.current,{width:450,height:450})
            .then(function (dataUrl) {
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = props.place.name+'.png'; // Nombre del archivo a descargar
              link.click();
            });
        }
    };

   
    
    return(
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            
            onClose={props.handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth='md'
        >
            {
                props.place !== undefined &&
                <>
                    <DialogTitle>{"Código QR de "+props.place.name}</DialogTitle>
                    <DialogContent>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <div ref={qrCodeRef} style={{width:450,height:450}}>
                            <QRCode style={{width:'100%',height:'100%'}} value={domain+'tourism/places/'+props.place.uuid} />
                        </div>
                        </div>
                        <DialogContentText id="alert-dialog-slide-description" mt={1}>
                            Usar/Compartir este código QR para mostrar el sitio turístico.
                            <br/>
                            Una vez los usuarios abran el enlace del código QR podrán ver la información
                            del sitio turístico y tambien podrán resañarlo
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="cancel" variant="contained" onClick={props.handleClose}>
                            <Typography>Cerrar</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            onClick={()=>downloadQRCode()}
                        >
                            <Typography>
                            Descargar QR
                            </Typography>
                        </Button>
                    </DialogActions>
                </>
            }
            
            
        </Dialog>
    )
}