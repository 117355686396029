import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";

import { useHistory, useParams } from "react-router-dom";
import { emailVerification } from "../../controllers/AuthController";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function EmailVerification()
{
    const [isLoading,setIsLoading] = useState(true);

    const [title,setTitle] = useState('');
    const [msg,setMsg] = useState('');
    const [isOpen,setIsOpen] = useState(false);
    
    const { email, code } = useParams();

    const history = useHistory();

    useEffect(()=>{
        hanldeEmailVerification();
    },[]);

    const redirect = (route) => {
        history.push(route)
    }

    const hanldeEmailVerification = async () => {
        setIsLoading(true);
        let response = await emailVerification(email,code);
        console.log(response.message);
        if(response.success === true)
        {
            await setTitle('Operación Exitosa!');
            await setMsg(response.message);
            await setIsOpen(true);
        }
        else if(response.success === false)
        {
            await setTitle('Operación Fallida!');
            await setMsg(response.message);
            await setIsOpen(true);
        }
        else
        {
            await setTitle('Operación Fallida!');
            await setMsg('En estos momentos su solicitidud no puede ser procesada!');
            await setIsOpen(true);
        }

        setIsLoading(false);
    }

    return(
        <Container maxWidth='lg'>
            <Grid height='100vh' container spacing={3} justifyContent='center' alignItems='center'>
                {
                    isLoading &&
                    <CircularProgress/>
                }
                {
                    isOpen &&
                    <Grid item xs={12} sm={10} md={6} lg={6} xl={6}>
                        <Paper elevation={4} sx={{borderWidth:1,border:'solid',borderColor:'success.light'}}>
                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <CheckCircleOutlineIcon sx={{fontSize:70,color:'success.light'}}/>
                            </Box>
                            <Typography variant="h6" fontWeight='bold' textAlign='center'>{title}</Typography>
                            <br/>
                            <Typography variant="h6" textAlign='center'>{msg}</Typography>
                            <br/>
                            <Grid container spacing={3} justifyContent='center'>
                                <Grid item lg={6} xl={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        onClick={()=> redirect('/auth/signIn') }
                                    >
                                        Iniciar sesion
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
            </Grid>
        </Container>
    )
    
}