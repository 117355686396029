import { faClose, faPencil, faTicket, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Checkbox, Divider, Drawer, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useReducer, useState } from "react";


const initialState = {
    seccion_id: '',
    nombre : '',        errorNombre : false,
    descripcion : '',   errorDescripcion : false,
    capacidad:'',       errorCapacidad: false,
    precio:'',          errorPrecio: false,
    multiplos:'1',      errorMultiplos: false,
    visible: false,

    buttonText: 'Agregar ticket'
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}



export default function AddTickets(props)
{
    const [open,setOpen] = useState(false);
    const [tickets,setTickets] = useState([]);
    const [index,setIndex] = useState();
    const [state,setState] = useReducer(reducer,initialState);
    const {
        seccion_id,
        nombre,errorNombre,
        descripcion, errorDescripcion,
        capacidad, errorCapacidad,
        precio,errorPrecio,
        multiplos, errorMultiplos,
        visible,
        buttonText
    } = state;

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const toggleDrawer = () => {
        setOpen(!open);   
    }

    const toAddTicket = () => {
        setState({field:'buttonText',value:'Agregar ticket'});
        toggleDrawer()
    }

    const handleCancel = () => {
        toggleDrawer();
        setState({field:'nombre',value:''});
        setState({field:'precio',value:''});
        setState({field:'capacidad',value:''});
        setState({field:'descripcion',value:''});
        setState({field:'multiplos',value:'1'});
        setState({field:'errorNombre',value:false});
        setState({field:'errorPrecio',value:false});
        setState({field:'errorCapacidad',value:false});
        setState({field:'errorDescripcion',value:false});
        setState({field:'errorMultiplos',value:false});
        setState({field:'visible',value:false});
    }


    const formVerification = () => {
        let ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(multiplos === '')
        {
            setState({field:'errorMultiplos',value:true});
            ban = 1;
        }

        if(precio === '')
        {
            setState({field:'errorPrecio',value:true});
            ban = 1;
        }
        
        if(capacidad === '')
        {
            setState({field:'errorCapacidad',value:true});
            ban = 1;
        }

        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }

        return ban;
    }


    const handleAddTicket = async () => {
        let ban = formVerification();
        if(ban === 0)
        {
            let _tickets = tickets;
            _tickets.push({
                nombre: nombre,
                multiplos: multiplos,
                precio: precio,
                capacidad: capacidad,
                descripcion: descripcion,
                visible: visible
            });
    
            setTickets(_tickets);
            handleCancel();
        }
        return;
    }

    const handleRemoveTicket = (index) => {
        const newArray = [...tickets];
        newArray.splice(index,1);
        setTickets(newArray);
    }

    const handleToUpdateTicket = (_index) => {
        const ticket = tickets.find((item,xindex)=>xindex===_index);
        setIndex(_index);
        setState({field:'nombre',value:ticket.nombre});
        setState({field:'multiplos',value:ticket.multiplos});
        setState({field:'precio',value:ticket.precio});
        setState({field:'capacidad',value:ticket.capacidad});
        setState({field:'descripcion',value:ticket.descripcion});

        setState({field:'buttonText',value:'Actualizar ticket'});

        toggleDrawer();
    }

    const handleUpdateTicket = async (_index) => {
        let ban = formVerification();
        if(ban === 0)
        {
            //const ticket = tickets.find((item,xindex)=>xindex===_index);
            //setTickets(tickets.filter((ticket,xindex)=>xindex !== _index));
            setTickets((prevTickets)=>{
                const updatedTickets = [...prevTickets];
                const indexToUpdate = updatedTickets.findIndex((item,xindex)=> xindex === _index);
                
                if(indexToUpdate !== -1){
                    updatedTickets[indexToUpdate].nombre = nombre;
                    updatedTickets[indexToUpdate].multiplos = multiplos;
                    updatedTickets[indexToUpdate].precio = precio;
                    updatedTickets[indexToUpdate].capacidad = capacidad;
                    updatedTickets[indexToUpdate].descripcion = descripcion;
                    updatedTickets[indexToUpdate].visible = visible;
                }
                console.log(tickets)
                return updatedTickets;
            });

            handleCancel();
        }
        return;
    }

    const handleSubmit = () => {
        props.reviewAction(tickets);
    }
    
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6">
                    ¿Qué tipos de entradas planeas vender en línea? Esto podría ser cualquier cosa, desde entradas de "entrada general" hasta entradas "VIP". Haz clic en "Agregar ticket" para cada tipo de ticket que planees vender.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button variant='contained' onClick={()=>toAddTicket()}>
                    <Typography variant="h6">+ Agregar ticket</Typography>
                </Button>
            </Grid>

            {/**Tickets Added*/}
            {
                tickets.length !== 0 &&
                tickets.map((item,index)=>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                        <Paper sx={{bgcolor:'#f0f0f0'}}>
                            <Grid container spacing={3} alignItems={'center'}>
                                <Grid item lg={1}>
                                    <FontAwesomeIcon size="2x" icon={faTicket} />
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="h6" fontWeight={'bold'}>
                                        {item.nombre}
                                    </Typography>
                                    <Typography variant="body1">
                                        {item.descripcion}
                                    </Typography>
                                    <Typography variant="body1">
                                        ${item.precio} &nbsp;&nbsp; Max Capacidad: {item.capacidad}
                                    </Typography>
                                </Grid>
                                <Grid item lg={2} display={'flex'} flexDirection={'row-reverse'}>
                                    <IconButton onClick={()=>handleRemoveTicket(index)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </IconButton>
                                    <IconButton onClick={()=>handleToUpdateTicket(index)}>
                                        <FontAwesomeIcon icon={faPencil}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )
            }

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row-reverse'}>
                <Button variant="contained" sx={{ml:2}} onClick={()=>handleSubmit()}>
                    <Typography>Revisar</Typography>
                </Button>
                <Button variant='contained' sx={{bgcolor:'#e6e6e6'}} color="cancel" onClick={props.prevPanel}>
                    <Typography>Anterior</Typography>
                </Button>
            </Grid>
            

            {/**Drawer + formulario de tickets */}
            <Drawer
                anchor="right"
                open={open}
                onClose={handleCancel}
                
            >
                <Box sx={{width:{xs:260,md:460,lg:630,xl:630},pt:9}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6' fontWeight={'bold'}>
                                Agrega un tipo de ticket
                            </Typography>
                            <IconButton onClick={()=>handleCancel()}>
                                <FontAwesomeIcon icon={faClose}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Tipo de ticket'
                                name='nombre'
                                id='nombre'
                                required
                                value={nombre}
                                onChange = { onChange }
                                onBlur={ () => setState({field:'errorNombre',value:nombre===''?true:false}) }
                                error = {errorNombre}
                                helperText={<Typography>{errorNombre ?'Ingresa el tipo de ticket':'Este es el nombre del tipo de ticket. Por ejemplo: Entrada general, VIP, Pre-venta'}</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Multiplos del Ticket'
                                name='multiplos'
                                id='multiplos'
                                required
                                value={multiplos}
                                onChange = { onChange }
                                onBlur={ () => setState({field:'errorMultiplos',value:multiplos===''?true:false}) }
                                error = {errorMultiplos}
                                helperText={
                                    <Typography>
                                        {
                                            errorNombre 
                                            ?'Ingresa la cantidad de códigos QR que se deben emitir al comprar este tipo de ticket'
                                            :'Esta es la cantidad de códigos QR a emitir al momento que un cliente compre un ticket. Por ejemplo: El cliente selecciona la cantidad de tickets que desea y se multiplica por los multiplos, el resultado es la cantidad de códigos QR a emitir'
                                        }
                                    </Typography>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Precio ($)'
                                name='precio'
                                id='precio'
                                required
                                value={precio}
                                onChange = { onChange }
                                onBlur={ () => setState({field:'errorPrecio',value:precio===''?true:false}) }
                                error = {errorPrecio}
                                helperText={<Typography>{errorPrecio ?'Ingresa el precio del tipo de ticket':'Ingresa el precio del tipo de ticket. Las tarifas son independientes y se mostrarán y calcularán al momento del pago.'}</Typography>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Máxima capacidad'
                                name='capacidad'
                                id='capacidad'
                                required
                                value={capacidad}
                                onChange = { onChange }
                                onBlur={ () => setState({field:'errorCapacidad',value:capacidad===''?true:false}) }
                                error = {errorCapacidad}
                                helperText={<Typography>{errorCapacidad ?'Ingresa la máxima capacidad del tipo de ticket':'Esta es la cantidad máxima de tickets disponibles en línea para este tipo de ticket.'}</Typography>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Descripción'
                                name='descripcion'
                                id='descripcion'
                                required
                                value={descripcion}
                                onChange = { onChange }
                                onBlur={ () => setState({field:'errorDescripcion',value:descripcion===''?true:false}) }
                                error = {errorDescripcion}
                                helperText={<Typography>{errorDescripcion ?'Ingresa la máxima capacidad del tipo de ticket':'Ingrese una breve descripción de lo que incluye este tipo de ticket, como acceso especial, ventajas o requisitos.'}</Typography>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                            style={{display:'flex',flexDirection:'row',alignItems:'center'}}
                        >
                            <Checkbox
                                checked={visible} 
                                onChange={()=>{ 
                                    setState({field:'visible',value:!visible})} 
                                }
                                name="visible"
                                id='visible'
                            />
                            <Box>
                                <Typography fontWeight={'bold'}>
                                    Ticket visible
                                </Typography>
                                <Typography>
                                    Indica si el ticket es visible en el evento.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row-reverse'}>
                            <Button
                                variant='contained' sx={{ml:3}}
                                onClick={()=> buttonText === 'Agregar ticket'? handleAddTicket():handleUpdateTicket(index)}
                            >
                                <Typography>{buttonText}</Typography>
                            </Button>
                            <Button onClick={()=>handleCancel()}>
                                <Typography>Cancelar</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                
            </Drawer>
        </Grid>
    )
}