import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import QUDProgress from "../../../../components/progress";

export default function BoxPark()
{
    const users = [
        { id: 448, name: "Lorenzo Vasquez" },
        { id: 449, name: "Marysabel Montero" },
        { id: 450, name: "alejandra gomez" },
        { id: 451, name: "Alejandro Klie" },
        { id: 452, name: "Marysabel Montero" },
        { id: 453, name: "Rainner Rivas Rivas" },
        { id: 454, name: "Carlos Guaita" },
        { id: 455, name: "Natasha Garcia" },
        { id: 456, name: "Jhorman Parra" },
        { id: 457, name: "Luis Villarroel" },
        { id: 458, name: "Tomas Medina" },
        { id: 459, name: "Fiorella Salazar" },
        { id: 460, name: "Edwind Aguilera" },
        { id: 461, name: "Cesar Alexis Figueroa" },
        { id: 462, name: "Eglantina Quispe Quispe" },
        { id: 463, name: "Jhonny Gonzalez" },
        { id: 464, name: "Francisco Alfonzo" },
        { id: 465, name: "Jose Castillo" },
        { id: 466, name: "Dessire Arevalo" },
        { id: 467, name: "Cesar Reyes" },
        { id: 468, name: "Germary Guevara" },
        { id: 469, name: "Alvaro Pachón" },
        { id: 470, name: "Amanda Torrealba" },
        { id: 471, name: "Ana Bonillo" },
        { id: 472, name: "Zoila Rodriguez" },
        { id: 473, name: "Jesús Alcantara" },
        { id: 474, name: "Maria Palacios" },
        { id: 475, name: "Eliana Reyes" },
        { id: 477, name: "Auri Lopez" },
        { id: 478, name: "Andreina Marin" },
        { id: 479, name: "Marisela alfonzo" },
        { id: 480, name: "Luis Cedeño" },
        { id: 481, name: "Eduard Pohl" },
        { id: 482, name: "Narcisse Manzanarez" },
        { id: 483, name: "Rainner Rivas" },
        { id: 484, name: "Pebbles Manzanarez" },
        { id: 485, name: "Paola Manzanarez" },
        { id: 486, name: "Sandra Rangel" },
        { id: 487, name: "David VALERO MARTINEZ" },
        { id: 488, name: "Greulys Atias" },
        { id: 489, name: "Alber Manzanarez" },
        { id: 490, name: "Pamela Manzanarez" },
        { id: 491, name: "Migdalia Manzanarez" },
        { id: 492, name: "Leila Esso" },
        { id: 493, name: "Emilis Rosal" },
        { id: 494, name: "Juan Carlos Maya salloum" },
        { id: 495, name: "Grace Goncalves" },
        { id: 496, name: "Sergio rubini" },
        { id: 497, name: "Diego Torres" },
        { id: 498, name: "Billy Gomez" },
        { id: 499, name: "PEDRO Manzanarez" },
        { id: 500, name: "Guillero Fulco" },
        { id: 501, name: "Giovanni Cataldo" },
        { id: 502, name: "Doren Ruiz" },
        { id: 503, name: "Jhorman Parra" },
        { id: 504, name: "Pedro Llovera" },
        { id: 505, name: "Yolibeth Díaz" },
        { id: 506, name: "adrián garcía" },
        { id: 507, name: "Aguasanta Alcala" },
        { id: 508, name: "Barbara Azuaje" },
        { id: 509, name: "Nissin Giardini" },
        { id: 510, name: "Diana Bultron" },
        { id: 511, name: "Michelle Padilla" },
        { id: 512, name: "Solymar Sarocenis" },
        { id: 513, name: "Kimberly Contreras" },
        { id: 514, name: "Elsy Yrigoyen" },
        { id: 515, name: "Jesus Gonzalez" },
        { id: 516, name: "Camila Merino" },
        { id: 517, name: "Alejandra Navarro" },
        { id: 518, name: "CARLOS FOUCAULT" },
        { id: 519, name: "Francisco Blanca" },
        { id: 520, name: "Patricia Santos" },
        { id: 521, name: "Laila Chaaban" },
        { id: 522, name: "Henry Cova" },
        { id: 523, name: "Miguel Silva" },
        { id: 524, name: "Alejandra Sanz" },
        { id: 525, name: "Jessica Rodriguez" },
        { id: 526, name: "Elias Garcia" },
        { id: 527, name: "Domenico Giliberto" },
        { id: 528, name: "Diego Hernandez" },
        { id: 529, name: "Alejandro Delgado" },
        { id: 530, name: "Diego Gonzalez" },
        { id: 531, name: "Fabiana Aguilar" },
        { id: 532, name: "María Valentina Delgado García" },
        { id: 533, name: "Daniela Sanchez" },
        { id: 534, name: "Moises Ramos" },
        { id: 535, name: "Imperio Martinez" },
        { id: 536, name: "Annarella Lopez" },
        { id: 537, name: "Jose Gregorio Lemus" },
        { id: 538, name: "Ana Bolivar" },
        { id: 539, name: "Georges Attieh" },
        { id: 540, name: "Sofía Centeno" },
        { id: 541, name: "Raymond Attieh" },
        { id: 542, name: "José Daniel Yrigoyen armas" },
        { id: 543, name: "Jorge Graterol" },
        { id: 544, name: "José Febres" },
        { id: 545, name: "Harold Cortes" },
        { id: 546, name: "irmarys marcano" },
        { id: 547, name: "Yuliana Mejias" },
        { id: 548, name: "Niyorki Medina" },
        { id: 549, name: "Adrian Gonzalez" },
        { id: 550, name: "Luis carlos Reyes" },
        { id: 551, name: "Rachelt Rivero" },
        { id: 552, name: "Valeria González" },
        { id: 553, name: "Angelica Pichardo" },
        { id: 554, name: "Gianfranco M. Franco" },
        { id: 555, name: "Robert Rodriguez" },
        { id: 556, name: "Nadil Castillo" },
        { id: 557, name: "Francheska Franceschi" },
        { id: 558, name: "Romy Florez" },
        { id: 559, name: "Alejandro Guzmán" },
        { id: 560, name: "Nicolai Woznessensky" },
        { id: 561, name: "David Wong" },
        { id: 562, name: "Walter Silva" }
    ];

    const [isLoading,setIsLoading] = useState(true);
    const [primero,setPrimero] = useState('');
    const [segundo,setSegundo] = useState('');

    useEffect(()=>{
        
        let _primero ='';
        setTimeout(()=>{
            setIsLoading(true)
            _primero = selectRandomElement(users);
            setPrimero(_primero.name)
            let _segundo = selectRandomElement(users);
            setSegundo(_segundo.name)
            setIsLoading(false);
        }, 10000);
        
    },[]);

    //const seleccionarGanadores = 


    const selectRandomElement = (array) => {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }
      
      // Select a random user from the 'users' array
    const randomUser = selectRandomElement(users);
      
      // Display the selected random user
    console.log("Usuario aleatorio:", randomUser);

    return(
        <Container>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={5}>
                    <Typography variant="h2">Sorteo BoxPark</Typography>
                </Grid>

                {
                    isLoading === true
                    ?
                    <QUDProgress open={isLoading}/>
                    :
                    <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4">
                            Ganador #1
                        </Typography>
                        <Typography variant="h2">
                            {primero}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4">
                            Ganador #2
                        </Typography>
                        <Typography variant="h2">
                            {segundo}
                        </Typography>
                    </Grid>
                    </>
                }

            </Grid>
        </Container>
    )
}