import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function AddButton(props)
{
    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    return(
        <div style={{marginTop:20,marginBottom:20}}>
            <Button
                variant='contained'
                endIcon={<AddCircleIcon/>}
                onClick={()=>redireccionar(props.route)}
            >
                {props.title}
            </Button>
        </div>
    )
}