import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import CustomerRoutes from "../../apiRoutes/CustomerRoutes";
import WebsiteRoutes from "../../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

/**
 * @param {string} id_comercio
 * @param {string} origen_direccion
 * @param {number} origen_latitud
 * @param {number} origen_longitud
 * @param {number} monto is the total amount to pay for the order
 * @param {number} comision is the commission charged on the order
 * @param {string} destino_direccion
 * @param {number} destino_latitud
 * @param {number} destino_longitud
 * @param {array} detalles It is an array with the details of the products/services that are in the order
 * @param {array} pagos
 */
export async function orderTaxi(id_comercio,origen_direccion,origen_latitud,origen_longitud,monto,comision,destino_direccion,destino_latitud,destino_longitud,detalles,pagos)
{
    let response = await POST_JSON(
        CustomerRoutes.Orders.TaxiOrder,
        {
            id_comercio,
            origen_direccion,origen_latitud,origen_longitud,
            monto,comision,
            destino_direccion, destino_latitud,destino_longitud,
            detalles,
            pagos
        }
    );
    return response;
}

/**
 * @param {number} tipo_pedido
 */
export async function getOrders(tipo_pedido)
{
    let response = await GET(CustomerRoutes.Orders.GetAll+tipo_pedido);
    return response;
}