import React, { useState, useEffect } from 'react';
import CountdownHook from 'react-countdown-hook';

const CountdownComponent = () => {
    const [countdown, setCountdown] = useState(900); // 10 minutes in seconds

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
            
        }, 1000); // Update countdown every second
        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    useEffect(() => {
        if(countdown === 0)
            window.location.reload();
        
        /*const intervalId = setInterval(() => {
            window.location.reload(); // Refresh the page
        }, 60000); // 10 minutes in milliseconds
    
        return () => clearInterval(intervalId); // Clear interval on component unmount*/
    }, [countdown]);

    return (
        <div className="countdown">
            <p>Tiempo restante: {countdown} segundos</p>
            {/* Add any additional elements or styling as needed */}
        </div>
    );
};

export default CountdownComponent;