import { useState } from "react";
import { faEdit, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, TextField, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { ChangeStatus, Delete, GetAllBankAccounts } from "../../../../controllers/company/AccountNumbersController";
import ModalDialog from "../../../../components/modalDialog";

export default function BankAccountDataTable(props)
{
    const [bankAccounts,setBankAccounts] = useState(props.bankAccounts);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(false);
    const [action,setAction] = useState('');
    const [id,setId] = useState('');

    const [selectedAccount,setSelectedAccount] = useState(null);
    const [openRemoveModal,setOpenRemoveModal] = useState(false);
    const [removeWord,setRemoveWord] = useState('');
    const [removeWordError,setRemoveWordError] = useState(false);

    const handleGetAll = async () => {
        let response = await GetAllBankAccounts();
        if(response.success === true)
        {
            setBankAccounts(response.data.accounts);
        }
    }

    const handleGetccountToRemove = (item) => {
        setSelectedAccount(item);
        setOpenRemoveModal(true);
    }

    const columns = [
        {
            name : 'description',
            label : 'Nombre',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'uso',
            label : 'Usar Para',
            
            options:{
                filter:true
            }
        },
        {
            name : 'payment_method',
            label : 'Forma de Pago',
            
            options:{
                filter:true,
                searchable:true
            }
        },
        {
            name : 'bank',
            label : 'Entidad/Plataforma',
            
            options:{
                filter:true
            }
        },
        {
            name : 'account_type',
            label : 'Tipo de Cuenta',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:true,
                searchable:true
            }
        },
        {
            name : 'account_number',
            label : 'Cuenta',
            
            options:{
                filter:false
            }
        },
        {
            name : 'status',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Activo'
                    ? <Chip label='Activo' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => handleChangeStatus(bankAccounts[dataIndex].id)  }  
                            >
                                <IconButton>
                                {
                                    bankAccounts[dataIndex].estatus === 'Activo'
                                    ? 
                                    <Tooltip title='Inactivar' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Activar' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                }
                                </IconButton>
                            </Link>

                            
                            <Link 
                                to={'/bankAccounts/update/'+bankAccounts[dataIndex].id} 
                                //onClick={ ()=> localStorage.setItem('evento',JSON.stringify(bankAccounts[dataIndex])) }
                                replace
                            >
                                <Tooltip title='Editar' placement='top'>
                                    <IconButton>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>

                            <Link
                                onClick={ ()=> handleGetccountToRemove(bankAccounts[dataIndex]) }
                                
                            >
                                <Tooltip title='Remove' placement="top">
                                    <IconButton>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const handleChangeStatus = async (id) => {
        let response = await ChangeStatus(id);
        if(response.success === true)
        {
            handleGetAll();
        }
    }

    const handleRemoveCustomer = async () => {
        if(formVerification() === 0)
        {   
            setIsLoading(true);
            let response = await Delete(selectedAccount.id);
            if(response.success === true)
            {
                handleClose()
                handleGetAll();
            }
            setIsLoading(false);
        }
    }

    const handleClose = () => {
        setSelectedAccount(null);
        setRemoveWord('');
        setOpenRemoveModal(false);
    }

    const formVerification = () => {
        let ban = 0;
        if(removeWord !== 'eliminar')
        {
            setRemoveWordError(true);
            ban = 1;
        }

        return ban;
    }



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <>
        <MUIDataTable
            title={"Listado de Eventos"}
            data={bankAccounts}
            columns={columns}
            options={options}
        />
            <ModalDialog
                open={open}
                title={title}
                msg={message}
                action={action}
                handleAction={()=>console.log('hola')}
            />

            <Dialog open={openRemoveModal} onClose={()=>handleClose()}>
                <DialogTitle>Eliminar Número de Cuenta</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    ¿Estás seguro que deseas eliminar la cuenta {selectedAccount && <span style={{fontWeight:'bold'}}>{selectedAccount.descripcion}</span>}?
                    Para confirmar escribe la palabra 'eliminar' y luego haz click en el botón eliminar
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="removeWord"
                    placeholder="Escribe 'eliminar' "
                    fullWidth
                    variant="standard"
                    value={removeWord}
                    onChange={(e)=>setRemoveWord(e.target.value)}
                    onBlur={ () => (removeWord === '' ? setRemoveWordError(true) : setRemoveWordError(false) ) }
                    error = {removeWordError}
                    helperText={removeWordError && 'Debes escribir la palabra "eliminar"'}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose()} disabled={isLoading}>Cancelar</Button>
                    <Button variant="contained" color="error" onClick={()=>handleRemoveCustomer()} disabled={isLoading}>
                        {
                            isLoading === true
                            ?
                            <CircularProgress/>
                            :
                            'Eliminar'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}