import { Container, Grid, makeStyles, Typography } from "@mui/material";

export default function Privacy()
{
    

    const styles ={
        Subtitle: {
            fontWeight:'bold',
            lineHeight:1.8,
            letterSpacing:0.7,
            color:'#212121',
            
        },
        Text:{
            lineHeight:1.8,
            letterSpacing:0.7,
            color:'#212121',
            textAlign:'justify'
        },
        highlighted : {
            color:'#212121',
            fontWeight:'bold'
        },
    };

    return(
        <Container maxWidth='lg' sx={{mt:20,mb:10}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h4">Políticas de privacidad</Typography>
                    <Typography mt={5} variant='body1' gutterBottom>
                        Estas políticas de privacidad explican nuestras prácticas (y sus elecciones) con respecto a la recopilación, el uso y la divulgación de ciertos datos, incluida su información personal en relación con el servicio de QUD.
                    </Typography>

                    {/**CONTACTO */}
                    <Typography mt={5} variant='body1' style={styles.Subtitle}>
                        Contacto
                    </Typography>
                    <Typography mt={2} variant='subtitle1' paragraph style={styles.Text}>
                        Si tiene preguntas generales sobre su cuenta o cómo ponerse en contacto con nuestro Servicio al Cliente para obtener asistencia, 
                        si tiene alguna pregunta específica respecto de esta Declaración de privacidad, o nuestro uso de su información personal, de las 
                        cookies o de otras tecnologías similares, escriba a nuestro responsable de Protección de Datos o a la Oficina de Privacidad al email 
                        &nbsp;<span style={styles.highlighted}>support@qud.life</span>.
                    </Typography>

                    <Typography mt={2} variant='subtitle1' paragraph  style={styles.Text}>
                        El responsable del tratamiento de los datos de su información personal es <sapn style={styles.highlighted}>Technological Sharks CA</sapn> de ahora en adelante QUD. Tenga en cuenta que, si se comunica con nosotros para obtener ayuda, tanto por su seguridad como por la nuestra, podemos pedirle que autentique su identidad antes de atender su solicitud.
                    </Typography>


                    <br/>
                    <br/>
                    {/**RECOPILACION DE INFORMACION */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        Recopilación de la información
                    </Typography>

                    <Typography variant='subtitle1' paragraph  style={styles.Text}>
                        A través de este sitio web no se recaban datos de carácter personal de los usuarios sin su conocimiento, 
                        ni se ceden a terceros. Toda comunicación con QUD por cualquier medio o a través de su dirección de correo 
                        electrónico, o de los formularios presentes en este sitio web o a su e-mail, supone el consentimiento expreso para que sus datos personales en calidad de Cliente/Proveedor/Contacto/Repartidor, sean incorporados en nuestro Registro de Actividades y a ficheros o bases de datos titularidad de Technological Sharks, que usted puede consultar y se conservarán mientras se mantenga la relación o durante los años necesarios para cumplir con las obligaciones legales. 
                    </Typography>

                    <Typography paragraph  style={styles.Text}>
                        Los datos de carácter personal que se pudieran recabar directamente del interesado serán tratados de forma 
                        confidencial y quedarán incorporados a la correspondiente actividad de tratamiento. La finalidad del 
                        tratamiento de los datos corresponde a cada una de las actividades de tratamiento que realiza QUD y que 
                        Usted tiene derecho a obtener confirmación sobre si estamos tratando sus datos personales, por tanto, 
                        tiene derecho a acceder a sus datos personales, rectificar los datos inexactos o solicitar que sean rectificados.
                    </Typography>

                    <Typography paragraph  style={styles.Text}>
                        Este documento de Política de Privacidad pauta los términos en que QUD maneja y protege la información que es 
                        proporcionada por sus usuarios al momento de utilizar su plataforma cuyo dominio es <span style={styles.highlighted}>qud.life</span> (que 
                        incluye el formato Web y la “App”), redes sociales o cualquier medio relacionado con la marca. 
                        Estamos comprometidos con la responsabilidad en la seguridad de los datos de nuestros usuarios en concordancia 
                        con las legislaciones locales y las consideraciones éticas correspondientes. Cuando le pedimos llenar los campos
                        de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se emplea 
                        de acuerdo con los términos de este documento.
                    </Typography>

                    <Typography paragraph  style={styles.Text}>
                        Antes de registrase en QUD, los Usuarios deben leer la presente Política de Privacidad. Al marcar el botón 
                        “registrarse” o su equivalente, los Usuarios afirman que han leído y que consienten expresamente las presente 
                        Política de Privacidad, en particular la finalidad con la que se recopilan los datos, así como cualquier 
                        cesión de datos prevista en la presente Política de Privacidad. Asimismo, QUD, siempre y cuando los Usuarios 
                        lo autoricen, recogerá datos relacionados con su localización y otros requeridos.
                    </Typography>

                    <Typography paragraph  style={styles.Text}>
                        Una vez completado el registro, todo Usuario podrá acceder a su perfil y completarlo y/o editarlo 
                        según estime conveniente. La información y datos facilitados por el Usuario estarán en todo momento 
                        disponibles en su cuenta de usuario y podrán ser modificados por el Usuario a través de la opción 
                        editar perfil.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        El Usuario se compromete a introducir datos reales y veraces. Asimismo, será el único responsable 
                        de los daños y perjuicios que QUD, sus aliados o terceros pudieran sufrir como consecuencia de la 
                        falta de veracidad, inexactitud, falta de vigencia y autenticidad de los datos facilitados. Los datos 
                        recabados por QUD, serán exclusivamente utilizados para la consecución del objeto definido en los 
                        términos y condiciones y las condiciones descritas en la presente Política. Toda la información personal 
                        que facilite o se recoja a través de la App y el sitio Web será tratada principalmente por QUD, como 
                        responsable del tratamiento.
                    </Typography>


                    <br/>
                    {/** 1 - LEGISLACION APLICABLE */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        1.LEGISLACIÓN APLICABLE A LOS DATOS PERSONALES DE LOS USUARIOS (PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL): 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD tiene el compromiso de cumplir con la legislación vigente en cada momento en materia de 
                        protección de datos y mantenerse actualizado al respecto. Se informa a los usuarios que los 
                        datos de carácter personal que sean facilitados en la plataforma serán objeto de tratamiento 
                        automatizado y pasarán a formar parte de la base de datos de QUD, siendo el responsable del 
                        tratamiento, para las finalidades propias de gestión de usuarios registrados en la Plataforma de QUD.
                    </Typography>



                    <br/><br/>
                    {/** 2 - INFORMACIÓN REQUERID:  */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        2.INFORMACIÓN REQUERIDA
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        La Plataforma de QUD se utiliza tanto por los Usuarios, como por nuestros Proveedores y repartidores, 
                        los cuales manejan la siguiente información:
                    </Typography>

                    <div style={{marginLeft:20}}>
                        <Typography paragraph style={styles.Text}>
                            <span style={styles.highlighted}>Información que los Usuarios nos facilitan directamente:</span> 
                        </Typography>
                            
                        <Typography paragraph style={styles.Text}>
                            • Datos de Registro : la información que el Usuario nos facilita cuando se crea una cuenta en 
                            la Plataforma de QUD: nombre del Usuario y el correo electrónico.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Información del Perfil del Usuario: la información que el Usuario añade en la Plataforma a 
                            efectos de poder utilizar el servicio de QUD, esto es, el teléfono móvil y la dirección de 
                            entrega del Usuario. El Usuario puede ver y editar los datos personales de su perfil cuando 
                            estime oportuno. QUD no almacena los datos de la tarjeta de crédito del Usuario, datos bancarios, 
                            pero éstos se facilitan a prestadores de servicios de pago electrónico con licencia, los cuales 
                            recibirán directamente los datos incluidos y los almacenarán para facilitar el proceso de pago al 
                            Usuario y gestionarán el mismo en nombre de QUD. En ningún caso QUD almacena en sus servidores estos datos. 
                            El Usuario podrá, en cualquier momento, eliminar los datos, con esa acción, el proveedor de servicios 
                            procederá al borrado de los datos y será necesario volver a introducirlos o seleccionarlos para poder 
                            realizar nuevos pedidos a través de la Plataforma.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Información acerca de las comunicaciones realizadas con QUD: se tendrá acceso a información que 
                            los Usuarios faciliten para la resolución de dudas o quejas sobre el uso de la plataforma. 
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            El tratamiento de estos datos por parte de QUD es necesario para poder cumplir con la relación 
                            contractual que se establece. Si el Usuario no los facilita, los servicios solicitados podrán no 
                            estar disponibles.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            
                        </Typography>
                    </div>


                    <div style={{marginLeft:20,marginTop:30}}>
                        <Typography paragraph style={styles.Text}>
                            <span style={styles.highlighted}>Información que los Usuarios nos facilitan indirectamente:</span> 
                        </Typography>
                            
                        <Typography paragraph style={styles.Text}>
                            • Datos derivados del Uso de la Plataforma: QUD puede recolectar los datos derivados del 
                            uso de la Plataforma por parte del Usuario cada vez que éste interactúa con la Plataforma, 
                            como: Dirección IP de Internet que utiliza el Usuario para conectarse a Internet con su 
                            ordenador o móvil. Información de su ordenador o móvil, como su conexión a Internet, su 
                            tipo de navegador, la versión y el sistema operativo, y el tipo de dispositivo. El 
                            clickstream completo de localizadores de recursos Uniformes (URL), incluyendo la fecha y la 
                            hora. 
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Datos de la cuenta del Usuario: la información de los pedidos realizados por parte del 
                            Usuario, así, como las valoraciones y/o comentarios que realice acerca de los mismos. El 
                            historial de navegación y las preferencias del Usuario.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Datos derivados de la gestión de incidencias: si el Usuario se dirige a la Plataforma de 
                            QUD a través del Formulario de Contacto o a través de nuestros números de teléfonos, QUD 
                            recopilará los mensajes recibidos en el formato utilizado por el Usuario y los podrá utilizar 
                            y almacenar para gestionar incidencias presentes o futuras. Datos derivados de las “cookies”.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            La información facilitada por el tercero externo podrá ser controlada por el Usuario según la 
                            propia política de privacidad del tercero externo.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            
                        </Typography>
                    </div>



                    <br/><br/>
                    {/** 3 - FINALIDAD DE LOS DATOS:   */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        3.FINALIDAD DE LOS DATOS: 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Los datos que QUD recopila de los Usuarios serán usados para que los mismos puedan acceder y 
                        comunicarse con la plataforma y para prestar los servicios que soliciten a través de la misma, 
                        según el mecanismo descrito en los “Términos de Uso”.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Durante la ejecución de un pedido, nuestros operadores de atención al cliente, repartidores, 
                        los terceros proveedores de servicios que ejecutan pedidos, envían paquetes, solucionan incidencias 
                        con los envíos, tendrán acceso a la información personal de los usuarios necesaria para realizar 
                        sus funciones, pero no podrán utilizarla para otros fines. En dicho caso QUD facilitara a los 
                        proveedores con los cuales mantiene acuerdos comerciales el nombre de usuario, teléfono y/o correo 
                        electrónico con la finalidad de facilitar la ejecución y transacción del pedido. En el supuesto que 
                        el Usuario contacte directamente con los proveedores descritos y facilite sus datos directamente a 
                        éstos, QUD no será responsable por el uso que los proveedores hagan de los datos recopilados.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD garantiza que todo su personal, los socios comerciales, técnicos, proveedores, o terceros 
                        independientes están unidos mediante promesas contractualmente vinculantes para procesar información 
                        que se comparte con ellos siguiendo las indicaciones que QUD le da en la presente Política de Privacidad, 
                        así como la legislación aplicable en materia de protección de datos. 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD podrá utilizar los datos personales que el Usuario le facilita para realizar comunicaciones vía 
                        correo electrónico y/o enviar SMS al Usuario acerca de la operativa del servicio. QUD enviará mensajes 
                        al teléfono del Usuario con información relativa al estado del pedido solicitado, y una vez terminado, 
                        se enviará un resumen/ recibo del pedido y del precio del mismo al correo electrónico y/o teléfono del 
                        Usuario. Al registrarse en la Plataforma se entenderá que el Usuario acepta la presente Política de 
                        Privacidad y que, por lo tanto, autoriza a QUD a realizar ambas comunicaciones.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Se utiliza la información para investigar y analizar cómo mejorar los servicios que QUD presta a los 
                        Usuarios, así como para desarrollar y mejorar las características del servicio que ofrece. QUD utiliza 
                        la información con fines estadísticos a efectos de analizar el comportamiento y las tendencias de los 
                        Usuarios, de comprender cómo los Usuarios utilizan la Plataforma y de gestionar y mejorar los servicios 
                        ofrecidos. 
                    </Typography>




                    <br/><br/>
                    {/** 4 - GARANTIZAR LA SEGURIDAD EN LA PRESTACIÓN DE SERVICIOS:    */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        4.GARANTIZAR LA SEGURIDAD EN LA PRESTACIÓN DE SERVICIOS: 
                    </Typography>

                    <Typography style={styles.Text}>
                        Se utilizan los datos a efectos de garantizar el buen uso de los productos solicitados en la 
                        Plataforma (asegurar consejo farmacéutico, garantizar la entrega a mayores de 18 años, entre otros). 
                        Se puede utilizar la información de los Usuarios con el propósito de detectar e investigar fraudes, 
                        así como otras actividades ilegales y quebrantamientos potenciales de nuestros “Términos de Uso”. 
                        QUD podrá compartir datos del usuario con plataformas que analicen las operaciones de fraude. 
                    </Typography>



                    <br/><br/>
                    {/** 5 - PROMOCIÓN Y OFERTAS COMERCIALES:     */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        5.PROMOCIÓN Y OFERTAS COMERCIALES:  
                    </Typography>

                    <Typography style={styles.Text}>
                        QUD podrá utilizar tecnología de terceros integrada en su Plataforma a los efectos de recabar sus 
                        datos y preferencias y utilizarlos con sistemas de CRM y tecnología avanzada en beneficio de los 
                        Usuarios. Así, mediante la información recabada se realizarán los siguientes tratamientos de sus 
                        datos:
                    </Typography>

                    <div style={{marginLeft:20}}>
                        <Typography style={styles.Text}>
                            • Enviar al correo electrónico de los Usuarios mensajes promocionales y/o ofertas relativas al 
                            servicio que ofrece y que pudieran interesar al Usuario. QUD podrá medir y personalizar dicha 
                            publicidad en función de las preferencias de los Usuarios. El Usuario que no desee recibir 
                            información y/o comunicaciones comerciales, podrá optar en cualquier momento a la opción de 
                            “Cancelar la suscripción” en el propio correo electrónico y QUD cesará inmediatamente en el 
                            envío de la citada información.
                        </Typography>

                        <Typography style={styles.Text}>
                            • Se podrá enviar al Usuario a su teléfono móvil mensajes y/u ofertas relativas a dichos servicios.
                        </Typography>

                        <Typography style={styles.Text}>
                            • QUD y/o los terceros asociados con la plataforma, podrán utilizar la dirección de entrega del 
                            pedido introducida por el Usuario a efectos de realizar actividades promocionales para la entrega 
                            de muestras o productos gratuitos del servicio relacionado con QUD (entrega de muestras gratuitas 
                            a domicilio o folletos publicitarios) siempre junto a la entrega del pedido que puedan ser de interés 
                            del Usuario.
                        </Typography>
                    </div>



                    <br/><br/>
                    {/** 6 - COMPARTIR LOS DATOS DE LOS USUARIOS CON TERCEROS:     */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        6.COMPARTIR LOS DATOS DE LOS USUARIOS CON TERCEROS: 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Si el Usuario conecta su cuenta de QUD a otra red social o a la plataforma de un tercero, se podría 
                        utilizar la información cedida a esa red social o tercero, siempre que dicha información se haya 
                        puesto a disposición de QUD cumpliendo con la política de privacidad de dicha red social o plataforma 
                        de tercero.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Si la propiedad de QUD cambiara o fueran adquiridos por un tercero, los Usuarios serán informados 
                        y se transferirá sus datos a las entidades del adquirente con la finalidad de seguir prestando los 
                        servicios objeto del tratamiento de datos.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD podrá transferir sus datos personales a terceros asociados con la plataforma siempre y cuando 
                        el Usuario hubiera aceptado expresamente la presente Política de Privacidad.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Previo requerimiento legal, QUD podrá compartir información con organismos de autoridades ejecutivas 
                        y/o terceros con respecto a peticiones de información relativas a investigaciones de carácter penal 
                        y presuntas actividades ilegales
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Para ofrecer un Servicio de Atención al cliente y, realizar acciones dirigidas a la medición del grado 
                        de satisfacción de los cleintes y prestadores de servicios QUD podrá compartir los datos recopilados 
                        con servicios de Call Center y gestión de incidencias
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        para el control del fraude QUD compartirá los datos de los Usuarios con proveedores que ofrecen servicios 
                        de control del fraude, para estudiar el riesgo de las operaciones que se llevan a cabo.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Los datos de los Usuarios de QUD no se transmitirán a ningún tercero a excepción de que:
                    </Typography>

                    <div style={{marginLeft:20}}>
                        <Typography paragraph style={styles.Text}>
                            • sea necesario para los servicios solicitados, en caso de que se colabore con terceros
                        </Typography>
        
                        <Typography paragraph style={styles.Text}>
                            • cuando el Usuario haya autorizado la presente Política de Privacidad al darse de alta 
                            en la Plataforma, considerándose con ello el consentimiento expreso del usuario a estos 
                            fines
                        </Typography>
        
                        <Typography paragraph style={styles.Text}>
                            • cuando se solicite por parte de autoridad competente en el ejercicio de sus funciones 
                            (para investigar, prevenir o tomar acciones relativas a acciones ilegales) o 
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • por último, si lo requiere la ley.
                        </Typography>
                    </div>

                    <Typography paragraph style={styles.Text}>
                        Ninguna de las comunicaciones de datos, incluirá utilizar de otro modo información personal de clientes 
                        con fines contrarios a los compromisos adquiridos en la presente Política de Privacidad.
                    </Typography>
                    
                    <Typography paragraph style={styles.Text}>
                    </Typography>
                    



                    <br/><br/>
                    {/** 7 - DERECHO A RETIRAR SU CONSENTIMIENTO:      */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        7.DERECHO A RETIRAR SU CONSENTIMIENTO:
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        En cualquier momento el Usuario podrá retirar su consentimiento para el tratamiento de datos por 
                        parte de QUD. Para su retirada bastará con que el Usuario contacte a través de:  support@qud.life 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        La retirada del consentimiento podrá realizarse en cualquier momento por parte del interesado. 
                        Los Usuarios aceptan y consienten que la retirada del consentimiento implicará que QUD no pueda 
                        prestar los servicios propios de su Plataforma.
                    </Typography>



                    <br/><br/>
                    {/** 8 - DERECHO A RETIRAR SU CONSENTIMIENTO:      */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        8.PROTECCIÓN DE LOS DATOS DE LOS USUARIOS: 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD adopta las medidas necesarias para mantener el nivel de seguridad requerido, según la 
                        naturaleza de los datos personales tratados y las circunstancias del tratamiento, con el 
                        objeto de evitar, en la medida de lo posible y siempre según el estado de la técnica, su 
                        alteración, pérdida, tratamiento o acceso no autorizado. Como se ha mencionado anteriormente, 
                        los datos personales facilitados no serán cedidos a terceros si el titular de los mismos no 
                        hubiera aceptado la presente Política de Privacidad.
                    </Typography>



                    <br/><br/>
                    {/** 9. - CAMBIOS EN POLÍTICAS DE PRIVACIDAD:       */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        9.CAMBIOS EN POLÍTICAS DE PRIVACIDAD:  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD se reserva el derecho, a su discreción, de cambiar, modificar, añadir o quitar cualquier 
                        porción de las políticas de privacidad, en cualquier momento. Los cambios serán efectivos cuando 
                        se publiquen. La continuación del uso de la plataforma y/o de los servicios puestos a disposición 
                        después de haber sido publicado cualquier cambio, será considerado como aceptación de esos cambios.
                    </Typography>

                    <br/><br/>
                    <Typography paragraph style={styles.Text}>
                        Cualquier comentario, información, sugerencia u otro puede comunicarse a través de:  info@qud.life
                    </Typography>

                    <br/><br/>
                    <Typography paragraph style={styles.Text}>
                        <strong>Última actualización:</strong> 19 de febrero de 2021
                    </Typography>

                </Grid>
            </Grid>
        </Container>
    )
}



