import { useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Divider, Grid, List, ListItem, ListItemText, ListSubheader, Paper, Typography, useTheme } from "@mui/material";

import img_fondo from '../../../../assets/images/allies/mamaazul/fondo4.png';
import img_eazul from '../../../../assets/images/allies/mamaazul/eazul.png';
import img_pricing from '../../../../assets/images/allies/mamaazul/papel.png';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheckSquare, faMapLocation, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { getSponsors } from "../../../../controllers/customer/EventosController";
import { imagesURL } from "../../../../apiRoutes/apiBase";
import { Helmet, HelmetProvider, HelmetData } from 'react-helmet-async';

const helmetData = new HelmetData({});

export default function MamaAzul(props)
{
    const [sponsors,setSponsors] = useState([]);
    const history = useHistory();
    const pageDescription = 'Camina y corre por el autismo'

    const princing = [
        {
            plan:'Caminata Familiar de 2K',
            price: 12.00,
            benefits:[
                'Franela',
            ]
        },
        {
            plan:'Carrera de 10K',
            price: 25.00,
            benefits:[
                'Franela',
                'Visera',
                'Bolso',
            ]
        }
    ];

    const theme = useTheme();

    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetSponsors();
    },[]);

    const handleGetSponsors = async () => {
        let response = await getSponsors(14);
        if(response.success === true)
        {
            setSponsors(response.data);
        }
    }

    return(
        <Box sx={{overflowX:'hidden',mt:-6}}>
            <Helmet helmetData={helmetData}>
                <title>Mama Azul</title>
                <meta name="description" content={pageDescription} />
            </Helmet>
            <Box
                sx={{
                    backgroundImage: `url(${img_fondo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '90vh',
                    width: '100vw',
                    //filter: 'brightness(50%)',
                    //backdropFilter: 'blur(10px)',
                    position:'relative'
                }}
            >
                <Container maxWidth='lg' sx={{height:'100%'}}>
                <Grid container spacing={3} display={'flex'} flexDirection={'row'} alignContent={'center'} height={'100%'}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                        <Typography variant="h2" fontWeight={'bold'} color={'#FFF'} mb={-1}>
                            Camina y Corre
                        </Typography>
                        <Typography variant="h2" fontWeight={'bold'} color={'#FFF'} gutterBottom>
                            por el autismo
                        </Typography>
                        <Typography variant="h4" fontWeight={'bold'} color={'link.main'} gutterBottom>
                            28/04/2024
                        </Typography>
                        <Button
                            variant="contained"
                            color='link'
                            sx={{p:1}}
                            onClick={()=>redirect('eventos/14/tickets')}
                        >
                            <Typography variant="h4">&nbsp;&nbsp;Inscríbete ya&nbsp;&nbsp;</Typography>
                        </Button>
                    </Grid>
                </Grid>
                </Container>
            </Box>

            <Container maxWidth='lg'>
                <Grid container spacing={3} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography fontWeight={'bold'} color={'link.main'} sx={{mt:{xs:5}}}>
                            ¡Mamá Azul necesita tu apoyo!
                        </Typography>
                        <Typography variant="h2">
                            ¡Dona tus pasos!
                        </Typography>
                        <Typography variant='h6'>
                            Cada kilómetro que recorres suma.
                            Participa en nuestro maratón y caminata familiar y juntos construiremos un futuro mejor para los niños con autismo.
                        </Typography>
                        <Grid container spacing={3} mt={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Box sx={{bgcolor:'#f5f5f5',p:2,borderRadius:3,display:'flex',flexDirection:'row'}}>
                                    <FontAwesomeIcon size="2x" color="#0599DE" icon={faMapLocationDot}/>
                                    <Box ml={2}>
                                        <Typography variant="h6" fontWeight={'bold'}>Lugar</Typography>
                                        <Typography variant="body1" >Playa Lido. Lechería, Edo. Anzoátegui</Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Box sx={{bgcolor:'#f5f5f5',p:2,borderRadius:3,display:'flex',flexDirection:'row'}}>
                                    <FontAwesomeIcon size="2x" color="#0599DE" icon={faCalendarAlt}/>
                                    <Box ml={2}>
                                        <Typography variant="h6" fontWeight={'bold'}>Fecha</Typography>
                                        <Typography variant="body1" >Domingo 28 de abril de 2024</Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color='link'
                                    sx={{p:1}}
                                    onClick={()=>redirect('eventos/14/tickets')}
                                >
                                    <Typography variant="h4">&nbsp;&nbsp;Inscríbete ya&nbsp;&nbsp;</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{width:'100%'}}>
                            <img
                                src={img_eazul}
                                alt="Mama Azul"
                                style={{width:'100%'}}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box
                sx={{
                    backgroundImage: `url(${img_pricing})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '70vh',
                    width: '100vw',
                    //filter: 'brightness(50%)',
                    //backdropFilter: 'blur(10px)',
                    position:'relative'
                }}
            >
            <Container maxWidth='lg' sx={{mt:5,mb:5}}>
                <Grid container spacing={3} justifyContent={'center'} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h4' fontSize={45} fontWeight={'bold'} color='primary.main' textAlign={'center'}>
                            Categorías disponibles
                        </Typography>
                    </Grid>
                    {
                        princing.length !== 0 &&
                        princing.map(item =>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{mt:{xs:5}}}>
                                <Paper elevation={4} sx={{height:'100%'}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" fontWeight={'bold'} color={'link.main'}>
                                                {item.plan}
                                            </Typography>
                                            <Box sx={{display:'flex',flexDirection:'row'}}>
                                                <Typography variant="h4" fontWeight={'bold'}>{item.price} $</Typography>
                                                <Typography variant="h6" fontWeight={'bold'} ml={1}>/persona</Typography>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                ¿Qué incluye?
                                            </Typography>
                                            {
                                                item.benefits.map(benefit=>
                                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',mt:1}}>
                                                    <FontAwesomeIcon color={theme.palette.link.main} icon={faCheckSquare}/>
                                                    <Typography variant="body1" fontWeight={'bold'} ml={1}>{benefit}</Typography>
                                                </Box>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'} mt={10}>
                        <Button
                            variant="contained"
                            color='link'
                            sx={{p:1}}
                            onClick={()=>redirect('eventos/14/tickets')}
                        >
                            <Typography variant="h4">&nbsp;&nbsp;Inscríbete ya&nbsp;&nbsp;</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            </Box>
            


            <Container maxWidth='lg' sx={{mt:15}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography fontWeight={'bold'} color={'link.main'} sx={{mt:{xs:35,sm:8,md:5,lg:0,xl:0}}}>
                            Actividades
                        </Typography>
                        <Typography variant="h4" fontWeight={'bold'}>
                            Itinerario
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="h6" fontWeight={'bold'}>Sábado 27 de abril de 2024</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant="h6">02:00 pm - Entregas de Kits en el CC Plaza Mayor</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="h6" fontWeight={'bold'}>Domingo 28 de abril de 2024</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant="h6" gutterBottom>06:30 am - Salida de la carrera de 10K</Typography>
                        <Typography variant="h6">07:30 am - Salida de la caminata familiar de 2K</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    </Grid>
                </Grid>
            </Container>


            <Container maxWidth='lg'>
                <Grid container spacing={3} mt={5} mb={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography fontWeight={'bold'} color={'link.main'}>
                            Patrocinado por
                        </Typography>
                        <Typography variant="h4" fontWeight={'bold'}>
                            Nuestros socios y colaboradores
                        </Typography>
                    </Grid>
                    {
                        sponsors.length !==0 &&
                        sponsors.map(item=>
                            <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                <Button
                                    href={item.url}
                                    target="_blank"
                                >
                                    <img
                                        src={imagesURL+'eventos/patrocinadores/'+item.ruta_imagen}
                                        style={{width:'100%'}}
                                        alt="qud"
                                    />
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>

        </Box>
    )
}