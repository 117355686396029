import React, { useEffect, useReducer, useState } from "react";
import { Box, Chip, Container, Grid, IconButton, Link, Paper, Slide, Table, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../../../components/breadcrumbs";
import SectionTitle from "../../../../../components/sectionTitle";
import { GetEvent, SectionChangeStatus, SectionCreate, SectionList, SectionUpdate } from "../../../../../controllers/company/EventsController";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { imagesURL } from "../../../../../apiRoutes/apiBase";
import QUDProgress from "../../../../../components/progress";
import { LoadingButton } from "@mui/lab";
import MUIDataTable, { TableBody, TableHead } from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    seccion_id: '',
    nombre : '',        errorNombre : false,
    descripcion : '',   errorDescripcion : false,
    capacidad:'',       errorCapacidad: false,
    precio:'',          errorPrecio: false,
    multiplos:'',       errorMultiplos: false,
    categoriaEvento:'', errorCategoriaEvento: false,
    ciudad:'',          errorCiudad: false,
    direccion:'',       errorDireccion: false,
    estatus:'',         errorEstatus: false,
    imagen: [],         errorImagen: false,
    rutaImagen: '',

    coordenadas:{lat : 10.153135, lng : -64.678742},
    errorCoordenadas : false,
    //center : {lat: 10.1976900, lng: -64.6940700},
    initialCenter : {lat:10.153135,lng:-64.678742},
    center : {lat : 10.153135, lng : -64.678742},
    markerPosition : {lat:10.153135,lng:-64.678742},
    markerPositionInicial : {lat:10.153135,lng:-64.678742},
  
    zoom : 16,
      
    isLoading : false,
    open : false,
    result : false, message : '', title : '',

    ciudades: [],
    categorias: [],

    
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function EventsSections(props)
{
    const [value, setValue] = useState(moment().format());

    const [rutaImg,setRutaImg] = useState('');
    const [img,setImg] = useState([]);
    const [state,setState] = useReducer(reducer,initialState);
    const [event,setEvent] = useState(props.event);
    const [isLoading,setIsLoading] = useState(false);

    const [isLoadingData,setIsLoadingData] = useState(false);
    const [sections,setSections] = useState([]);


    let { id } = useParams();

    const {
        seccion_id,
        nombre,errorNombre,
        descripcion, errorDescripcion,
        capacidad, errorCapacidad,
        precio,errorPrecio,
        multiplos, errorMultiplos
    } = state;

    useEffect(()=>{
        //handleGetEvent();
        handleGetSections()
    },[]);

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const handleGetSections = async () => {
        setIsLoadingData(true);
        let response = await SectionList(id);
        if(response.success === true)
        {
            setSections(response.data)
        }
        setIsLoadingData(false);
    }

    const handleGetSections2 = async () => {
        let response = await SectionList(id);
        if(response.success === true)
        {
            setSections(response.data)
        }
    }

    const handleChangeStatus = async (uuid) => {
        let response = await SectionChangeStatus(uuid);
        if(response.success === true)
        {
            handleGetSections2();
        }
    }

    const handleCreateSection = async () => {
        if(formVerification() === 0)
        {
            setIsLoading(true);
            let response = await SectionCreate(id,nombre,descripcion,capacidad,precio,multiplos);
            if(response.success === true)
            {
                handleClean();
                handleGetSections();
            }
            setIsLoading(false);
        }
    }

    const handleUpdateSection = async () => {
        if(formVerification() === 0)
        {
            setIsLoading(true);
            
            let response = await SectionUpdate(seccion_id,nombre,descripcion,capacidad,precio,multiplos);
            if(response.success === true)
            {
                handleClean();
                handleGetSections();
            }
            setIsLoading(false);
        }
    }

    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        /*if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }*/

        /*if(capacidad === '')
        {
            setState({field:'errorCapacidad',value:true});
            ban = 1;
        }*/

        if(precio === '')
        {
            setState({field:'errorPrecio',value:true});
            ban = 1;
        }

        
        return ban;
    }

    const handleClean = async () => {
        setState({field:'seccion_id',value:''});
        setState({field:'nombre',value:''});
        setState({field:'descripcion',value:''});
        setState({field:'capacidad',value:''});
        setState({field:'precio',value:''});
    }

    const handleSubmit = async () => {
        if(seccion_id === '')
        {
            handleCreateSection();
        }
        else
        {
            handleUpdateSection();
        }
    }


    const columns = [
        {
            name : 'nombre',
            label : 'Nombre',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography><strong style={{fontSize:17}}>{sections[dataIndex].nombre}</strong></Typography>
                            <Typography>{sections[dataIndex].descripcion}</Typography>
                        </div>
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'capacidad',
            label : 'Capacidad',
            
            options:{
                filter:true,
                searchable:true
            }
        },
        {
            name : 'precio',
            label : 'Precio',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:true,
                searchable:true
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='Activo' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => handleChangeStatus(sections[dataIndex].uuid)  }  
                            >
                                <IconButton>
                                {
                                    sections[dataIndex].estatus === 1
                                    ?
                                    <Tooltip title='Inactivar' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Activar' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                }
                                </IconButton>
                            </Link>

                            <IconButton
                                onClick={()=>{
                                    setState({field:'seccion_id',value:sections[dataIndex].uuid});
                                    setState({field:'nombre',value:sections[dataIndex].nombre});
                                    setState({field:'descripcion',value:sections[dataIndex].descripcion});
                                    setState({field:'capacidad',value:sections[dataIndex].capacidad});
                                    setState({field:'precio',value:sections[dataIndex].precio});
                                    setState({field:'multiplos',value:sections[dataIndex].multiplos});
                                }}
                            >
                                <Tooltip title='Editar' placement="top">
                                    <FontAwesomeIcon icon={faEdit}/>
                                </Tooltip>
                            </IconButton>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <Grid container spacing={3}>
            <Grid item lg={4}>
                <Paper elevation={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography>Agregar / Actualizar</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{'& .MuiTextField-root': { mb: 2 }}}>
                                <TextField
                                    fullWidth
                                    label='Nombre de la sección'
                                    name='nombre'
                                    id='nombre'
                                    required
                                    value={nombre}
                                    onChange = { onChange }
                                    onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                    error = {errorNombre}
                                    helperText={errorNombre && 'Ingrese el nombre del evento'}
                                />
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    label='Descripción de la sección'
                                    name='descripcion'
                                    id='descripcion'
                                    value={descripcion}
                                    onChange = { onChange }
                                    //onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                    error = {errorDescripcion}
                                    //helperText={errorDescripcion && 'Ingrese la descripción del evento'}
                                />
                                <TextField
                                    fullWidth
                                    label='Capacidad maxima de la sección'
                                    name='capacidad'
                                    id='capacidad'
                                    value={capacidad}
                                    onChange = { onChange }
                                    //onBlur={ () => (capacidad === '' ? setState({field:'errorCapacidad',value:true}) : setState({field:'errorCapacidad',value:false}) ) }
                                    error = {errorCapacidad}
                                    //helperText={errorCapacidad && 'Ingrese la caoacidad de la sección'}
                                />

                                <TextField
                                    fullWidth
                                    label='Precio (USD)'
                                    name='precio'
                                    id='precio'
                                    required
                                    value={precio}
                                    onChange = { onChange }
                                    onBlur={ () => (precio === '' ? setState({field:'errorPrecio',value:true}) : setState({field:'errorPrecio',value:false}) ) }
                                    error = {errorPrecio}
                                    helperText={errorPrecio && 'Ingrese la caoacidad de la sección'}
                                />

                                <TextField
                                    fullWidth
                                    label='Multiplos'
                                    name='multiplos'
                                    id='multiplos'
                                    required
                                    value={multiplos}
                                    onChange = { onChange }
                                    onBlur={ () => (multiplos === '' ? setState({field:'errorMultiplos',value:true}) : setState({field:'errorMultiplos',value:false}) ) }
                                    error = {errorMultiplos}
                                    helperText={errorMultiplos && 'Ingrese los multiplos de la sección'}
                                />
                            </Box>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} >
                                <LoadingButton
                                    loading={isLoading}
                                    style={{padding:15}}
                                    fullWidth
                                    variant="contained"
                                    onClick={()=>handleSubmit()}
                                    color={seccion_id === '' ? 'success' : 'primary'}
                                >
                                    <Typography>
                                        {
                                            seccion_id === ''
                                            ?'Agregar Sección'
                                            :'Actualizar Sección'
                                        }
                                        
                                    </Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item lg={8}>
                {
                    isLoadingData === true
                    ?
                        <QUDProgress/>
                    :
                    sections.length !== 0 &&
                    <MUIDataTable
                        title={"Listado de Secciones"}
                        data={sections}
                        columns={columns}
                        options={options}
                    />
                }
            </Grid>
        </Grid>
    )
}