import React, { useEffect, useState } from 'react'
import { Box, Container, Divider, Grid, List, ListItem, ListItemText, Typography, useTheme } from "@mui/material";

import img_cientificos from '../../../../assets/images/allies/usm/cientificos.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarAlt, faClock, faMapLocation, faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getSponsors } from '../../../../controllers/customer/EventosController';
import { imagesURL } from '../../../../apiRoutes/apiBase';

export default function Usm()
{
    const theme = useTheme();

    const [sponsors,setSponsors] = useState([]);

    useEffect(()=>{
        handleGetSponsors();
    },[]);

    const handleGetSponsors = async () => {
        let response = await getSponsors(9);
        if(response.success === true)
        {
            setSponsors(response.data);
        }
    }

    const dia1 = [
        {
            hora: '08:00 - 08:45',
            descripcion: 'Acto de Instalación',
            ponente:''
        },
        {
            hora: '09:00 - 09:45',
            descripcion: 'Fármacos Opioides',
            ponente:'Msc. Omar Martinez'
        },
        {
            hora: '10:00 - 10:45',
            descripcion: 'Insuficiencia Venosa y su relación de la farmacología',
            ponente:'Dr. Gustavo Luccioni'
        },
        {
            hora: '11:00 - 11:45',
            descripcion: 'Evolución de los pisos de venta de las farmacias',
            ponente: 'Esp. Mercadeo Karen Briceño'
        },
        {
            hora: '12:00 - 13:00',
            descripcion: 'Receso',
            ponente:''
        },
        {
            hora: '13:00 - 13:45',
            descripcion: 'Senescencia endotelial y activación plaquetaria (Online – desde Chile)',
            ponente:'Dra. Whitney Venturini – Egresada USM Oriente'
        },
        {
            hora: '14:00 - 14:45',
            descripcion: 'Resistencia bacteriana: nuevos antibióticos',
            ponente: 'Dr. Nubraska Ramirez'
        },

        {
            hora: '15:00 - 15:45',
            descripcion: 'Presentación de Trabajo Especial de Grado 2023-02',
            ponente:''
        },
        {
            hora: '16:00 - 16:45',
            descripcion: 'Actividad Interactiva Farma Match',
            ponente: ''
        },
    ];

    const dia2 = [
        {
            hora: '08:00 - 08:45',
            descripcion: 'Nuevas materias primas y sus usos en Dermacosmética',
            ponente:'Ftico. Elenise Mendoza'
        },
        {
            hora: '09:00 - 09:45',
            descripcion: 'Blastocytis sp en humanos, actualización y experiencia clínica terapéutica',
            ponente:'Lic. En Bioanálisis Amarillis Liccioni'
        },
        {
            hora: '10:00 - 10:45',
            descripcion: 'Desde la investigación a tus manos',
            ponente:'Ftico. Michelle Caraballo (Lab. Leti)'
        },
        {
            hora: '11:00 - 11:45',
            descripcion: 'Atención Farmacéutica',
            ponente: 'Ftico María Lourdes de Natera (Farmatodo)'
        },
        {
            hora: '12:00 - 13:00',
            descripcion: 'Receso',
            ponente:''
        },
        {
            hora: '13:00 - 13:45',
            descripcion: 'Al rescate del corazón enfermo: mitocondrias y estrés oxidativo en la mira terapéutica (Online desde Hospital Universitario de Wurzburgo, Alemania)',
            ponente:'Phd. © David Weisman - Egresado USM Oriente'
        },
        {
            hora: '14:00 - 14:45',
            descripcion: 'Equipos para análisis de fisicoquímica HPLC, UPLC y cromatografía de gases utilizados en la industria farmacéutica',
            ponente: 'Ftico Maria Belen Aguilar (Lab. Farma)'
        },

        {
            hora: '15:00 - 15:45',
            descripcion: 'Acto de reconocimiento a los estudiantes con los mejores promedio semestre 2023-02',
            ponente:''
        },
        {
            hora: '16:00 - 16:45',
            descripcion: 'Bailoterapia',
            ponente:''
        },
    ];

    return(
        <Box sx={{position:'relative'}}>
            <Box
                sx={{
                    bgcolor:'usm.main',
                    minHeight: '95vh',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    pb:{xs:5,sm:5,md:0,lg:0,xl:0}
                }}
            >
                <Container sx={{mt:10}}>
                    <Typography
                        textAlign={'center'} fontWeight={'bold'} variant='h1' color={'usm.contrastTitle'}
                        sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}
                    >
                        Jornada<br/>Científica
                    </Typography>
                    <Typography
                        textAlign={'center'} fontWeight={'bold'} variant='h4' color={'usm.contrastText'}
                        sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}
                    >
                        70º ANIVERSARIO DE LA<br/>UNIVERSIDAD SANTA MARÍA
                    </Typography>

                    <Typography
                        textAlign={'center'} fontWeight={'bold'} variant='h2' color={'usm.contrastTitle'}
                        sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}
                    >
                        Jornada<br/>Científica
                    </Typography>

                    <Typography
                        textAlign={'center'} variant='h6' color={'usm.contrastText'}
                        sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}
                    >
                        70º ANIVERSARIO DE LA<br/>UNIVERSIDAD SANTA MARÍA
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                                sx={{
                                    position:'relative',
                                    left:{md:-200, lg:-650,xl:-650},
                                    bottom:{md:-80,lg:-100,xl:-100},
                                    display:{xs:'flex',sm:'flex',md:'block',lg:'block',xl:'block'},
                                    justifyContent:{xs:'center',sm:'center'},
                                    alignItems:{xs:'center',sm:'center'}
                                }}
                            >
                                <Box
                                    sx={{
                                        width:{xs:300,sm:400,md:600,lg:1000,xl:1000},
                                        height:{xs:300,sm:400,md:600,lg:1000,xl:1000},
                                        borderRadius:{xs:300,sm:400,md:600,lg:1000,xl:1000},
                                        bgcolor:'#FFF',
                                        padding:2
                                    }}
                                >
                                    <img style={{width:'100%',height:'100%',borderRadius:'100%'}} src={img_cientificos} alt='USM'/>
                                </Box>
                                
                            </Box>
                        </Grid>
                        
                        
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Typography
                                textAlign={'center'} fontWeight={'bold'} variant='h4' color={'usm.contrastText'}
                                sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}
                            >
                                DIRIGIDO A ESTUDIANTES DE<br/>FARMACIA
                            </Typography>

                            <Typography
                                textAlign={'center'} fontWeight={'bold'} variant='h6' color={'usm.contrastText'}
                                sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}
                            >
                                DIRIGIDO A ESTUDIANTES DE<br/>FARMACIA
                            </Typography>


                            <Typography
                                mt={5}
                                textAlign={'center'} fontWeight={'bold'} variant='h4' color={'usm.contrastText'}
                                sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}
                            >
                                INSCRIPCIÓN 12$
                            </Typography>

                            <Typography
                                mt={5}
                                textAlign={'center'} fontWeight={'bold'} variant='h6' color={'usm.contrastText'}
                                sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}
                            >
                                INSCRIPCIÓN 12$
                            </Typography>
                            <Typography variant='caption' fontWeight={'bold'} color={'usm.contrastTitle'}>
                                (Comprar boleto directamente a la USM)
                            </Typography>

                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'90%',mt:5}}>
                                <Grid container spacing={3}>

                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <Typography fontWeight={'bold'} variant='h6' color={'usm.contrastText'}>
                                                    MIÉRCOLES
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Typography textAlign={{xs:'right',sm:'right',md:'left',lg:'left',xl:'left'}} fontWeight={'bold'} variant='h6' color={'usm.contrastText'}>
                                                    01/11
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <Typography fontWeight={'bold'} variant='h6' color={'usm.contrastText'}>
                                                    JUEVES
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Typography textAlign={{xs:'right',sm:'right',md:'left',lg:'left',xl:'left'}} fontWeight={'bold'} variant='h6' color={'usm.contrastText'}>
                                                    02/11
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Box
                                            sx={{
                                                display:'flex',
                                                flexDirection:'row',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                bgcolor:'#FFF',
                                                width:70,
                                                height:70,
                                                borderRadius:80,
                                                border:4,
                                                borderColor:'usm.contrastTitle'

                                            }}
                                        >
                                            <FontAwesomeIcon size='2xl' color={theme.palette.usm.main} icon={faCalendarAlt}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>



                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'90%',mt:5}}>
                                <Grid container spacing={3} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Grid container spacing={0}>
                                            <Grid item lg={8} xl={8}>
                                                <Typography fontWeight={'bold'} variant='h6' color={'usm.contrastText'}>
                                                    8:00 AM A 4:00 PM
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Box
                                            sx={{
                                                display:'flex',
                                                flexDirection:'row',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                bgcolor:'#FFF',
                                                width:70,
                                                height:70,
                                                borderRadius:80,
                                                border:4,
                                                borderColor:'usm.contrastTitle'

                                            }}
                                        >
                                            <FontAwesomeIcon size='2xl' color={theme.palette.usm.main} icon={faClock}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'90%',mt:5}}>
                                <Grid container spacing={3} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Grid container spacing={0}>
                                            <Grid item lg={8} xl={8}>
                                                <Typography fontWeight={'bold'} variant='h6' color={'usm.contrastText'}>
                                                    SALÓN DE USOS<br/>
                                                    MULTIPLES EDIF B<br/>
                                                    2DO PISO
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Box
                                            sx={{
                                                display:'flex',
                                                flexDirection:'row',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                bgcolor:'#FFF',
                                                width:70,
                                                height:70,
                                                borderRadius:80,
                                                border:4,
                                                borderColor:'usm.contrastTitle'

                                            }}
                                        >
                                            <FontAwesomeIcon size='2xl' color={theme.palette.usm.main} icon={faMapMarkerAlt}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/**Sponsors */}
            <Box
                sx={{
                    bgcolor:'usm.contrastText',
                    paddingTop:10,
                    paddingBottom:10
                }}
            >
                <Container maxWidth='md'>
                    <Grid container spacing={3} justifyContent={'center'}>
                        {
                            sponsors.length !== 0 &&
                            sponsors.map(item=>
                                <Grid item xs={4} sm={4} md={3} lg={2} xl={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Box
                                        sx={{
                                            width:120,
                                            height:'auto',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            '&:hover':{cursor:'pointer'}
                                        }}
                                        component={'a'}
                                        href={item.url}
                                        target={'_blank'}
                                    >
                                        <img style={{width:'100%'}} src={imagesURL+'eventos/patrocinadores/'+ item.ruta_imagen}/>
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Container>
            </Box>

            {/**Itinerario */}
            <Box
                sx={{bgcolor:theme.palette.usm.main,pt:5,pb:5}}
            >
                <Typography
                    textAlign={'center'} fontWeight={'bold'} variant='h4' color={'usm.contrastTitle'}
                    sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}
                >
                    ITINERARIO
                </Typography>

                <Typography
                    textAlign={'center'} fontWeight={'bold'} variant='h6' color={'usm.contrastTitle'}
                    sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}
                >
                    ITINERARIO
                </Typography>

                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}  mt={5}>
                            <List
                                subheader={<Typography variant='h4' color={'usm.contrastText'}>01/11/2023</Typography>}
                            >
                                {
                                    dia1.map(item =>
                                        <>
                                        <ListItem sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            <Grid container spacing={3} alignItems={'baseline'}>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <Typography color={'usm.contrastText'} fontWeight={'bold'} sx={{textAlign:{xs:'left',sm:'left',md:'right',lg:'right',xl:'right'}}} mr={2}>{item.hora}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                    <ListItemText
                                                        primary={<Typography variant='h5' color={'usm.contrastText'}>{item.descripcion}</Typography>}
                                                        secondary={
                                                          <React.Fragment>
                                                            <Typography
                                                              sx={{ display: 'inline' }}
                                                              component="p"
                                                              variant="body1"
                                                              color="usm.contrastTitle"
                                                              
                                                            >
                                                              {item.ponente}
                                                            </Typography>
                                                            
                                                          </React.Fragment>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Box variant="inset" sx={{width:'100%',borderBottom:'solid',borderBottomWidth:0.1,borderBlockColor:'#41568c'}} />
                                        
                                        </>
                                    )
                                }
                            </List>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}  mt={5}>
                            <List
                                subheader={<Typography variant='h4' color={'usm.contrastText'}>02/11/2023</Typography>}
                            >
                                {
                                    dia2.map(item =>
                                        <>
                                        <ListItem sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            <Grid container spacing={3} alignItems={'baseline'}>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <Typography color={'usm.contrastText'} fontWeight={'bold'} sx={{textAlign:{xs:'left',sm:'left',md:'right',lg:'right',xl:'right'}}} mr={2}>{item.hora}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                    <ListItemText
                                                        primary={<Typography variant='h5' color={'usm.contrastText'}>{item.descripcion}</Typography>}
                                                        secondary={
                                                          <React.Fragment>
                                                            <Typography
                                                              sx={{ display: 'inline' }}
                                                              component="p"
                                                              variant="body1"
                                                              color="usm.contrastTitle"
                                                              
                                                            >
                                                              {item.ponente}
                                                            </Typography>
                                                            
                                                          </React.Fragment>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Box variant="inset" sx={{width:'100%',borderBottom:'solid',borderBottomWidth:0.1,borderBlockColor:'#41568c'}} />
                                        
                                        </>
                                    )
                                }
                            </List>
                        </Grid>
                        
                    </Grid>
                </Container>

            </Box>
        </Box>
    )
}