import { useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, Rating, Typography } from "@mui/material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import StarIcon from '@mui/icons-material/Star';
import SignInModal from "../views/auth/signInModal";
import UserContext from "../navigation/context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMinus, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ResponseComponent from "./responseComponent";
import QUDProgress from "./progress";

import { LocalizationProvider, MobileDatePicker, TimePicker, MobileTimePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { create } from "../controllers/customer/ReservationsController";

const labels = {
    1: 'Terrible',
    2: 'Malo',
    3: 'Normal',
    4: 'Bueno',
    5: 'Genial',
  };
  
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }




export default function Reserve(props)
{
    const {userData} = useContext(UserContext);
    const [value, setValue] = useState(1);

    const [date,setDate] = useState(new Date())
    const [time,setTime] = useState();

    const [comment,setComment] = useState('');

    const [showModal,setShowModal] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState();
    const [actionText,setActionText] = useState('');
  

    const calculateUser = () => {
        if(userData.user === undefined)
        {
            localStorage.setItem('openModalReserve','true');
            setShowModal(true);
        }
    }

    let type = '';
    if(props.type === 'merchant')
        type = 'Comercio';
    else if(props.type === 'product')
        type = 'Producto';
    else if(props.type === 'event')
        type = 'Evento';

    
    const handleOnClose = () => {
        localStorage.removeItem('openModalReserve');
        setShowModal(false)
    }

    const handleValue = (op) => {
        if(op === '+')
            setValue(value+1);
        else
        {
            if(value === 1)
                return;
            else
                setValue(value-1);
        }
    }


    const primaryAction = () => {
        if(success === true)
        {
            setOpen(false);
            props.handleClose();
        }
        else
        {
            setOpen(false);
        }
    }

    
    const handleSubmit = async () => {
        //console.log('DATE ===> ',moment(date).format("YYYY-MM-DD"));
        //console.log('TIME ===> ',moment(time).format('HH:mm:ss'));
        //return;
        setIsLoading(true);
        const _date = moment(date).format("YYYY-MM-DD");
        const _time = moment(time).format('HH:mm:ss');
        let response = await create(props.toUUID,_date,_time,value,comment);
        if(response.success === true)
        {
            setTitle('Operación Exitosa!');
            setSuccess(true);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);

            setComment('');
            setValue(1);
        }
        else
        {
            setTitle('Operación Fallida!');
            setSuccess(false);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);
        }
        setIsLoading(false);
    }

    return(
        <Dialog
            open={props.open}
            //onClose={props.handleClose}
            fullWidth
        >
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                <IconButton onClick={props.handleClose}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            </Box>
            <DialogTitle>Reservar</DialogTitle>
            
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{display:'flex',flexDirecction:'row',justifyContent:'center',width:'100%'}}>
                            <img style={{width:200,borderRadius:10}} src={props.image}/>
                        </Box>
                        <Typography textAlign={'center'} fontWeight={'bold'} variant="h5">{props.name}</Typography>
                    </Grid>

                    {
                        userData.user !== undefined
                        ?
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                                <IconButton color="primary" aria-label="minus" onClick={()=>handleValue('-')}>
                                    <FontAwesomeIcon icon={faMinusCircle}/>
                                </IconButton>
                                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',ml:1,mr:1}}>
                                    <Typography variant="h5" fontWeight={'bold'}>
                                        {value}
                                    </Typography>
                                    <Typography variant="caption">Personas</Typography>
                                </Box>
                                <IconButton color="primary" aria-label="plus" onClick={()=>handleValue('+')}>
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <LocalizationProvider dateAdapter={ AdapterMoment }>
                                    <MobileDatePicker
                                        label='Fecha'
                                        inputFormat="DD/MM/YYYY"
                                        minDate={new Date()}
                                        value={date}
                                        onChange={(newValue)=>setDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <LocalizationProvider dateAdapter={ AdapterMoment }>
                                    <MobileTimePicker
                                        
                                        label="Hora"
                                        value={time}
                                        onChange={(newValue) => setTime(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                        
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    id="comment"
                                    name="comment"
                                    label="Observaciones (opcional)"
                                    multiline
                                    rows={3}
                                    value={comment}
                                    onChange={(e)=>setComment(e.target.value)}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1" textAlign={'center'}>Para poder realizar una reservación debe iniciar sesión</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button variant="contained" fullWidth onClick={()=>calculateUser()}>
                                    <Typography fontWeight={'bold'}>Iniciar Sesión</Typography>
                                </Button>
                            </Grid>
                        </>
                    }
                    
                </Grid>
                
                
            </DialogContent>
            <DialogActions>
                {
                    userData.user !== undefined &&
                    <>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            color="cancel"
                            onClick={props.handleClose}
                        >
                            <Typography fontWeight={'bold'}>Cancelar</Typography>
                        </Button>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            onClick={()=>handleSubmit()}
                        >
                            <Typography fontWeight={'bold'}>Reservar</Typography>
                        </Button>
                    </>
                }
                
            </DialogActions>
            
            
            <SignInModal
                open={showModal}
                onClose={()=>handleOnClose()}
            />

            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}

                onClick={()=>primaryAction()}
                actionText={actionText}
            />

            <QUDProgress open={isLoading}/>
            
        </Dialog>
    )
}