import { ElementProps, Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Paper, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { stripePromisePK } from "../../apiRoutes/apiBase";

const stripePromise = loadStripe(stripePromisePK);

export default function PaymentStripe({amount})
{
    const appareance = {
        theme:'stripe',
        variables:{
            colorPrimary:'#212121'
        }
    };

    const options = {
        // passing the client secret obtained from the server
        //clientSecret: clientSecret?.client_secret,
        mode:'payment',
        currency:'usd',
        amount: amount*100,
        paymentMethodTypes:['card'],
        
    };

    const onChange = (e) => {
        console.log(e)
    }

    return(
        <Paper elevation={4}>
            <Typography mb={2}>
                <FontAwesomeIcon icon={faCircleExclamation} color="#ff9d00"/> 
                &nbsp;Los pagos con tarjeta de débito/crédito  estan sujetos a una comisión del 10% de la tarifa + 0.30$
            </Typography>
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between', mb:2}}>
                <Typography variant="h6" fontWeight={'bold'}>
                    Total a pagar
                </Typography>
                <Typography variant="h6" fontWeight={'bold'}>
                    {parseFloat(amount).toFixed(2)}
                </Typography>
            </Box>
            
            <Elements
                stripe={stripePromise}
                options={{
                    mode:'payment',
                    currency:'usd',
                    amount:parseInt(amount*1000),
                    paymentMethodTypes:['card']
                }}
            >
                <PaymentElement
                    onChange={(e)=>onChange(e)}
                />
            </Elements>
            
        </Paper>
    )
}

