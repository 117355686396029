import { useEffect, useReducer, useState } from "react";
import { Box, Button, Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";

import MapContainer from '../../../components/map';
import { add, getPlaceByUUID, to_add, update } from "../../../controllers/company/TourismController";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { Place } from "@mui/icons-material";


const initialState = {
    categories: [],
    cities: [],

    place: [],

    image_cover: null,
    image_background: null,

    category:'',    categoryError: false,
    name:'',        nameError: false,
    description:'', descriptionError: false,
    history:'',     historyError: false,

    city:'',        cityError: false,
    address:'',     addressError: false,
    
    coordinates: {lat:10.194865, lng: -64.690113},
    zoom : 16,

    isLoading : false,
    open : false,
    success : false, title : '', message : '',
    actionText:'',
    secondaryText:'',
    
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function TourismUpdate()
{

    const [rutaImgFondo,setRutaImgFondo] = useState('');
    const [imgFondo,setImgFondo] = useState([]);

    const [cover,setCover] = useState([]);
    const [coverError,setCoverError] = useState(true);
    const [background, setBackground] = useState([]);
    const [backgroundError,setBackgroundError] = useState(true);

    const [state,setState] = useReducer(reducer,initialState);

    const {
        place,
        image_cover, image_background,
        category, categoryError, name, nameError, description, descriptionError,
        history, historyError,

        city, cityError, address, addressError, coordinates,
        
        categories, cities,

        isLoading,
        open,
        success, title, message,
        actionText, secondaryText

    } = state;

    const { uuid } = useParams()

    const historyRoute = useHistory();

    const redirect = (route) => {
        historyRoute.push(route)
    }

    
    useEffect(()=>{
        handleGetToAdd();
        handleGetPlace();
    },[]);

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const handleGetToAdd = async () => {
        setState({field:'isLoading',value: true});
        let response = await to_add();
        if(response.success === true)
        {
            setState({field:'cities',value:response.data.cities});
            setState({field:'categories',value:response.data.categories});
        }
        setState({field:'isLoading',value: false});
    }

    const handleGetPlace = async () => {
        let response = await getPlaceByUUID(uuid);
        if(response.success === true)
        {
            setState({field:'category',value:response.data.category_id});
            setState({field:'name',value:response.data.name});
            setState({field:'description',value:response.data.description});
            setState({field:'history',value:response.data.history});
            setState({field:'city',value:response.data.city_id});
            setState({field:'address',value:response.data.address});
            setState({
                field:'coordinates',
                value:{lat:response.data.latitude, lng: response.data.longitude}
            });
            setState({field:'place',value:response.data})
        }
    }

    
    const updateCover = (incommingFiles) => {

        setCover(incommingFiles);
        if(incommingFiles.length === 0)
            setCoverError(true);
        else
        {
            setCoverError(false);
            setState({field:'image_cover',value:incommingFiles[0].file})
        }
            
    };
    const updateBackground = (incommingFiles) => {
        setBackground(incommingFiles);

        if(incommingFiles.length === 0)
            setBackgroundError(true);
        else
        {
            setBackgroundError(false);
            setState({field:'image_background',value:incommingFiles[0].file})
        }
    };

    const mapClicked = (mapProps, map, clickEvent) => {
        setState({
            field:'coordinates',
            value: {lat: parseFloat(clickEvent.latLng.lat()),lng:parseFloat(clickEvent.latLng.lng())}
        });
    }


    const formVerification = () => {
        let ban = 0

        if(category === '')
        {
            setState({field:'categoryError',value:true});
            ban = 1;
        }

        if(name === '')
        {
            setState({field:'nameError',value:true});
            ban = 1;
        }

        if(description === '')
        {
            setState({field:'descriptionError',value:true});
            ban = 1;
        }

        if(history === '')
        {
            setState({field:'historyError',value:true});
            ban = 1;
        }
        
        if(city === '')
        {
            setState({field:'cityError',value:true});
            ban = 1;
        }

        if(address === '')
        {
            setState({field:'addressError',value:true});
            ban = 1;
        }

        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;

        setState({field:'isLoading',value:true});
        
        let response = await update(
            uuid,
            category,name,description,history,
            city,address,coordinates.lat,coordinates.lng,
            image_cover,image_background
        );

        if(response.success === true)
        {
            setState({field:'title',value:'Operación Exitosa!'});
            setState({field:'message',value:response.message.message});
            setState({field:'success',value:true});
            setState({field:'actionText',value:'Sí'});
            setState({field:'secondaryText',value:'No'});
            setState({field:'open',value:true});
        }
        else
        {
            setState({field:'title',value:'Operación Fallida!'});
            setState({field:'message',value:response.message.message});
            setState({field:'success',value:false});
            setState({field:'actionText',value:'Aceptar'});
            setState({field:'open',value:true});
        }

        setState({field:'isLoading',value:false});
    }



    const primaryAction = () => {
        if(success === true)
        {
            clear();
        }
        else
        {
            setState({field:'open',value:false});
            window.scrollTo(0,0);
        }
    }
    const secondaryAction = () => {
        historyRoute.goBack()
    }

    const clear = () => {
        setCover([]);       setCoverError(true)
        setBackground([]);  setBackgroundError(true);

        setState({field:'category',value:''});
        setState({field:'name',value:''});
        setState({field:'description',value:''});
        setState({field:'history',value:''});
        setState({field:'city',value:''});
        setState({field:'address',value:''});
        setState({
            field:'coordinates',
            value: {lat:10.194865, lng: -64.690113}
        });

        setState({field:'open',value:false});
        window.scrollTo(0,0);
    }

    return(
        <Container>
            
            <CustomBreadcrumbs
                returnTo='/tourism'
                crumbs={[{name:'Sitios Turísticos',link:'/tourism'},{name:'Actualizar',link:'/tourism/update/'+uuid}]}
            />
            <br/>
            <SectionTitle title='Actualizar Sitio Turístico'/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight={'bold'}>Imagenes</Typography>
                </Grid>

                {/**Cover image */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Dropzone
                        maxFiles={1}
                        label="Foto de portada"
                        accept="image/*"
                        localization="Es-es"
                        onChange={updateCover} value={cover}
                        
                    >
                        
                        {
                            cover.length > 0 
                            ?
                                cover.map((file) => (
                                    <Box sx={{width:'100%', height:{xs:150,sm:200,md:200,lg:250,xl:250},p:2}}>
                                        <FileMosaic style={{width:'100%',height:'100%'}} {...file} preview  />
                                    </Box>
                                ))
                            :
                                <Box sx={{width:'100%', height:{xs:150,sm:200,md:200,lg:250,xl:250},p:2}}>
                                    <img style={{width:'100%',height:'100%'}} src={imagesURL+'tourism/places/'+place.image_cover_path} alt={'QUD'}/>
                                </Box>
                        }
                    </Dropzone>
                </Grid>
                
                {/**background image */}
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Dropzone
                        maxFiles={1}
                        label="Foto de Fondo"
                        accept="image/*"
                        localization="Es-es"
                        onChange={updateBackground} value={background}
                        
                    >
                        {
                            background.length > 0 
                            ?
                            background.map((file) => (
                                    <Box sx={{width:'100%', height:{xs:150,sm:200,md:200,lg:250,xl:250},p:2}}>
                                        <FileMosaic style={{width:'100%',height:'100%'}} {...file} preview  />
                                    </Box>
                                ))
                            :
                                <Box sx={{width:'100%', height:{xs:150,sm:200,md:200,lg:250,xl:250},p:2}}>
                                    <img style={{width:'100%',height:'100%'}} src={imagesURL+'tourism/places/'+place.image_background_path} alt={'QUD'}/>
                                </Box>
                        }
                    </Dropzone>
                </Grid>

                {/**General Data */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                    <Typography variant="h6" fontWeight={'bold'}>Datos generales</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        
                        {/**Category */}
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                select
                                label="Categoría"
                                name="category"
                                value={category}
                                onChange={ onChange }
                                onBlur={ () => (category === '' ? setState({field:'categoryError',value:true}) : setState({field:'categoryError',value:false}) ) }
                                error={categoryError}
                                helperText={categoryError && 'Debe seleccionar una categoría'}
                            >
                                <MenuItem key={'category0'} value={''}>
                                    Seleccione una categoría
                                </MenuItem>
                                {categories.map((item) => (
                                    <MenuItem key={item.uuid} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        {/**Name */}
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={name}
                                onChange={ onChange }
                                onBlur={ () => (name === '' ? setState({field:'nameError',value:true}) : setState({field:'nameError',value:false}) ) }
                                error={nameError}
                                helperText={nameError && 'Debe ingresar el nombre'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label="Descripción"
                                name="description"
                                value={description}
                                onChange={ onChange }
                                onBlur={ () => (description === '' ? setState({field:'descriptionError',value:true}) : setState({field:'descriptionError',value:false}) ) }
                                error={descriptionError}
                                helperText={descriptionError && 'Debe ingresar la descripción'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={6}
                                label="Historia"
                                name="history"
                                value={history}
                                onChange={ onChange }
                                //onBlur={ () => (history === '' ? setState({field:'historyError',value:true}) : setState({field:'historyError',value:false}) ) }
                                //error={historyError}
                                //helperText={historyError && 'Debe ingresar una breve historia'}
                            />
                        </Grid>
                    </Grid>
                </Grid>


                {/**Ubicacion */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                    <Typography variant="h6" fontWeight={'bold'}>Ubicación</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Ciudad"
                                select
                                name="city"
                                value={city}
                                onChange={ onChange }
                                onBlur={ () => (city === '' ? setState({field:'cityError',value:true}) : setState({field:'cityError',value:false}) ) }
                                error={cityError}
                                helperText={cityError && 'Debe seleccionar una ciudad'}
                            >
                                {cities.map((item) => (
                                    <MenuItem key={item.idCiudad} value={item.idCiudad}>
                                        {item.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={address}
                                onChange={ onChange }
                                onBlur={ () => (address === '' ? setState({field:'addressError',value:true}) : setState({field:'addressError',value:false}) ) }
                                error={addressError}
                                helperText={addressError && 'Debe ingresar la dirección'}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                disabled
                                fullWidth
                                label="Latitud"
                                value={coordinates.lat.toString()}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                disabled
                                fullWidth
                                label="Longitud"
                                value={coordinates.lng.toString()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MapContainer
                                center={coordinates}
                                coordenadas={coordinates}
                                mapClicked={ (mapProps, map, clickEvent) => mapClicked(mapProps, map, clickEvent)  }
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/**Action buttons */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="cancel"
                                onClick={()=>historyRoute.push('/tourism')}
                            >
                                <Typography>Cancelar</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={()=>handleSubmit()}
                            >
                                <Typography fontWeight={'bold'}>Actualizar Sitio Turístico</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}

                secondaryAction={()=>secondaryAction()}
                secondaryText={secondaryText}

                onClick={()=>primaryAction()}
                actionText={actionText}
            />
        </Container>
    )
}