import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';
import { GetAll } from '../../../controllers/company/SubCategoriesController';
import QUDProgress from '../../../components/progress';

//export default class TablaProductos extends Component
export default function CategoriesDataTable()
{
    const {userData,setUserData} = useContext(UserContext);
    const [subCategories,setSubCategories] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect( () => {
        getSubCategories()
    },[]);

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:180,height:100}} src={imagesURL + 'subcategorias/' + subCategories[dataIndex].imagen}/>
                        </div>
                    );
                },
                filter:false
            }
        },
        {
            name : 'nombre',
            label : 'Nombre',
        },
        {
            name : 'descripcion',
            label : 'Descripción'
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='Activo' color='success' />
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategories[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => ChangeStatus(subCategories[dataIndex].idSubCategoria)  }  
                            >
                                <IconButton>
                                {
                                    subCategories[dataIndex].estatus === 1
                                    ? <FontAwesomeIcon icon={faTrash}/>
                                    : <FontAwesomeIcon icon={faPlay}/>
                                }
                                </IconButton>
                            </Link>
                            
                            <Link 
                                to='/categories/update' 
                                onClick={ ()=> localStorage.setItem('categoria',JSON.stringify(subCategories[dataIndex])) }
                                replace
                            >
                                <IconButton>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </IconButton>
                            </Link>
                        </div>
                    );
                },

                print : false
            }
        }
    ];

    const ChangeStatus = async (id) => {
        
        const params = [
            {name:'idSubCategoria',value:id},
        ];

        let response = await POST(CompanyRoutes.SubCategories.ChangeStatus,params);

        if(response.success === true)
            getSubCategories();
    }


    const getSubCategories = async () => {
        setIsLoading(true);
        let response = await GetAll();
        if(response.success === true)
        {
            setSubCategories(response.data.subCategorias);
        }
        setIsLoading(false);
    }


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <>
            <MUIDataTable
                title={"Listado de Categorías"}
                data={subCategories}
                columns={columns}
                options={options}
            />
            <QUDProgress open={isLoading}/>
        </>
    )
}