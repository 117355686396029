import { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import SectionTitle from "../../../../components/sectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { GetAllBankAccounts } from "../../../../controllers/company/AccountNumbersController";
import BankAccountDataTable from "./dataTable";

export default function MyBankAccounts(props)
{
    const [bankAccounts,setBankAccounts] = useState([]);
    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetAllBankAccounts();
    },[]);

    const handleGetAllBankAccounts = async () => {
        let response = await GetAllBankAccounts();
        if(response.success === true)
        {
            setBankAccounts(response.data.accounts);
        }
    }
    return(
        <Container maxWidth='lg'>
            <CustomBreadcrumbs crumbs={[{name:'Mis Cuentas Bancarias',link:'/finances/banks'}]}/>
            <br/>
            <SectionTitle title='Mis Cuentas Bancarias'/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                        variant='contained'
                        endIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                        onClick={()=>redirect('/finances/banks/add')}
                    >
                        <Typography>Agregar</Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {
                    bankAccounts.length !== 0 &&
                    <BankAccountDataTable bankAccounts={bankAccounts}/>
                }
                </Grid>
            </Grid>
        </Container>
    )
}