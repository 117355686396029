import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';
import qz from 'qz-tray';
import { POST, POST_JSON } from './ConsumeApiController';
import WebsiteRoutes from '../apiRoutes/WebsiteRoutes';

export async function printWithRawBT(merchant,event,tickets) {
    //return console.log('imprimiendo tickets ===> ',tickets);
    let encoder = new ReceiptPrinterEncoder({
        columns: 32
    });

    
    // Configura el encoder para tu impresora
    encoder.initialize();
    encoder.align('center');
    encoder.bold(true);
    encoder.text(merchant);

    encoder.newline(2);
    encoder.text(event);
    encoder.newline();
    encoder.newline();
    // Añade cada sección y su código QR al recibo
    for (const ticket of tickets) {
        encoder.text(ticket.seccion)
        encoder.newline()
        encoder.qrcode(ticket.ticket_uuid)
        encoder.newline()
    }

    encoder.text('WWW.QUD.LIFE');
    encoder.newline();
    // Genera el contenido en formato binario
    const encodedContent = encoder.encode();
  
    // Convierte el contenido a base64
    const base64Content = btoa(String.fromCharCode(...new Uint8Array(encodedContent)));
  
    // Crea la URL de impresión para rawBT
    const rawBTUrl = `rawbt:base64,${base64Content}`;
  
    // Llama a rawBT para enviar el contenido
    window.location.href = rawBTUrl;
}


export async function printWithWebBluetooth(merchant, event, tickets) {
    let encoder = new ReceiptPrinterEncoder({ columns: 32 });
    encoder.initialize();
    encoder.align('center');
    encoder.bold(true);
    encoder.text(merchant);
    encoder.newline(2);
    encoder.text(event);
    encoder.newline();
    encoder.newline();

    for (const ticket of tickets) {
        encoder.text(ticket.seccion);
        encoder.newline();
        encoder.qrcode(ticket.ticket_uuid);
        encoder.newline();
    }

    encoder.text('WWW.QUD.LIFE');
    encoder.newline();

    const encodedContent = encoder.encode();
    const buffer = new Uint8Array(encodedContent).buffer;

    try {
        // Solicita conexión con el dispositivo Bluetooth compatible con Serial Port Profile
        const device = await navigator.bluetooth.requestDevice({
            acceptAllDevices: true, // Reemplaza 'IMPRESORA' con el nombre de tu dispositivo si es necesario
            optionalServices: ['00001101-0000-1000-8000-00805f9b34fb'] // UUID estándar para servicio serial en Bluetooth
        });

        // Conexión al servidor GATT
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('00001101-0000-1000-8000-00805f9b34fb');
        const characteristic = await service.getCharacteristic('00001101-0000-1000-8000-00805f9b34fb');

        // Envía el contenido codificado a la impresora
        await characteristic.writeValue(buffer);
        console.log('Impresión enviada con éxito');
    } catch (error) {
        console.error('Error al imprimir:', error);
    }
}


export async function printWithQZTrayFunction(merchant, event, tickets,printer) {
    console.log('Tickets para impresion ===> ',tickets);
    const encoder = new ReceiptPrinterEncoder({ columns: 32 });
    encoder.initialize();
    encoder.align('center');
    encoder.bold(true);
    encoder.text(merchant);
    encoder.newline(2);
    encoder.text(event);
    encoder.newline();
    encoder.newline();

    for (const ticket of tickets) {
        encoder.text(ticket.seccion);
        encoder.newline();
        encoder.qrcode(ticket.ticket_uuid);
        encoder.newline();
    }

    encoder.text('WWW.QUD.LIFE');
    encoder.newline();
    encoder.newline();
    encoder.newline();

    const encodedContent = encoder.encode();
    const base64Data = btoa(String.fromCharCode(...encodedContent));

    try {
        await qz.websocket.connect();  // Conecta QZ Tray
        const config = qz.configs.create(printer);
        await qz.print(config, [{ type: 'raw', format: 'base64', data: base64Data }]);
        console.log("Impresión realizada con éxito");
    } catch (error) {
        console.error("Error de impresión:", error);
    } finally {
        qz.websocket.disconnect();
    }
}



export async function UploadExcel(excel_file){
    let response = await POST_JSON(WebsiteRoutes.Eventos.Tickets.batch,{excel_file});
    return response;
}


