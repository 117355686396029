import { useState } from "react";
import { Box, ClickAwayListener, Grid, IconButton, Tooltip, Typography, tooltipClasses } from "@mui/material";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
        minWidth:200,
        maxWidth: 200,
        backgroundColor:'#FFF'
    },
  });


export default function CustomTooltip(props)
{
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return(
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div >
                <CustomWidthTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    onClick={handleTooltipOpen}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <>
                        <Grid container spacing={3} display={'flex'} flexDirection={'row'}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h6" textAlign={'center'} color={'primary.main'}>
                                    Compartir
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={6} sm={6} md={6} lg={6} l={6} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                <TwitterShareButton url={props.url}>
                                    <XIcon round/>
                                </TwitterShareButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} l={6} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                <TelegramShareButton url={props.url}>
                                    <TelegramIcon round/>
                                </TelegramShareButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} l={6} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                <FacebookShareButton url={props.url}>
                                    <FacebookIcon round/>
                                </FacebookShareButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} l={6} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                <WhatsappShareButton url={props.url}>
                                    <WhatsappIcon round/>
                                </WhatsappShareButton>
                            </Grid>
                        </Grid>
                        </>
                    }

                >
                    <IconButton size="large">
                        <FontAwesomeIcon icon={faShareNodes}/>
                    </IconButton>
                </CustomWidthTooltip>
            </div>
        </ClickAwayListener>
    )
}