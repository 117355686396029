import WebsiteRoutes from "../apiRoutes/WebsiteRoutes";
import { GET, POST } from "./ConsumeApiController";


export async function GetData(uuid)
{
    let response = await GET(WebsiteRoutes.Merchants.GetMerchantProfile+uuid);
    return response;
}


/**Function to get all merchants categories */
export async function GetCategories()
{
    let response = await GET(WebsiteRoutes.Merchants.GetCategories);
    return response;
}

export async function GetToSearchMerchants()
{
    let response = await GET(WebsiteRoutes.Merchants.GetToSearchMerchants);
    return response;
}


export async function GetMerchants(idCategoria)
{
    let response = await GET(WebsiteRoutes.Merchants.GetMerchants+idCategoria);
    return response;
}

export async function GetMerchantsByCategoryAndLocation(category,location)
{
    const params = [
        {name:'category',value:category},
        {name:'location',value:location}
    ];

    const response = await POST(WebsiteRoutes.Merchants.GetMerchantsByCategoryAndLocation,params);
    return response;
}

export async function GetMerchant(uuid)
{
    let response = await GET(WebsiteRoutes.Merchants.GetMerchant+uuid);
    return response;
}


export async function GetMerchantCategories(merchant_uuid)
{
    let response = await GET(WebsiteRoutes.Merchants.GetMerchantCategories+merchant_uuid);
    return response;
}


export async function GetMerchantProducts(merchant_uuid,category_uuid)
{
    console.log('LA RUTA ES ---->>>> ',WebsiteRoutes.Merchants.GetMerchantProducts+merchant_uuid+'/'+category_uuid);
    let response = await GET(WebsiteRoutes.Merchants.GetMerchantProducts+merchant_uuid+'/'+category_uuid);
    return response;
}

export async function GetPaymentMethods(uuid)
{
    const response = await GET(WebsiteRoutes.Merchants.GetPaymentMethods+uuid);
    return response;
}