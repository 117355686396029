import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, Container, Divider, Grid, IconButton, Paper, Typography, useScrollTrigger } from '@mui/material';

import { motion } from "framer-motion"

//imagenes
import home1 from '../../assets/images/website/home1.png';

import ic1 from '../../assets/images/website/ic1.png'
import googlePlay from '../../assets/images/stores/GooglePlay.png';
import appleStore from '../../assets/images/stores/AppleStore.png';
import { useHistory } from 'react-router-dom';


import { useTheme } from '@mui/material';
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faAppStore, faApple, faGooglePlay, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import opcion1 from '../../assets/images/opcion1.jpg';
import opcion2 from '../../assets/images/opcion2.jpg';

import te1 from '../../assets/images/website/TE1.png';
import te3 from '../../assets/images/website/events.webp';
import publicidad1 from '../../assets/images/publicidad/publicidad1.png';
import publicidad2 from '../../assets/images/publicidad/publicidad2.webp';
import p2 from '../../assets/images/publicidad/p2.png';
import op1 from '../../assets/images/website/ic1.png';
import img_events from '../../assets/images/website/events.jpg';
import img_marketplace from '../../assets/images/website/marketplace.jpg'
import img_coupons from '../../assets/images/website/happy.jpg';
import img_loyalties from '../../assets/images/website/loyalty.jpg';

import eventos_provicional from '../../assets/images/website/eventos_provicional.png';
//import ImageButtonQUD from '../../components/ImageButton';
import ImageButtonQUD from '../../components/ImageButton';
import { faCartShopping, faCheck, faCheckCircle, faCheckSquare, faCircleCheck, faEllipsis, faGifts, faHandshakeAngle, faHeartPulse, faHotel, faLandmark, faListCheck, faMagnifyingGlass, faMapLocation, faSpa, faStar, faStore, faTicket, faTruckFast, faUtensils } from '@fortawesome/free-solid-svg-icons';
import ContactUs from './contactUs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./homeStyles.css";
import Categories from './Categories';
import Test from './test';
import { TypeAnimation } from 'react-type-animation';
import { imagesURL } from '../../apiRoutes/apiBase';

import pub1 from '../../assets/images/pub1.png';
import pub2 from '../../assets/images/pub2.png';
import t6 from '../../assets/images/t6.png';
import testc1 from '../../assets/images/website/marketplace.jpg';
import testc2 from '../../assets/images/website/happy.jpg';
import ContactUs2 from './contactUs2';

export default function ForPeople(props)
{
    const history = useHistory();
    const theme = useTheme();


    const redirect = (route) => {
        history.push(route);
    }


    const activities = [
        {
            icon: faLandmark,
            title:'Conoce a fondo a las empresas',
            description:'Descubre información detallada sobre sus perfiles, valores y trayectoria.'
        },
        {
            icon: faTruckFast,
            title:'Conecta con los proveedores ideales',
            description:'Interactúa directamente con las empresas y establece relaciones comerciales sólidas.'
        },
        {
            icon: faMagnifyingGlass,
            title:'Encuentra productos y servicios únicos',
            description:'Accede a una amplia variedad de ofertas de la mano de empresas confiables.'
        },
        {
            icon: faCartShopping,
            title:'Realiza compras de forma segura y eficiente',
            description:'Disfruta de una experiencia de compra intuitiva y protegida.'
        },
        {
            icon:faListCheck,
            title:'Gestiona tus pedidos y reservas',
            description:'Controla y administra tus transacciones en un solo lugar.'
        },
        {
            icon:faGifts,
            title:'Aprovecha al máximo las ofertas y promociones',
            description:'Accede a descuentos exclusivos y beneficios personalizados.'
        },
    ];



    return(
        <Box sx={{overflowX:'hidden',mt:14}}>
            {/**Carrusel publicitario */}
            <Container maxWidth='lg'>
                <Box
                    sx={{
                        bgcolor:'primary.main',
                        color:'primary.contrastText',
                        borderRadius:5,
                        p:5
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                                    <Typography variant='h3' fontWeight={'bold'} gutterBottom>
                                        ¡Empieza hoy mismo a explorar <br/> las posibilidades que te ofrece <br/> nuestra plataforma!
                                    </Typography>
                                    <Typography variant='h6' color={'#CCC'} gutterBottom>
                                        Regístrate de forma gratuita y descubre <br/> un mundo de oportunidades para tu vida.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                                    <Typography variant='h6' fontSize={19} fontWeight={'bold'} gutterBottom>
                                        ¡Empieza hoy mismo a explorar las posibilidades que te ofrece nuestra plataforma!
                                    </Typography>
                                    <Typography variant='body1' color={'#CCC'} gutterBottom mt={2}>
                                        Regístrate de forma gratuita y descubre un mundo de oportunidades para tu vida.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Button
                                        color='link'
                                        variant='contained'
                                        fullWidth
                                        onClick={()=>redirect('/auth/userType')}
                                    >
                                        <Typography variant='h6' textAlign={'center'}>Registrate ahora</Typography>
                                    </Button>
                                    
                                </Grid>
                                <Grid item lg={6}></Grid>
                                {/**Para descargar la app *}
                                <Grid item lg={6}>
                                    <Typography textAlign={'center'} variant='h6'>Get it on</Typography>
                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                        <IconButton
                                            color='secondary'
                                            href='https://play.google.com/store/apps/details?id=com.technologicalsharks.snowierewards&hl=es_US' target='_blank'
                                        >
                                            <FontAwesomeIcon size='2x' icon={faGooglePlay}/>
                                        </IconButton>
                                        <IconButton
                                            color='secondary'
                                            sx={{ml:1}}
                                            href='https://apps.apple.com/us/app/snowie-rewards/id1610015974?l=es' target='_blank'
                                        >
                                            <FontAwesomeIcon size='2x' icon={faAppStore}/>
                                        </IconButton>
                                    </Box>
                                </Grid>*/}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box sx={{width:'100%'}}>
                                <img style={{width:'100%',borderRadius:10}} src={t6} alt='QUD'/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            
            <Container maxWidth='lg' sx={{mt:5,mb:10}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
                        <Typography variant='h5'  color='#5A5A5A' textAlign={'center'}>
                            Te invitamos a explorar un universo de oportunidades que te permitirá disfrutar al máximo de tu vida personal y profesional.
                        </Typography>
                    </Grid>

                    {
                        activities.map(item=>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} height={'auto'} display={'flex'} flexDirection={'column'} alignItems={'center'} mt={10}>
                                <Paper elevation={6} sx={{height:'100%'}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',mt:-10}}>
                                                <Box sx={{bgcolor:'primary.main',p:1,borderRadius:5}}>
                                                    <Avatar
                                                        variant="rounded"
                                                        alt="QUD"
                                                        src={item.src}
                                                        sx={{ width: 100, height: 100, bgcolor:'primary.main' }}
                                                    >
                                                        <FontAwesomeIcon size='2x' icon={item.icon}/>
                                                    </Avatar>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant="h5" fontWeight={'bold'} textAlign={'center'}>
                                                {item.title}
                                            </Typography>
                                            <Typography pl={3} pr={3} variant='h6' component={'p'} color={'#5A5A5A'} gutterBottom mt={5} textAlign={'center'}>
                                                {item.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    }
                    <Grid item xs={11} sm={11} md={9} lg={9} xl={9} mt={5}>
                        <Typography variant='h5'  color='#5A5A5A' textAlign={'center'}>
                            Nuestra plataforma te brinda un espacio donde podrás encontrar todo lo que necesitas 
                            para mejorar tu calidad de vida. Con una interfaz intuitiva y una amplia gama de 
                            funcionalidades, te acompañamos en cada paso del camino hacia la satisfacción 
                            de tus necesidades.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {/**CONTACT US */}
            <ContactUs2
                title={'¿Tienes alguna pregunta o necesitas ayuda?'}
                description={`No dudes en contactarnos. Estaremos encantados de ayudarte.`}
            />
        </Box>
    )
}