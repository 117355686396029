import { useEffect, useState } from "react";
import { getTicketList } from "../../../../controllers/customer/EventosController";
import { Box, Container, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import SectionTitle from "../../../../components/sectionTitle";
import TicketsDataTable from "./TicketsDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import QUDProgress from "../../../../components/progress";

export default function TicketsList()
{
    const [isLoading,setIsLoading] = useState(false);
    const [tickets,setTickets] = useState([]);

    useEffect(()=>{
        handleGetTickets();
    },[]);

    const handleGetTickets = async () => {
        setIsLoading(true);
        let response = await getTicketList();
        if(response.success === true)
        {
            setTickets(response.data.tickets);
        }
        setIsLoading(false);
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Pedidos',link:'/orders'}]}/>
            <br/>
            <SectionTitle title={`Mis Boletos`}/>
            <Grid container spacing={3}>
                <Grid xs={12}>
                    {
                        tickets.length !== 0 &&
                        <TicketsDataTable orders={tickets}/>
                    }
                    {
                        (isLoading === false && tickets.length === 0) &&
                        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:5,mb:10}}>
                            <FontAwesomeIcon size="3x" icon={faTriangleExclamation}/>
                            <Typography variant="h4" textAlign={'center'}>No se encontraron resultados</Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
            <QUDProgress open={isLoading}/>
        </Container>
    )
}