import { Button } from '@mui/material';
import React, { useState } from 'react';


import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';
import { printWithQZTrayFunction } from '../../controllers/PrinterController';
import PrintWithQZTray from '../../components/QZPrint';

function PrintQR() {

	
	
    async function printWithRawBT(sections) {
        printWithQZTrayFunction('Prueba','Evento de Prueba',sections)
    }

	// Función para cargar una imagen y convertirla a Base64
	function loadImageToBase64(imgPath) {
		return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = imgPath;
		img.crossOrigin = 'anonymous'; // Evita problemas de CORS si la imagen es externa
		img.onload = () => {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0);
			resolve(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));
		};
		img.onerror = (err) => reject(err);
		});
	}

    
    // Ejemplo de uso
    const datosAImprimir = [
        { seccion: "General", ticket_uuid: "mN5oP6qR7sT8u9vW0" },
		//{ seccion: "General", qrCode: "https://example.com/a" },
        /*{ seccion: "Sección B", qrCode: "https://example.com/b" },
        { seccion: "Sección C", qrCode: "https://example.com/c" }*/
    ];


  return (
    <div style={{marginTop:200,marginLeft:200}}>
        <Button onClick={()=>printWithRawBT(datosAImprimir)}>Imprimir</Button>
        <br/><br/><br/>
        <PrintWithQZTray
            merchant={'Prueba'}
            event={'Evento de Prueba'}
            tickets={datosAImprimir}
        />        
    </div>
  );
}

export default PrintQR;