import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';


import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WalletIcon from '@mui/icons-material/Wallet';
import { Box, Divider, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import AuthContext from '../../navigation/context/authContext';





export default function FinanceListItems(props){

    const { signOut } = useContext(AuthContext);
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <div>
            {
                props.open &&
                <ListItem>
                    <Typography variant='body1' color={'#878787'} >Mis Finanzas</Typography>
                </ListItem>
            }
            
            <ListItem button onClick={()=>redireccionar('/finances/banks')}>
                <Tooltip title='Mis Cuentas' placement='right'>
                    <ListItemIcon>
                        <AccountBalanceIcon sx={{color:'primary.contrastText'}}/>
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Mis Cuentas" />
            </ListItem>
            
            <ListItem button onClick={()=>redireccionar('/wallet')}>
                <ListItemIcon>
                    <WalletIcon sx={{color:'primary.contrastText'}}/>
                </ListItemIcon>
                <ListItemText primary="Billetera" />
            </ListItem>
        </div>
    )    
}
    