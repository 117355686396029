import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Box, Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';

//export default class TablaProductos extends Component
export default function TicketsDataTable(props)
{

    const [tickets,setTickets] = useState(props.tickets);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        setTickets(props.orders)
    },[props])

    const columns = [
        {
            name : 'ticket_id',
            label : <Typography># Ticket</Typography>,
            options:{
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:true
            }
        },
        {
            name : 'evento',
            label : <Typography>Evento</Typography>,
            
            options:{
                filter:false
            }
        },
        {
            name : 'seccion',
            label : <Typography>Seccion</Typography>,
            options:{
                filter:true,
                //filterType:'dropdown',
                //display:false,
                searchable:false
            }
        },
        {
            name : 'estatus',
            label : <Typography>Estatus</Typography>,
            options:{
                filter:true,
                //filterType:'dropdown',
                //display:false,
                searchable:false
            }
        },
        {
            name : 'ticket',
            label : <Typography>Ver ticket</Typography>,
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row'}}>
                            <Button href={tickets[dataIndex].ticket} target='_blank'>
                                Ver Boleto
                            </Button>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];




    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={<Typography>Listado de Boletos</Typography>}
            data={tickets}
            columns={columns}
            options={options}
        />
    )
}