import { Container, Grid, Typography } from "@mui/material";

export default function DeleteUser()
{
    return(
        <Container maxWidth='lg'>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h4" fontWeight='bold'>Eliminar Cuenta de Usuario</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1">
                        Para eliminar su cuenta de usuario escribanos un correo a <span style={{fontWeight:'bold'}}>SUPPORT@QUD.LIFE. </span> 
                        <br/><br/>
                        Una vez recibida la solicitud nos encargaremos de eliminar su cuenta de usuario y todos los datos asociados 
                        a la misma.
                        <br/><br/>
                        Tenga en cuenta que esta acción es irreversible y que se perderán todos sus datos.                        
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}