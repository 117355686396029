import { useEffect, useState } from 'react';
import { Container, Box, Paper, Grid, Typography, Avatar, Chip, IconButton } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { GetMerchantsByCategoryAndLocation } from '../../../controllers/MerchantsController';

import MapContainer from '../../../components/map';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import QUDProgress from '../../../components/progress';
import SectionTitle from '../../../components/sectionTitle';

export default function Search()
{
    const [merchants,setMerchants] = useState([]);
    const [city,setCity] = useState([]);

    const [isLoading,setIsLoading] = useState(false);

    const { category, location } = useParams();
    const history = useHistory();
    const locationPath = useLocation();
    
    useEffect(()=>{
        handleGetMerchants();
    },[locationPath]);

    const redirect = (route) => {
        history.push(route);
    }

    const handleGetMerchants = async () => {
        setIsLoading(true);
        let response = await GetMerchantsByCategoryAndLocation(category,location);
        if(response.success === true)
        {
            setMerchants(response.data.merchants);
            setCity(response.data.city);
        }
        setIsLoading(false);
    }


    return(
        <Container maxWidth="lg" sx={{pt:{xs:10,md:15,lg:15}, mb:5}}>
            <QUDProgress open={isLoading}/>
            <IconButton onClick={()=>{redirect('/')}}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </IconButton>
            <SectionTitle title={category}/>
            
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid container spacing={3}>
                        {
                            merchants.length !== 0
                            ?
                            merchants.map(item =>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Paper
                                        variant='outlined'
                                        sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                                        onClick={()=>redirect('/merchants/'+item.uuid+'/profile')}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                                                {/*<img src={imagesURL+'empresas/'+item.imagen}/>*/}
                                                <Box sx={{width:'100%'}}>
                                                    <img style={{width:'100%',borderRadius:5}} src={imagesURL+'empresas/'+item.image2} alt='qud'/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={9} xl={9}>
                                                <Typography variant='h4' fontWeight={'bold'}>{item.merchant}</Typography>
                                                
                                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',mb:2}}>
                                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                                        <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                                        <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                                        <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                                        <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                                        <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                                        {/*<FontAwesomeIcon icon={faStar} color='#999999' />*/}
                                                    </Box>
                                                    {/**
                                                    <Typography variant="h6" fontWeight={'bold'} color={'primary.main'} ml={2}>
                                                        5.0
                                                    </Typography>
                                                    <Typography variant="subtitle2" color={'primary.main'} ml={1} mt={0.5}>
                                                        (100 reseñas)
                                                    </Typography> */}
                                                </Box>
                                                <Chip label="Sub-Categoría" size="small" />
                                                <Chip label={item.city} size="small" sx={{ml:1}} />
                                                <Typography variant='body1' fontWeight={'bold'} color={'#454545'} mt={2}>{item.description}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )

                            :
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography textAlign={'center'}>No se econtraron resultados</Typography>
                            </Grid>
                        }
                        
                    </Grid>
                </Grid>

                {/*
                    city !== null &&
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <MapContainer
                            center={{lat:city.latitud, lng:city.longitud}}
                            //coordenadas={{lat:user.latitud, lng:user.longitud}}
                        />
                    </Grid>
                */}
            </Grid>
        </Container>
    )
}


/**
 * Para el futuro
 */
/**
 * <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <Paper
            variant='outlined'
            sx={{':hover':{cursor:'pointer',boxShadow:4},p:1}}
            onClick={()=>redirect('/merchants/'+item.uuid+'/profile')}
        >
            <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    {/*<img src={imagesURL+'empresas/'+item.imagen}/>*}
                    <Box sx={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <img style={{width:'100%',borderRadius:5}} src={imagesURL+'empresas/'+item.image2} alt='qud'/>
                    </Box>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography variant='h6' fontWeight={'bold'}>
                        {cortarPalabra(item.merchant,23)}
                    </Typography>
                    
                    <Box >
                        <Box sx={{display:'flex',flexDirection:'row'}}>
                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                            {/*<FontAwesomeIcon icon={faStar} color='#999999' />*}
                        </Box>
                        <Typography variant="caption" fontWeight={'bold'} color={'primary.main'}>
                            5.0 (100 reseñas)
                        </Typography>
                    </Box>
                    <Typography variant='body2'>{item.city}</Typography>
                    
                </Grid>
            </Grid>
        </Paper>
    </Grid>
 */