import { Box, Button, Container, Divider, Grid, IconButton, Link, Paper, Typography } from "@mui/material";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

//import sharkboy from '../../assets/images/sharkboy.png'
import { motion } from "framer-motion"

import logo from '../../../assets/images/logos/qud4.png';
import googlePlay from '../../../assets/images/stores/GooglePlay.png';
import appleStore from '../../../assets/images/stores/AppleStore.png';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { domain } from "../../../apiRoutes/apiBase";
import { useTranslation } from "react-i18next";

export default function Footer()
{
    const {t, i18n} = useTranslation();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    return(
        <Box sx={{minHeight:'50vh',position:'relative',bgcolor:'primary.main',pt:5}}>
            <Divider sx={{bgcolor:'#1C1C1C',mt:2,mb:2}}/>
            <Container maxWidth='xl' sx={{pt:5,pb:5,color:'primary.contrastText'}}>
                <Grid container spacing={6} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img style={{width:100}} src={logo} alt="QUD"/>
                        </Box>
                        {/**
                        <Box sx={{mt:3}}>
                            <Typography variant="h6" textAlign={'center'} color={'primary.contrastText'} fontWeight={'bold'}>
                                QUD es una plataforma TODO EN UNO que ofrece una solución completa para eventos, compras, cupones y recompensas. Todo lo que necesitas en una sola app. Descárgala ahora y simplifica tu vida
                            </Typography>
                        </Box> */}
                        <Box sx={{mt:3,display:'flex',justifyContent:'center'}}>
                            <Button color="secondary" LinkComponent={'a'} target="_blank" href="https://www.instagram.com/qud.life/">
                                <FontAwesomeIcon size="3x" icon={faInstagram}/>
                            </Button>
                            <Button sx={{ml:1}} color="secondary" LinkComponent={'a'} target="_blank" href="https://www.instagram.com/qud.life/">
                                <FontAwesomeIcon size="3x" icon={faFacebook}/>
                            </Button>
                            <Button sx={{ml:1}} color="secondary" LinkComponent={'a'} target="_blank" href="https://www.instagram.com/qud.life/">
                                <FontAwesomeIcon size="3x" icon={faTwitter}/>
                            </Button>
                        </Box>
                    </Grid>


                    {/**SOLUCIONES */}
                    <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                        <Typography variant="h5" fontWeight={'bold'}>{t('helpers.solutions')}</Typography>
                        <Box>
                            <Button
                                color="secondary"
                                variant="text"
                                sx={{textTransform:'none'}}
                                onClick={()=>redirect('forbusiness')}
                            >
                                <Typography variant="h6" fontWeight={'bold'}>{t('helpers.ticketing')}</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="secondary"
                                variant="text"
                                sx={{textTransform:'none'}}
                                onClick={()=>redirect('forbusiness')}
                            >
                                <Typography variant="h6" fontWeight={'bold'}>{t('helpers.merchants')}</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="secondary"
                                variant="text"
                                sx={{textTransform:'none'}}
                                onClick={()=>redirect('forpeople')}
                            >
                                <Typography variant="h6" fontWeight={'bold'}>{t('helpers.people')}</Typography>
                            </Button>
                        </Box>
                        
                        
                    </Grid>


                    {/**Servicios 
                    <Grid item lg={2} xl={2}>
                        <Typography variant="h5" fontWeight={'bold'}>Soluciones</Typography>

                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Eventos</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Marketplace</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Cupones</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Puntos de Lealtad</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Landing page</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Menú Digital</Typography>
                            </Button>
                        </Box>
                    </Grid>*/}

                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Typography variant="h5" fontWeight={'bold'}>Legal</Typography>

                        <Box>
                            <Button
                                color="secondary"
                                variant="text"
                                sx={{textTransform:'none'}}
                                target="_blank"
                                href={domain+'privacy'}
                            >
                                <Typography variant="h6" fontWeight={'bold'}>{t('helpers.privacy')}</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="secondary"
                                variant="text"
                                sx={{textTransform:'none'}}
                                target="_blank"
                                href={domain+'termsandconditions'}
                            >
                                <Typography variant="h6" fontWeight={'bold'}>{t('helpers.terms')}</Typography>
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Typography variant="h5" fontWeight={'bold'}>{t('helpers.app')}</Typography>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>Google Play</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button color="secondary" variant="text" sx={{textTransform:'none'}}>
                                <Typography variant="h6" fontWeight={'bold'}>App Store</Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{bgcolor:'#1C1C1C',mt:2,mb:2}}/>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='caption'>Powered by</Typography>
                        <Typography variant='body1'>Technological Sharks</Typography>
                    </Grid>
                </Grid>
            </Container>
            <Box
                sx={{
                    width:'100%',height:'100%',
                    display:'flex',
                    //justifyContent:'center',
                    alignItems:'center',
                    bgcolor:'primary.main',
                    backgroundPosition:'start',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover 100%',
                    opacity:1,
                    position:'absolute',
                    bottom:0, left:0,
                    borderTopRightRadius:10,
                    borderBottomRightRadius:10,zIndex:-10
                }}
            ></Box>
        </Box>
    )
}