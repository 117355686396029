import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Box, Button, Chip, Container, Dialog, Divider, Grid, IconButton, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import GoogleMaps from "../../components/maps/GoogleMaps";
import { Marker, Polyline } from "google-maps-react";

import PropTypes from 'prop-types';

import m1 from '../../assets/images/allies/artemis_marker1.png';
//import m2 from '../../assets/images/markers/m2.png';
import m2 from '../../assets/images/allies/artemis_marker.png';
import artemis_logo from '../../assets/images/allies/artemis_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { GetPaymentMethods } from "../../controllers/MerchantsController";
import PaymentStripe from "../../components/stripe/Payment";
import Copyright from "../../components/copyRight";
import UserContext from "../../navigation/context/userContext";
import SignInModal from "../auth/signInModal";
import { orderTaxi } from "../../controllers/customer/PedidosController";
import Payments from "../../components/Payments";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ResponseComponent from "../../components/responseComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import QUDProgress from "../../components/progress";
import { stripePromisePK } from "../../apiRoutes/apiBase";


const stripePromise = loadStripe(stripePromisePK);


/**Tabs de formas de pago */
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ pt: 3 }}>
                {children}
            </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}



//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    initialCenter: {lat:10.189640,lng:-64.688094},
    origin : {lat:10.189640,lng:-64.688094},
    destination : {lat:10.189640,lng:-64.688094},
    route:[],
    rate:0.7,
    distance:0,
    duration:0,
    price:0,
    startAddress:'',
    endAddress:'',
    
    step:0,
    paymentMethods: [],
    paymentMethod:null, //para almacenar los datos de la forma de pago seleccionada
    bankAccounts: [],
    bankAccountsAux:[],
    bankAccountSelected:null,
}
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  
  

export default function Artemis()
{
    const theme = useTheme();
    const {userData} = useContext(UserContext);
    const [showModal,setShowModal] = useState(true);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        initialCenter, origin, destination, route, rate, distance, duration, startAddress, endAddress,
        step,paymentMethod, bankAccounts, price, //cash
    } = state;

    const [isLoading,setIsLoading] = useState(false);
    const [complete,setComplete] = useState(false);
    const [show,setShow] = useState(false);
    const [successA,setSuccessA] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [actionText,setActionText] = useState('Finalizar');
    const [secondaryText,setSecondaryText] = useState('SI');

    const [goBack,setGoBack] = useState(false);
    const [goToProfile,setGoToProfile] = useState(false);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        //calculateUser();
        //getCurrentLocation();
        handleGetPaymentsMethods();
    },[]);

    useEffect(()=>{
       calculateUser()
    },[userData])

    //verificar si el usuario esta logeado
    const calculateUser = () => {
        if(userData.user === undefined)
        {
            setShowModal(true);
        }
        else if(userData.user.telefono === null)
        {
            setSuccessA(false);
            setTitle('Número de Teléfono');
            setMessage('Para poder solicitar un servicio de taxi debe agregar su número de teléfono');
            setGoToProfile(true);
            setShow(true);
        }
        else
        handleOnClose();
    }

    const handleOnClose = () => {
        setShowModal(false);
        if(localStorage.getItem('UserAccount') === null)
        {
            setSuccessA(false);
            setTitle('Inicio de sesión obligatorio');
            setMessage('Para poder solicitar un servicio de taxi debe registrarse y/o iniciar sesión');
            setGoBack(true);
            setShow(true);
        }
    }


    /**To get user location */
    var options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0, }; 
    function success(pos){ 
        var crd = pos.coords;
        setState({field:'initialCenter',value:{lat:crd.latitude, lng:crd.longitude}});
        setState({field:'origin',value:{lat:crd.latitude, lng:crd.longitude}});
        setState({field:'destination',value:{lat:crd.latitude, lng:crd.longitude}});
    }
    
    function errors(err){
        console.warn(`ERROR(${err.code}): ${err.message}`); 
    }

    const getCurrentLocation = () => {
        if(navigator.geolocation)
        { 
            navigator.permissions.query({ name: "geolocation" })
            .then((result)=>{ 
                console.log('GEOLOCALIZACION ===> ',result); 
                if(result.state === 'granted')
                {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                }
                else if(result.state === 'prompt')
                {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                }
                else if(result.state === 'denied')
                {

                }
                else
                {
                    console.log("Geolocation is not supported by this browser.");
                }
            }); 
        }
        else
        {
            console.log("Geolocation is not supported by this browser."); 
        } 
    }

    const onMarkerDragEnd = async(t,map,coord, tipo) => {
        
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        let _origin = origin;
        let _destination = destination;

        if(tipo === 'origen')
        {
            _origin = {lat:lat,lng:lng};
            setState({field:'origin',value:{lat:lat,lng:lng}});
        }
            
        else
        {
            _destination = {lat:lat,lng:lng};
            setState({field:'destination',value:{lat:lat,lng:lng}});
        }

        const directionsService = new t.google.maps.DirectionsService();
        const directionsRenderer = new t.google.maps.DirectionsRenderer();
        

        //directionsDisplay.setMap(map);

        let request = {
            origin: _origin,
            waypoints: [
                {
                    location: _origin,
                    stopover: true,
                },
                {
                    location: _destination,
                    stopover: true,
                },
            ],
            destination: _destination,
            travelMode: "DRIVING",
            optimizeWaypoints: true
        };

        
        await directionsService.route(request, async function (result, status) {
            if (status === "OK") {
                
                setState({field:'route',value:result.routes[0].overview_path});

                let legs = result.routes[0].legs;
                let _distance = 0;
                let _duration = 0;
                let _startAddress = '';
                let _endAddress = '';
                
                await Promise.all(
                    legs.map((leg,index)=>{
                        _distance += leg.distance.value/1000;
                        _duration += leg.duration.value/60;
                        if(index === 0)
                            _startAddress = leg.start_address;
                        if(index === 2)
                            _endAddress = leg.end_address;

                        return null;
                    })
                );
                setState({field:'distance',value:_distance});
                setState({field:'duration',value:_duration});
                setState({field:'startAddress',value:_startAddress});
                setState({field:'endAddress',value:_endAddress});
                
            }
        });
    };

    const calculatePrice = () => {
        let _price = distance * rate;
        if(_price < 3)
            _price = 3;

            setState({field:'price',value:_price});
        
        
    }

    useEffect(()=>{
        calculatePrice();
    },[distance]);

    const handleNext = () => {
        setState({field:'step',value:step+1});
    }

    const handlePrevious = () => {
        setState({field:'step',value:step-1});
    }


    /**
     * Gestion de pagos
     */
    /**
     * Para obtener las formas de pago de la empresa
     * @param uuid de la empresa
     * @returns formas de pago y cuentas bancarias
     */
    const handleGetPaymentsMethods = async () => {
        let response = await GetPaymentMethods('artemis');
        if(response.success === true)
        {
            
            setState({field:'paymentMethods',value:response.data.payment_methods});
            //setState({field:'bankAccounts',value:_bankAccounts /*response.data.cuentas*/});
        }
    }

    const handlePaymentMethodSelected = async () => {

        let _aux = await Promise.all(bankAccounts.filter(item=>{
            return item.forma_pago === paymentMethod.nombre
        }));
        
        setState({field:'bankAccountsAux',value:_aux});
        setState({field:'bankAccountSelected',value:paymentMethod?.cuentas[0]});
        console.log('CUENTA SELECCIONADA ===> ',paymentMethod?.cuentas[0])
    }

    useEffect(()=>{
        handlePaymentMethodSelected();
    },[paymentMethod]);






    const handleSubmit = async (paymentSuccess,payments=null) => {
        
        if(paymentSuccess === true)
        {
            setIsLoading(true);
            console.log('Solicitando el servicios')
            const detalles = [
                {
                    nombre:'Servicio de taxi',
                    cantidad:1,
                    precio:price,
                    descripcion:'Servicio de taxi.'
                }
            ];
    
            console.log('Validando forma de pago...')
            let pagos=[];
            if(payments !== null)
            {
                pagos = [
                    {
                        //uuid: payments.uuid,
                        //paymentMethod: payments.payment_method,
                        //accountName: payments.description,
                        amount: parseFloat(payments.monto),
                        reference: payments.referencia,
                        id_numero_cuenta: payments.id_numero_cuenta
                    }
                ];
            }
    
            
            console.log('Solicitando...')
            let response = await orderTaxi('artemis',startAddress,origin.lat,origin.lng,price,1,endAddress,destination.lat,destination.lng,detalles,pagos)
            if(response.success === true)
            {
                setTitle('Operación Exitosa');
                setMessage(response.message);
                setSuccessA(true);
                setShow(true);
            }
            console.log('RESPONDE ===> ',response);
            setIsLoading(false);
        }
        else
            setIsLoading(false);
    }

    const handleNextButton = async () => {
        if(step === 0)
        {
            window.scrollTo(0,0);
            handleNext();
        }
        else
            handleSubmit();
    }

    const handleClostResponse = () => {
        if(goBack === true)
        {
            redirect('/');
        }
        else if(goToProfile === true)
            redirect('/profile');
        else
        {
            if(successA === true)
                redirect('/');
            else
                setShow(false);
        }
    }

    return(
        <Box sx={{width:'100%',minHeight:'100vh',mt:2}}>
            {
                step === 0 &&
                <>
                    <Box>
                        <GoogleMaps 
                            height={'60vh'}
                            initialCenter={initialCenter}
                        >
                            {/**How to use */}
                            <Box sx={{position:'absolute',top:3,left:3}}>
                                <Paper elevation={4}>
                                    <Typography fontWeight={'bold'}>Arrastra los iconos para indicar</Typography>
                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                        <Typography fontWeight={'bold'}>
                                            <img style={{width:25}} src={m1} alt={'QUD'}/>
                                            Origien
                                        </Typography>
                                        <Typography ml={3} fontWeight={'bold'}>
                                            <img style={{width:25}} src={m2} alt={'QUD'}/>
                                            Destino
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Box>

                            {/**Origin marker */}
                            <Marker
                                position={origin} 
                                title='Origen'
                                //color='#000'
                                style={{color:'#000'}}
                                draggable={true}
                                onDragend={(t, map, coord) => onMarkerDragEnd(t,map,coord,'origen')}
                                //image={{uri: m1}}
                                icon={{
                                    url:m1
                                }}
                            />

                            <Marker
                                title='Destino'
                                position={destination} 
                                draggable={true}
                                
                                onDragend={(t, map, coord) => onMarkerDragEnd(t,map,coord,'destino')}
                                icon={{
                                    url:m2
                                }}
                            />

                            <Polyline
                                path={route}
                            />

                        </GoogleMaps>
                    </Box>
                    <Container maxWidth={'xs'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography fontWeight={'bold'} variant="h6">Distancia: <span style={{color:theme.palette.cancelDark.main}}>{distance} km</span> </Typography>
                                <Typography fontWeight={'bold'} variant="h6">Duración: <span style={{color:theme.palette.cancelDark.main}}>{parseInt(duration)} min aprox.</span> </Typography>
                                <Typography fontWeight={'bold'} variant="h6">Tarifa: <span style={{color:theme.palette.cancelDark.main}}>{parseFloat(price).toFixed(2)} usd</span> </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button variant="contained" onClick={()=>handleNext()}>
                                    <Typography fontWeight={'bold'}>Realizar pago</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            }
            
            {
                step === 1 &&
                <>
                    <Container maxWidth={'xs'}>
                        <Grid container spacing={3}>
                            {/**Go back arrow */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <IconButton onClick={()=>handlePrevious()}>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                </IconButton>
                            </Grid>

                            {/**Logo de Artemis */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                                <Box sx={{width:180,alignItems:'center'}}>
                                    <img style={{width:'100%'}} src={artemis_logo} alt='artemis taxi'/>
                                </Box>
                            </Grid>

                            {/**Pequeno resumen */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper elevation={4}>
                                    <Typography fontWeight={'bold'} variant="h6">Distancia: <span style={{color:theme.palette.cancelDark.main}}>{distance} km</span> </Typography>
                                    <Typography fontWeight={'bold'} variant="h6">Duración: <span style={{color:theme.palette.cancelDark.main}}>{parseInt(duration)} min aprox.</span> </Typography>
                                    <Typography fontWeight={'bold'} variant="h6">Tarifa: <span style={{color:theme.palette.cancelDark.main}}>{parseFloat(price).toFixed(2)} usd</span> </Typography>
                                </Paper>
                            </Grid>

                            {/**Formas de pago */}
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    mode:'payment',
                                    currency:'usd',
                                    amount:parseInt((price*100)+(price*100*0.1)+0.3),
                                    paymentMethodTypes:['card']
                                }}
                            >
                                <Payments
                                    merchantUUID={'artemis'}
                                    amount={parseFloat(parseFloat(price).toFixed(2))}
                                    handleSubmit={handleSubmit}
                                    action='Solicitar Servicio'
                                />
                            </Elements>

                            {
                                step === 0 &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'} mb={2}>
                                    <Button variant="contained" onClick={()=>handleNextButton()}>
                                        <Typography fontWeight={'bold'}>Solicitar servicio</Typography>
                                    </Button>
                                </Grid>
                            }
                            
                            <Copyright/>
                        </Grid>
                    </Container>
                </>
            }

            <SignInModal
                open={showModal}
                onClose={()=>handleOnClose()}
            />
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={show}
                success={successA}
                title={title}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>handleClostResponse(goBack)}
            />
        </Box>
    )
}