import { useEffect, useState } from "react";
import { Box, Button, Container, MenuItem, Paper, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { getOrders } from "../../../controllers/company/OrdersController";
import OrdersDataTable from "./OrdersdataTable";
import QUDProgress from "../../../components/progress";

export default function Orders()
{
    const [isLoading,setIsLoading] = useState(false);
    const [orders,setOrders] = useState([]);

    useEffect(()=>{
        handleGetOrders();
    },[]);

    const handleGetOrders = async () => {
        setIsLoading(true);
        let response = await getOrders();
        if(response.success === true)
        {
            setOrders(response.data.orders);
        }
        setIsLoading(false);
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Pedidos',link:'/orders'}]}/>
            <br/>
            <SectionTitle title='Pedidos'/>
            <Grid container spacing={3}>
                {/*<Grid xs={12}>
                    <Paper elevation={4}>
                        <Typography variant="h6" component='h6' fontWeight={'bold'}>Filtros</Typography>
                        <Grid container spacing={3}>
                            <Grid xs={12} sm={12} md={12} lg={5} xl={5}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='Estatus'
                                    select
                                >
                                    <MenuItem>Por Procesar</MenuItem>
                                    <MenuItem>Pagado</MenuItem>
                                    <MenuItem>Por Entregar</MenuItem>
                                    <MenuItem>Entregado</MenuItem>
                                    <MenuItem>Cancelado</MenuItem>
                                    <MenuItem>Rechazado</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid xs={12} sm={12} md={9} lg={5} xl={5} display={'flex'} flexDirection={'row'}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Desde"
                                        //value={value}
                                        //onChange={handleChange}
                                     renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                    <DatePicker
                                        label="Hasta"
                                        //value={value}
                                        //onChange={handleChange}
                                     renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid xs={12} sm={12} md={3} lg={2} xl={2}>
                                <Button
                                    fullWidth
                                    sx={{height:'99%'}} 
                                    variant="contained" endIcon={<FontAwesomeIcon icon={faSearch}/>} >
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>*/}

                <Grid xs={12}>
                    {
                        orders.length !== 0 &&
                        <OrdersDataTable orders={orders}/>
                    }
                    {
                        (isLoading === false && orders.length === 0) &&
                        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:5,mb:10}}>
                            <FontAwesomeIcon size="3x" icon={faTriangleExclamation}/>
                            <Typography variant="h4" textAlign={'center'}>No se encontraron resultados</Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
            <QUDProgress open={isLoading}/>
        </Container>
    )
}