import { useReducer, useState } from "react";
import { Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { pay } from "../../../controllers/MorocotasController";
import { GetAppUser } from "../../../controllers/AppsUsersController";
import { LoadingButton } from "@mui/lab";
import ModalDialog from "../../../components/modalDialog";
import { useHistory } from "react-router-dom";



//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    receiver: '',       errorReceiver: false,
    amount:'',          errorAmount: false,
    observations: '',   errorObservations: false,
    pin:'',             errorPin: false,
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function Pay()
{
    const [app,setApp] = useState([]);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        receiver, errorReceiver, amount, errorAmount, observations, errorObservations,
        pin, errorPin,
        isLoading, open, title, message, success
    } = state;

    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const formVerification = () => {
        var ban = 0;

        if(receiver === '')
        {
            setState({field:'errorReceiver',value:true});
            ban = 1;
        }

        if(amount === '')
        {
            setState({field:'errorAmount',value:true});
            ban = 1;
        }

        if(observations === '')
        {
            setState({field:'errorObservations',value:true});
            ban = 1;
        }
            

        if(pin === '')
        {
            setState({field:'errorPin',value:true});
            ban = 1;
        }

        return ban;
    }


    const handleSubmit = async () => {
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});
            let responseMorocotas = await GetAppUser(1);
            let response = await pay(receiver,amount,observations,pin,responseMorocotas.data.token);
            if(response.success === true)
            {
                setState({field:'title',value:'Operacion Exitosa!'});
                setState({field:'message',value:response.data});
                setState({field:'open',value:true});
                setState({field:'success',value:true});
            }
            else
            {
                setState({field:'title',value:'Operacion Fallida!'});
                setState({field:'message',value:response.message});
                setState({field:'open',value:true});
                setState({field:'success',value:false});
            }

            setState({field:'isLoading',value:false});
        }

    }

    const handleAction = async () => {
        if(success === true)
            redirect('/wallet');
        else
        {
            handleClean();
        }
    }

    const handleClean = async () => {
        /*setState({field:'receiver',value:''});
        setState({field:'amount',value:''});
        setState({field:'observations',value:''});
        setState({field:'pin',value:''});*/
        setState({field:'open',value:false});
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/wallet' crumbs={[{name:'Billetera',link:'/wallet'},{name:'Pagar',link:'/wallet/pay'}]}/>
            <br/>
            <SectionTitle title='Pagar' />
            <br/>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                    <Paper>
                        <Grid container spacing={3}>
                            {/**Receiver */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Receptor'
                                    id='receiver'
                                    name='receiver'
                                    value={receiver}
                                    onChange={onChange}
                                    onBlur={ () => (receiver === '' ? setState({field:'errorReceiver',value:true}) : setState({field:'errorReceiver',value:false}) ) }
                                    error = {errorReceiver}
                                    helperText={errorReceiver && 'Ingrese el receptor'}
                                />
                            </Grid>

                            {/**Amount */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Monto'
                                    id='amount'
                                    name='amount'
                                    value={amount}
                                    onChange={onChange}
                                    onBlur={ () => (amount === '' ? setState({field:'errorAmount',value:true}) : setState({field:'errorAmount',value:false}) ) }
                                    error = {errorAmount}
                                    helperText={errorAmount && 'Ingrese el monto'}
                                />
                            </Grid>

                            {/**Observations */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Observacion'
                                    id='observations'
                                    name='observations'
                                    value={observations}
                                    onChange={onChange}
                                    onBlur={ () => (observations === '' ? setState({field:'errorObservations',value:true}) : setState({field:'errorObservations',value:false}) ) }
                                    error = {errorObservations}
                                    helperText={errorObservations && 'Ingrese la observacion'}
                                />
                            </Grid>

                            {/**Pin */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Pin'
                                    id='pin'
                                    name='pin'
                                    value={pin}
                                    onChange={onChange}
                                    onBlur={ () => (pin === '' ? setState({field:'errorPin',value:true}) : setState({field:'errorPin',value:false}) ) }
                                    error = {errorPin}
                                    helperText={errorPin && 'Ingrese el PIN'}
                                />
                            </Grid>

                            {/**BOTON */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LoadingButton
                                    fullWidth
                                    loading={isLoading}
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>handleSubmit()}
                                >
                                    <Typography>Pagar</Typography>
                                </LoadingButton>
                                
                                <Button
                                    fullWidth
                                    disabled={isLoading}
                                    variant='contained'
                                    color='error'
                                    sx={{mt:2}}
                                >
                                    <Typography>Volver</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <ModalDialog
                open={open}
                title={title}
                msg={message}
                handleAction={()=> handleAction()}
                handleClose={()=> handleAction()}
                action='Aceptar'
                close='Cerrar'
            />
        </Container>
    )
}