import { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography, TextField, MenuItem, Button, Checkbox, Link } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { completeProfile, toCompleteProfile } from '../../controllers/customer/ProfileController';
import QUDProgress from '../../components/progress';
import ResponseComponent from '../../components/responseComponent';





export default function CompleteProfile()
{
    const [user,setUser] = useState([]);
    const { uuid } = useParams();

    const [name,setName] = useState('');
    const [nameError,setNameEror] = useState(false);
    const [lastName,setLastName] = useState('');
    const [lastNameError,setLastNameEror] = useState(false);
    const [phone,setPhone] = useState('');
    const [phoneError,setPhoneError] = useState(false);

    const [password,setPassword] = useState('');
    const [passwordError,setPasswordError] = useState(false);
    const [passwordConfirm,setPasswordConfirm] = useState('');
    const [passwordConfirmError,setPasswordConfirmError] = useState(false);

    const [agree,setAgree] = useState(false);
    const [agreeError,setAgreeError] = useState(false);


    const [isLoading,setIsLoading] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [actionText,setActionText] = useState('Finalizar');
    const [open,setOpen] = useState(false);

    const [success2,setSuccess2] = useState(false);
    const [open2,setOpen2] = useState(false);

    const history = useHistory();

    useEffect(()=>{
        handleGetToCompleteProfile();
    },[]);

    const redirect = (route) => {
        history.push(route);
    }

    const handleGetToCompleteProfile = async () => {
        setIsLoading(true);
        let response = await toCompleteProfile(uuid);
        if(response.success === true)
        {
            console.log(response.data)
            setUser(response.data);
            /*setSuccess(true);
            setTitle('¡Operación Exitosa!');
            setMessage(response.message);*/
        }
        else
        {
            setSuccess(false);
            setTitle('¡Operación Fallida!');
            setMessage(response);
            setOpen(true);
        }
        setIsLoading(false);
    }

    const formVerification = () => {
        var ban = 0;

        if(agree === false)
        {
            setAgreeError(true);
            ban = 1;
        }

        if(name === '')
        {   
            setNameEror(true);
            ban = 1;
        }

        if(lastName === '')
        {   
            setLastNameEror(true)
            ban = 1;
        }

        if(phone === '')
        {   
            setPhoneError(true)
            ban = 1;
        }
        
        if(password === '')
        {
            setPasswordError(true);
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setPasswordConfirmError(true);
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setPasswordConfirmError(true);
            ban = 1;
        }
        
        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;
        setIsLoading(true);
        let response = await completeProfile(uuid,name,lastName,phone,password);
        if(response.success === true)
        {
            setSuccess2(true);
            setTitle('¡Operación Exitosa!');
            setMessage(response.message);
            setOpen2(true);
        }
        else
        {
            setSuccess2(false);
            setTitle('¡Operación Fallida!');
            setMessage(response);
            setOpen2(true);
        }
        setIsLoading(false);
    }

    const primaryAction = () => {
        setOpen(false);
        redirect('/auth/signIn')
    }

    const primaryAction2 = () => {
        if(success2 === true)
            redirect('/auth/signIn')
        else
            setOpen2(false);
    }

    return(
        <Container sx={{mt:10}}>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5' textAlign={'center'} fontWeight={'bold'}>Completar Perfil</Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant='h6'>Datos Personales</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id='name'
                                name='name'
                                label='Nombre'
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                onBlur={ () => (name === '' ? setNameEror(true) : setNameEror(false) ) }
                                error = {nameError}
                                helperText={nameError && 'Ingrese su nombre'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id='lastName'
                                name='lastName'
                                label='Apellido'
                                value={lastName}
                                onChange={(e)=>setLastName(e.target.value)}
                                onBlur={ () => (lastName === '' ? setLastNameEror(true) : setLastNameEror(false) ) }
                                error = {lastNameError}
                                helperText={lastNameError && 'Ingrese su apellido'}
                            />
                        </Grid>
                        {/*<Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                select
                                id='docType'
                                name='docType'
                                label='Documento'
                            >
                                <MenuItem key='select' value={'V'}>V - Venezolano</MenuItem>
                                <MenuItem key='select' value={'E'}>E - Extranjero</MenuItem>
                                <MenuItem key='select' value={'J'}>J - Jurídico</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id='document'
                                name='document'
                                label='Número de Documento'
                            />
                        </Grid>*/}

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id='phone'
                                name='phone'
                                label='Teléfono'
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                                onBlur={ () => (phone === '' ? setPhoneError(true) : setPhoneError(false) ) }
                                error = {phoneError}
                                helperText={phoneError && 'Ingrese su telefono'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                            <Typography variant='h6'>Datos de Acceso</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                disabled
                                fullWidth
                                variant='outlined'
                                id='email'
                                name='email'
                                //label='Correo Electrónico'
                                value={user.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id='password'
                                name='password'
                                type='password'
                                label='Contraseña'
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                                onBlur={ () => (password === '' ? setPasswordError(true) : setPasswordError(false) ) }
                                error = {passwordError}
                                helperText={passwordError && 'Ingrese la contraseña'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                id='passwordConfirm'
                                name='passwordConfirm'
                                type='password'
                                label='Confirmar contraseña'
                                value={passwordConfirm}
                                onChange={(e)=>setPasswordConfirm(e.target.value)}
                                onBlur={ () => (passwordConfirm !== password ? setPasswordConfirmError(true) : setPasswordConfirmError(false) ) }
                                error = {passwordConfirmError}
                                helperText={passwordConfirmError && 'La confirmación de contraseña no coincide'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30, marginLeft:-8}}>
                                <Checkbox
                                        checked={agree} 
                                        onChange={() => setAgree(!agree)}
                                        name="agree"
                                        id='agree' 
                                        onBlur={ () => (agree === false ? setAgreeError(true) : setAgreeError(false) ) }
                                    />
                                <Typography>
                                    Declaro que he leído y acepto las&nbsp;
                                    <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/privacy' target='_blank'>
                                        Políticas de Privacidad
                                    </Link> 
                                    &nbsp;así como los&nbsp;
                                    <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/termsandconditions' target='_blank'>
                                        Términos y Condiciones
                                    </Link> 
                                    &nbsp;de uso de QUD. 
                                </Typography>
                            </div>
                            {
                                agreeError === true
                                ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar las Políticas de Privacidad y los Términos y Condiciones de uso de QUD</Typography>
                                :null
                            }
                            <br/>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button disabled={isLoading} variant='contained' onClick={()=>handleSubmit()}>
                                <Typography fontWeight={'bold'}>Completar Perfil</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}

                onClick={()=>primaryAction()}
                actionText={actionText}
            />

            <ResponseComponent
                show={open2}
                success={success2}
                title={title}
                message={message}

                onClick={()=>primaryAction2()}
                actionText={actionText}
            />
        </Container>
    )
}