import { createRef, useContext, useEffect, useReducer, useState } from "react";
import { Box, Button, Divider, Grid, IconButton, MenuItem, Paper, Table, TableCell, TableContainer, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { IssueTicketsAtBoxOffice, SectionList } from "../../../../../controllers/company/EventsController";
import { GetEventByID } from "../../../../../controllers/customer/EventosController";
import PhoneInput from "react-phone-input-2";

import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSellcast } from "@fortawesome/free-brands-svg-icons";
import { faChair, faCheckCircle, faEye, faEyeSlash, faFileInvoiceDollar, faPerson, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { GetAllBankAccounts } from "../../../../../controllers/company/AccountNumbersController";
import { LoadingButton } from "@mui/lab";
import { TableBody, TableHead } from "mui-datatables";
import UserContext from "../../../../../navigation/context/userContext";
import QUDProgress from "../../../../../components/progress";
import { isNumeric } from "../../../../../controllers/AuxController";
import ReactInputVerificationCode from "react-input-verification-code";
import { printWithRawBT } from "../../../../../controllers/PrinterController";
import PrintWithQZTray from "../../../../../components/QZPrint";


const initialState = {

    paymentMethods: [],
    bankAccounts: [],       bankAccountSelected:null,
    bankAccountsAux:[],
    paymentMethod: null,    errorPaymentMethod: false,
    amount:'',              errorAmount: false,
    reference:'',           errorReference: false,
    totalPaid: 0,           errorTotalPaid: false,
    showPayments: false,

    ticketsIssued: []
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function IssueTicketWithSeats(props)
{
    const {userData} = useContext(UserContext);
    /**customer data */
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [lastName,setLastName] = useState('');
    const [lastNameError,setLastNameError] = useState(false)
    const [documentType,setDocumentType] = useState('V');
    const [document,setDocument] = useState('');
    const [email,setEmail] = useState('');
    const [emailError,setEmailError] = useState(false);
    const [documentError,setDocumentError] = useState(false);
    const [prefix,setPrefix] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');
    const [phoneNumberError,setPhoneNumberError] = useState(false);

    const [activeStep,setActiveStep] = useState(0);
    const [isLoading,setIsLoading] = useState(false);
    const [event,setEvent] = useState(props.event);
    const [country,setCountry] = useState(props.country);
    const [exchangeRate,setExchangeRate] = useState(props.exchangeRate);
    
    const [tickets,setTickets] = useState([]);
    const [sections,setSections] = useState([]);
    const [categories,setCategories] = useState([]);

    const [total,setTotal] = useState(100);
    const [subtotal,setSubTotal] = useState(0);
    const [comision,setComision] = useState(0);
    const [feeSeats,setFeeSeats] = useState(0);
    const [fee,setFee] = useState(0);

    const [payments,setPayments] = useState([]);

    const [selectedSeats,setSelectedSeats] = useState([]);
    const [holdToken,setHoldToken] = useState('');

    const [isComplimentary,setIsComplimentary] = useState(false);
    const [complimentaryCode,setComplimentaryCode] = useState('');
    const [complimentaryCodeError,setComplimentaryCodeError] = useState(false);

    const theme = useTheme();

    const [state,setState] = useReducer(reducer,initialState);

    const {
        paymentMethods, bankAccounts, bankAccountsAux, bankAccountSelected,
        paymentMethod, errorPaymentMethod, amount, errorAmount, reference, errorReference, totalPaid, errorTotalPaid,
        showPayments,

        ticketsIssued,
        result, message, title,
        
    } = state;


    useEffect(()=>{
        handleGetSections();
        handleGetPaymentsMethods();
    },[]);

    const onChange = (e) => {
        if(e.target.name === "documento" || e.target.name === "telefono")
            setState({field : e.target.name, value : e.target.value.replace(/[^\d,]/g, '')});
        else
        setState({field : e.target.name, value : e.target.value});
    }

    const handleGetSections = async () => {
        setIsLoading(true);
        let response = await GetEventByID(event.id_evento)
        if(response.success === true)
        {
            //setEvent(response.data.event);
            setTickets(response.data.tickets);
            handleSettingTickets(response.data.tickets);
        }
        else
        {}

        setIsLoading(false);
    }

    const handleSettingTickets = async (_tickets) => {
        let _sections = [];
        let _categories = [];
        if(_tickets !== undefined)
        {
            await Promise.all(_tickets.map(item =>{
                _sections.push({
                    evento_seccion_id: item.evento_seccion_id,
                    uuid:item.uuid,
                    nombre: item.nombre, //nombre de la categoria
                    descripcion: item.descripcion,
                    precio: parseFloat(parseFloat(item.precio).toFixed(2)),
                    cantidad: 0,
                    seleccion_max: item.seleccion_max,
                    evento_id: item.evento_id,
                    lista_asientos:[],
                    capacidad: item.capacidad,
                    vendidos: item.vendidos,
                    disponibles: parseInt(item.capacidad) - parseInt(item.vendidos)
                });

                _categories.push({
                    category: item.nombre,
                    price: item.precio
                })
            }));

            setSections(_sections);
            setCategories(_categories);
        }
    }

    /*--------------------------------------------------------
    /*          Manejo de la seleccion de asientos           |
    /*------------------------------------------------------*/

    const handleSelectingSeat = async (selectedObject,_operator) => {
        setSections((prevSections)=>{
            const updatedSections = [...prevSections];
            const indexToUpdate = updatedSections.findIndex((item)=> item.nombre === selectedObject.category.label);
            
            if(indexToUpdate !== -1)
                {
                    //let _cantidad = updatedSections[indexToUpdate].cantidad;
    
                    if(_operator === '+')
                    {
                        const seats = updatedSections[indexToUpdate].lista_asientos;
    
                        if(selectedObject.seats)
                        {
                            selectedObject.seats.map(item=>{
                                const [label,fil,col] = item.label.split('-');
                                const seatToFind = {fil: fil,col: col};
                                const seatFound = seats.find(seat=>seat.fil === seatToFind.fil && seat.col === seatToFind.col)
                        
                                if(!seatFound)
                                    updatedSections[indexToUpdate].lista_asientos.push({
                                        fil:fil,col:col
                                    });
                                return{
                                    label,fil,col
                                }
                            });
                            
                            //console.log('selectedObject.seats ===> ',_listaAsientos)
                        }
                        else
                        {
                            const seatToFind = {fil: selectedObject.labels.parent,col: selectedObject.labels.own}
                        
                            const seatFound = seats.find(seat=>seat.fil === seatToFind.fil && seat.col === seatToFind.col)
                            
                            if(!seatFound)
                                updatedSections[indexToUpdate].lista_asientos.push({
                                    fil:selectedObject.labels.parent,col:selectedObject.labels.own
                                });
                        }
    
                        updatedSections[indexToUpdate].cantidad = updatedSections[indexToUpdate].lista_asientos.length;
                    }
                    else
                    {
                        if(selectedObject.seats)
                        {
                            selectedObject.seats.map(item=>{
                                const [label,fil,col] = item.label.split('-');
                                //const seatFound = seats.find(seat=>seat.fil === seatToFind.fil && seat.col === seatToFind.col)
                                let _asientos = updatedSections[indexToUpdate].lista_asientos.filter(item=>!(item.fil === fil && item.col === col))
                                updatedSections[indexToUpdate].lista_asientos = _asientos;
                                
                                return{
                                    label,fil,col
                                }
                            });
                        }
                        else
                        {
                            //se filtran los asientos para eliminar el objeto anterior
                            let _asientos = updatedSections[indexToUpdate].lista_asientos.filter(item=>!(item.fil === selectedObject.labels.parent && item.col === selectedObject.labels.own))
                            updatedSections[indexToUpdate].lista_asientos = _asientos;
                        }
                            
                        updatedSections[indexToUpdate].cantidad = updatedSections[indexToUpdate].lista_asientos.length;   
                    }
    
                    /*updatedSections[indexToUpdate].cantidad = _cantidad;
                    updatedSections[indexToUpdate].lista_asientos.push({
                        fil:selectedObject.labels.parent,col:selectedObject.labels.own
                    });*/
                }
            console.log('LA SELECCION ES ===> ',updatedSections);
            return updatedSections;
        })
    }



    /**---------------------------------------------------------------
     *      GESTION DEL "CARRITO DE COMPRAS"                        |
     *--------------------------------------------------------------*/
    const handleShoppingCart = async (_uuid,_operator,_ticket) => {

        setSections((prevSections)=>{
            const updatedSections = [...prevSections];
            const indexToUpdate = updatedSections.findIndex((item)=> item.uuid === _uuid);

            if(indexToUpdate !== -1){
                let _cantidad = updatedSections[indexToUpdate].cantidad;
                
                if(_operator === '+')
                {
                    if(_cantidad < _ticket.disponibles && _cantidad < _ticket.seleccion_max)
                    _cantidad = _cantidad + 1;
                }
                else
                {
                    if(_cantidad > 0)
                    {
                        _cantidad = _cantidad - 1;
                    }
                }

                updatedSections[indexToUpdate].cantidad = _cantidad;
                
                let _lista_asientos = [];
                for(let i=0;i<_cantidad;i++)
                {
                    _lista_asientos.push({fil:updatedSections[indexToUpdate].nombre});
                }
                updatedSections[indexToUpdate].lista_asientos = _lista_asientos;
            }
            return updatedSections;
        })

    }




    const handleTotals = async () => {
        let _subtotal = 0;
        let _comision = 0;
        let _total = 0;
        let _comision_asientos = 0;

        await Promise.all(sections.map(item=>{
            if(event.event_key !== null)
            {
                _comision_asientos += item.cantidad*1; //0.3
            }
            return _subtotal += (item.cantidad * item.precio);
        }));

        _comision = parseFloat(_subtotal*(parseFloat(event.comision)/100));
        _total = parseFloat(_subtotal) + (_subtotal*(parseFloat(event.comision)/100));
        _total = _subtotal + _comision + _comision_asientos;
        _total = parseFloat(parseFloat(_total).toFixed(2));

        console.log('TOTAL ASIENTOS ===> ',_total);

        setSubTotal(_subtotal);
        setComision(_comision+_comision_asientos)
        setFee(_comision)
        setFeeSeats(_comision_asientos);
        setTotal(_total);
    }

    useEffect(()=>{
        handleTotals();
    },[sections]);





    /**-------------------------------------
     *      FORM VERIFICATION             |
     *------------------------------------*/
    const formVerificationCustomer = () => {
        let ban = 0;

        if(name === '')
        {
            setNameError(true);
            ban = 1;    
        }
        if(lastName === '')
        {
            setLastNameError(true);
            ban = 1;    
        }
        if(email === '')
        {
            setEmailError(true);
            ban = 1;
        }
        if(country.doc_obligatorio === 1 && document === '')
        {
            setDocumentError(true);
            ban = 1;
        }
        if(phoneNumber.length < 11)
        {
            setPhoneNumberError(true);
        }

        return ban;
    }

    const formVerificationRecordPayment = () => {
        let ban = 0;
        //return ban = 0; //quitar
        if(paymentMethod === null)
        {
            setState({field:'errorPaymentMethod',value:true});
            ban = 1;
        }

        if(amount === '')
        {
            setState({field:'errorAmount',value:true});
            ban = 1;
        }

        if(reference === '')
        {
            setState({field:'errorReference',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const formVerificationStep2 = () => {
        let ban = 0;
        //return ban; //quitar
        if(isComplimentary === false)
        {
            if(totalPaid < total)
                {
                    setState({field:'errorTotalPaid',value:true});
                    ban = 1;
                }
        }
        else
        {
            if(isNumeric(complimentaryCode) === false)
            {
                setComplimentaryCodeError(true);
                ban = 1;
            }
        }
        
        return ban;
    }


    /**-------------------------------------------------
    * Para manejar el paso a paso                      |
    * ------------------------------------------------*/
    const handleNext = async () => {
        if(activeStep === 0)
        {
            let nTickets = 0;
            await Promise.all(sections.map(item =>{
                console.log('CANTIDAD ===> ',item.cantidad)
                if(item.cantidad > 0)
                    nTickets += item.cantidad;
            }));

            if(nTickets > 0)
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            else
                return;
        }

        if(activeStep === 1)
        {
            if(formVerificationCustomer() === 0)
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if(activeStep === 2)
        {
            if(activeStep === 2 && formVerificationStep2() === 0)
            {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                handleSubmit();
            }
        }

        if(activeStep === 3)
        {
            if(result === true)
                handleFinalizar();
            else
                handleBack();
        }

        
        /*if(userData.user === undefined)
            setActiveStep(1);
        else
            setActiveStep(2);*/

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }


    /**---------------------------------------------------------------
     *                      GESTION DE PAGOS                        |
     *--------------------------------------------------------------*/
    const handleGetPaymentsMethods = async () => {
        let response = await GetAllBankAccounts();
        if(response.success === true)
        {
            setState({field:'paymentMethods',value: response.data.payment_methods});
            setState({field:'bankAccounts',value: response.data.accounts});
            setState({field:'paymentMethod',value: response.data.payment_methods[0]});
        }
    }

    const handleRecordPayment = async () => {
        if(formVerificationRecordPayment() === 0)
        {
            let _payments = payments;
            _payments.push({
                uuid: bankAccountSelected.uuid,
                paymentMethod: bankAccountSelected.payment_method,
                accountName: bankAccountSelected.description,
                amount: parseFloat(amount),
                reference: reference,
                id_numero_cuenta: bankAccountSelected.id
            });

            setPayments(_payments);
            /*setState({field:'payments',value:[...payments,
                {
                    uuid: bankAccountSelected.uuid,
                    paymentMethod: bankAccountSelected.payment_method,
                    accountName: bankAccountSelected.description,
                    amount: parseFloat(amount),
                    reference: reference,
                    id_numero_cuenta: bankAccountSelected.id
                }
            ]});*/
    
            setState({field:'totalPaid',value:totalPaid+parseFloat(amount)});
    
            handleClearPayment();
        }
    }

    const handlePaymentMethodSelected = async () => {
        let _aux = await Promise.all(bankAccounts.filter(item=>{
            return item.payment_method === paymentMethod.nombre
        }));
        
        setState({field:'bankAccountsAux',value:_aux});
        setState({field:'bankAccountSelected',value:_aux[0]})
    }

    const handleBankAccountSelected = async (item) => {
        setState({field:'bankAccountSelected',value:item})
    }
    
    useEffect(()=>{
        handlePaymentMethodSelected();
    },[paymentMethod]);

    const handleClearPayment = () => {
        //setState({field:'paymentMethod',value:null});
        setState({field:'amount',value:''});
        setState({field:'reference',value:''});
    }



    const handleSubmit = async () => {
        setIsLoading(true);

        let document_type = '';
        if(country.doc_obligatorio === 1)
            document_type = documentType;
        
        let cliente = {
            nombre: name,
            apellido: lastName,
            documento_tipo: document_type,
            documento: document,
            correo:email,
            prefijo:'',
            telefono: phoneNumber,
            profesion:''
        };

        //cliente = JSON.stringify(cliente);

        let detalles = await Promise.all(sections.filter(item => item.cantidad > 0 ));
        //detalles = JSON.stringify(detalles);

        const monto = parseFloat(subtotal);
        
        let response = await IssueTicketsAtBoxOffice(
            'merchant',
            event.id_evento,
            userData.user.id,
            userData.user.uuid,
            cliente,
            monto,
            comision,
            detalles,
            payments,
            holdToken,
            selectedSeats,
            event.event_key,
            isComplimentary,
            complimentaryCode
        );
        //console.log(response)
        if(response.success === true)
        {
            setState({field:'result',value:true});
            setState({field:'title',value:'Operación Exitosa'});
            setState({field:'message',value:response.message});

            setState({field:'ticketsIssued',value:response.data.tickets});
            console.log('RESPUESTA DE LOS TICKETS ===> ',response.data.tickets)
            console.log('Operación exitosa...')
        }
        else
        {
            setState({field:'result',value:false});
            setState({field:'title',value:'Operación Fallida'});
            setState({field:'message',value:response.message.message});
        }

        setIsLoading(false);
    }

    const handleFinalizar = () => {
        setPayments([]);
        setName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setTotal(0);
        setState({field:'totalPaid',value:0});

        handleGetSections();
        handleGetPaymentsMethods();

        setIsComplimentary(false);
        setComplimentaryCode('······');
        setComplimentaryCodeError(false);

        setActiveStep(0);
    }

    useEffect(()=>{
        if(complimentaryCode !== '······')
        {
            if(isNumeric(complimentaryCode) === true)
                setComplimentaryCodeError(false);
        }
    },[complimentaryCode])

    /**-------------------------------------------------------------
     *                      IMPRESION DE TICKETS                    |
     *--------------------------------------------------------------*/
    const handlePrintRBT = () => {
        printWithRawBT(userData.user.apellido,event.nombre,ticketsIssued);
    }


    return(
        <Grid container spacing={3}>
            <QUDProgress open={isLoading}/>
            <Grid item lg={12}>
                {
                    activeStep === 0 &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h6">Selecciona los asientos de la preferencia del cliente</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box component={'div'} sx={{width:'100%',height:500}}>
                                {
                                    tickets.length !== 0 &&
                                    <SeatsioSeatingChart
                                        workspaceKey="f0117c1e-e399-4ff5-93c2-ee4219d608f5"
                                        event={event.event_key}
                                        region="sa"
                                        pricing={categories}
                                        priceFormatter={price => '$' + price}
                                        onObjectSelected={(selectedObject,selectedTicketType) => {
                                            //console.log('Seleccion de Asiento ===> ',selectedObject);
                                            //se busca el item compatible con la categoria
                                            handleSelectingSeat(selectedObject,'+');
                                            //console.log('Seleccion de Asiento CHART ===> ',selectedObject.chart.holdToken);
                                            setSelectedSeats(selectedObject.chart.selectedSeats);
                                            setHoldToken(selectedObject.chart.holdToken);

                                        }}
                                        onObjectDeselected={(selectableObject)=>{handleSelectingSeat(selectableObject,'-')}}
                                        session="start"
                                    />
                                }
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Paper elevation={4}>
                                <Typography fontWeight={'bold'}>Resumen del pedido</Typography>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{p:1}}> 
                                            {
                                                sections.length !== 0 &&
                                                sections.map(item=>{
                                                    if(item.cantidad > 0)
                                                    return(
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                            <Typography>{item.nombre} x {item.cantidad}</Typography>
                                                            <Typography>{parseFloat(item.cantidad*item.precio).toFixed(2)}</Typography>
                                                        </Box>
                                                    )
                                                })
                                            }
                                            <Divider sx={{mt:2}}/>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',mt:2}}>
                                                <Typography>Subtotal</Typography>
                                                <Typography>{parseFloat(subtotal).toFixed(2)}</Typography>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Fee</Typography>
                                                <Typography>{parseFloat(fee).toFixed(2)}</Typography>
                                            </Box>
                                            {
                                                event.event_key !== null &&
                                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                    <Typography>Service fee</Typography>
                                                    <Typography>{parseFloat(feeSeats).toFixed(2)}</Typography>
                                                </Box>
                                            }
                                        </Box>
                                        
                                        <Box sx={{bgcolor:'#F5F8FC',p:1,mt:1,borderRadius:3}}>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Total</Typography>
                                                <Typography>{parseFloat(total).toFixed(2)}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                }


                {
                    activeStep === 1 &&
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h6" mb={2}>Ingresa los datos del cliente</Typography>
                        </Grid>
                        <Grid item lg={6} xl={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            name="name"
                                            label="Nombre"
                                            value={name}
                                            onChange={(e)=>setName(e.target.value)}
                                            onBlur={ () => (setNameError(name===''?true:false)) }
                                            error = {nameError}
                                            helperText={nameError && 'Ingrese el nombre'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextField
                                            fullWidth
                                            id="lastName"
                                            name="lastName"
                                            label="Apellido"
                                            value={lastName}
                                            onChange={(e)=>setLastName(e.target.value)}
                                            onBlur={ () => (setLastNameError(lastName===''?true:false)) }
                                            error = {lastNameError}
                                            helperText={lastNameError && 'Ingrese el apellido'}
                                        />
                                    </Grid>

                                    {
                                        country.doc_obligatorio === 1 &&
                                        <>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <TextField
                                                    fullWidth
                                                    id="documentType"
                                                    name="documentType"
                                                    label="Documento"
                                                    select
                                                    value={documentType}
                                                    onChange={(e) => setDocumentType(e.target.value) }
                                                >
                                                    <MenuItem value='V'>V</MenuItem>
                                                    <MenuItem value='E'>E</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <TextField
                                                    fullWidth
                                                    label={event.dirigido_a === 0 ? 'Número de Cédula' : 'Número de RIF'}
                                                    name='document'
                                                    id='document'
                                                    required
                                                    onChange={(e) => setDocument(e.target.value) }
                                                    onBlur={ () => (setDocumentError(document==='')) }
                                                    error = {documentError}
                                                    helperText={documentError && 'Ingrese la Cédula /RIF del cliente'}
                                                />
                                            </Grid>
                                        </>
                                    }

                                    {/**Telefono */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <PhoneInput
                                            country={'ve'}
                                            specialLabel="Número de Teléfono"
                                            inputStyle={{width:'100%'}}
                                            value={phoneNumber}
                                            onChange={(e)=>setPhoneNumber(e)} // passed function receives the phone value
                                            onBlur={ () =>  (setPhoneNumberError(phoneNumber.length < 11?true:false)) }
                                        />
                                        {
                                            phoneNumberError &&
                                            <Typography variant="caption" color={'error.main'}>El número de teléfono es inválido</Typography>
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Correo electrónico"
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value)}
                                            onBlur={ () => (setEmailError(email===''?true:false)) }
                                            error = {emailError}
                                            helperText={emailError && 'Ingrese el correo electrónico'}
                                        />
                                    </Grid>

                                    
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Paper elevation={4}>
                                <Typography fontWeight={'bold'}>Resumen del pedido</Typography>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{p:1}}> 
                                            {
                                                sections.length !== 0 &&
                                                sections.map(item=>{
                                                    if(item.cantidad > 0)
                                                    return(
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                            <Typography>{item.nombre} x {item.cantidad}</Typography>
                                                            <Typography>{parseFloat(item.cantidad*item.precio).toFixed(2)}</Typography>
                                                        </Box>
                                                    )
                                                })
                                            }
                                            <Divider sx={{mt:2}}/>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',mt:2}}>
                                                <Typography>Subtotal</Typography>
                                                <Typography>{parseFloat(subtotal).toFixed(2)}</Typography>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Fee</Typography>
                                                <Typography>{parseFloat(fee).toFixed(2)}</Typography>
                                            </Box>
                                            {
                                                event.event_key !== null &&
                                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                    <Typography>Service fee</Typography>
                                                    <Typography>{parseFloat(feeSeats).toFixed(2)}</Typography>
                                                </Box>
                                            }
                                        </Box>
                                        
                                        <Box sx={{bgcolor:'#F5F8FC',p:1,mt:1,borderRadius:3}}>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Total</Typography>
                                                <Typography>{parseFloat(total).toFixed(2)}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                }

                {
                    activeStep === 2 &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h6" mb={2}>Registro de Pagos</Typography>
                        </Grid>
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} xl={6} justifyContent={'center'}>
                                        <Typography textAlign={'center'}>
                                            USD &nbsp;
                                            {parseFloat(totalPaid).toFixed(2)} /&nbsp;
                                            <span style={{fontWeight:'bold'}}>{parseFloat(total).toFixed(2)}</span>
                                            &nbsp;
                                            <IconButton onClick={()=>setState({field:'showPayments',value:!showPayments})}>
                                                {
                                                    totalPaid < total &&
                                                    (
                                                        showPayments === true
                                                        ?<FontAwesomeIcon style={{fontSize:17}} icon={faEyeSlash}/>
                                                        :<FontAwesomeIcon style={{fontSize:17}} icon={faEye}/>
                                                    )
                                                }
                                                
                                            </IconButton>
                                        </Typography>
                                        <Typography textAlign={'center'}>VES {parseFloat(totalPaid*exchangeRate).toFixed(2)} / {parseFloat(total*exchangeRate).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item lg={6} xl={6} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                        <Button variant='outlined' onClick={()=>setIsComplimentary(!isComplimentary)}>
                                            {
                                                isComplimentary === true
                                                ?<Typography>Cancelar emision de cortesia</Typography>
                                                :<Typography>Emitir entradas de cortesia</Typography>
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                            </Grid>

                                {
                                    isComplimentary === true
                                    ?
                                    <Grid item lg={6} xl={6}>
                                        <Typography variant="h6" textAlign={'center'} gutterBottom>Para emitir entradas de cortesia por favor ingrese el codigo secreto establecido para tal fin</Typography>
                                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                            <ReactInputVerificationCode
                                                type="password"
                                                length={6}
                                                onChange = {(e)=>setComplimentaryCode(e)}
                                                //onCompleted={()=>console.log('isNumeric value ===> ',isNumeric(complimentaryCode))}
                                            />
                                        </Box>
                                        {
                                            complimentaryCodeError &&
                                            <Typography variant="h6" color={'error.main'} textAlign={'center'} gutterBottom>
                                                Complete el código solo con números
                                            </Typography>
                                        }
                                    </Grid>
                                    :
                                    totalPaid >= total || showPayments === true
                                    ?
                                    <Grid item lg={12}>
                                        {
                                            payments.length !== 0 &&
                                            payments.map((item)=>
                                                <Box sx={{display:'flex',flexDirection:'column',mt:2}}>
                                                    <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <Typography variant="h4">{parseFloat(item.amount).toFixed(2)} $</Typography>
                                                        <Box sx={{ml:2}}>
                                                            <Typography>{item.paymentMethod}</Typography>
                                                            <Typography>{item.accountName} #{item.reference}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider/>
                                                </Box>
                                            )
                                        }
                                    </Grid>
                                    :
                                    <>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} lg={12}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            autoFocus={true}
                                                            id="paymentMethod"
                                                            name='paymentMethod'
                                                            label="Forma de Pago"
                                                            variant="outlined"
                                                            value = {paymentMethod}
                                                            onChange = { onChange }
                                                            onBlur={ () => (paymentMethod === null ? setState({field:'errorPaymentMethod',value:true}) : setState({field:'errorPaymentMethod',value:false}) ) }
                                                            error = {errorPaymentMethod}
                                                            helperText={errorPaymentMethod && 'Seleccione una forma de pago'}
                                                        >
                                                            <MenuItem value={null}>Seleccione una forma de pago</MenuItem>
                                                            {
                                                                paymentMethods &&
                                                                paymentMethods.map(item=>
                                                                    <MenuItem key={item.id} value={item}>{item.nombre}</MenuItem>
                                                                )
                                                            }
                                                        </TextField>
                                                    </Grid>
                                                    
                                                    {/**Se mapea bankAccountsAux */}
                                                    {
                                                        (bankAccountsAux && paymentMethod) &&
                                                        bankAccountsAux.map(item=>
                                                            bankAccountSelected && item.uuid === bankAccountSelected.uuid 
                                                            ?
                                                            <Grid
                                                                key={item.uuid} item xs={12} sm={6} md={4} lg={6} xl={6}
                                                                onClick={()=>handleBankAccountSelected(item)}
                                                            >
                                                                <Paper sx={{border:'solid',borderWidth:1,borderColor:'success.light',cursor:'pointer',bgcolor:'success.lightTransparency',color:'success.dark'}}>
                                                                    <Typography>{item.bank} - {item.routing_number}</Typography>
                                                                    <Typography>{userData.user.documento}</Typography>
                                                                    <Typography>{item.account_number}</Typography>
                                                                </Paper>
                                                            </Grid>
                                                            :
                                                            <Grid
                                                                key={item.uuid} item xs={12} sm={6} md={4} lg={6} xl={6}
                                                                onClick={()=>handleBankAccountSelected(item)}
                                                            >
                                                                <Paper sx={{cursor:'pointer'}}>
                                                                    <Typography>{item.banco} - {item.numero_ruta}</Typography>
                                                                    <Typography>{userData.user.documento}</Typography>
                                                                    <Typography>{item.numero_cuenta}</Typography>
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>                        
                                            </Box>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} lg={12}>
                                                        <TextField
                                                            fullWidth
                                                            label='Monto ($)'
                                                            name='amount'
                                                            id='amount'
                                                            required
                                                            value={amount}
                                                            onChange = { onChange }
                                                            onBlur={ () => (amount === '' ? setState({field:'errorAmount',value:true}) : setState({field:'errorAmount',value:false}) ) }
                                                            error = {errorAmount}
                                                            helperText={errorAmount && 'Ingrese el monto en $ del pago'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={12}>
                                                        <TextField
                                                            fullWidth
                                                            label='Referencia'
                                                            name='reference'
                                                            id='reference'
                                                            required
                                                            value={reference}
                                                            onChange = { onChange }
                                                            onBlur={ () => (reference === '' ? setState({field:'errorReference',value:true}) : setState({field:'errorReference',value:false}) ) }
                                                            error = {errorReference}
                                                            helperText={errorReference && 'Ingrese la referencia del pago'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} >
                                                        <LoadingButton
                                                            style={{padding:15}}
                                                            fullWidth
                                                            variant="contained"
                                                            onClick={()=>handleRecordPayment()}
                                                        >
                                                            <Typography>
                                                                Registrar Pago
                                                            </Typography>
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </>
                                }
                                
                            </>
                    </Grid>
                }

                {
                    activeStep === 3 &&
                    <>
                        <Grid item lg={12} xl={12}>
                            {
                                isLoading === true
                                ?<QUDProgress open={isLoading}/>
                                :
                                <Grid container spacing={3} justifyContent={'center'}>
                                    <Grid item lg={4} xl={4} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            result === true
                                            ?<FontAwesomeIcon style={{fontSize:50,color:'#2E7D32'}} icon={faCheckCircle}/>
                                            :<FontAwesomeIcon style={{fontSize:50,color:'#F33232'}} icon={faTimesCircle}/>
                                        }
                                        <Typography variant="h6" sx={{fontWeight:'bold'}}>¡{title}!</Typography>
                                        <Typography variant="h6" textAlign={'center'}>{message}</Typography>
                                        <Button
                                            sx={{mt:5}}
                                            variant="contained"
                                            onClick={()=>handlePrintRBT()}
                                        >
                                            Imprimir (Android)
                                        </Button>
                                        <PrintWithQZTray
                                            merchant={userData.user.apellido}
                                            event={event.nombre}
                                            tickets={ticketsIssued}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            
                        </Grid>
                    </>
                }
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={3} justifyContent={'space-between'} mt={3}>
                    <Grid item lg={2}>
                        {
                            (activeStep > 0 && activeStep < 3) &&
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={()=>handleBack()}
                            >
                                <Typography>Volver</Typography>
                            </Button>
                        }
                    </Grid>

                    <Grid item lg={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={()=>handleNext()}
                        >
                            <Typography>
                                {
                                    activeStep < 3
                                    ?
                                        result === false
                                        ?'Reintentar'
                                        :'Continuar'
                                    :
                                        result === true
                                        ?'Finalizar'
                                        :'Volver'
                                }
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


/**
 * <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant="h6">Selecciona los asientos de la preferencia del cliente</Typography>
                            <Box component={'div'} sx={{width:'100%',height:500}}>
                                {
                                    tickets.length !== 0 &&
                                    <SeatsioSeatingChart
                                        workspaceKey="f0117c1e-e399-4ff5-93c2-ee4219d608f5"
                                        event={event.event_key}
                                        region="sa"
                                        pricing={categories}
                                        priceFormatter={price => '$' + price}
                                        onObjectSelected={(selectedObject,selectedTicketType) => {
                                            //console.log('Seleccion de Asiento ===> ',selectedObject);
                                            //se busca el item compatible con la categoria
                                            handleSelectingSeat(selectedObject,'+');
                                            //console.log('Seleccion de Asiento CHART ===> ',selectedObject.chart.holdToken);
                                            setSelectedSeats(selectedObject.chart.selectedSeats);
                                            //setHoldToken(selectedObject.chart.holdToken);

                                        }}
                                        onObjectDeselected={(selectableObject)=>{handleSelectingSeat(selectableObject,'-')}}
                                        //session=""
                                    />
                                }
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Paper elevation={4}>
                                <Typography fontWeight={'bold'}>Resumen del pedido</Typography>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{p:1}}> 
                                            {
                                                sections.length !== 0 &&
                                                sections.map(item=>{
                                                    if(item.cantidad > 0)
                                                    return(
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                            <Typography>{item.nombre} x {item.cantidad}</Typography>
                                                            <Typography>{parseFloat(item.cantidad*item.precio).toFixed(2)}</Typography>
                                                        </Box>
                                                    )
                                                })
                                            }
                                            <Divider sx={{mt:2}}/>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Subtotal</Typography>
                                                <Typography>{parseFloat(subtotal).toFixed(2)}</Typography>
                                            </Box>
                                        </Box>
                                        
                                        <Box sx={{bgcolor:'#F5F8FC',p:1,mt:1,borderRadius:3}}>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Total</Typography>
                                                <Typography>{parseFloat(total).toFixed(2)}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                                <Button variant="contained" sx={{mt:3}} onClick={()=>handleNext()}>
                                    <Typography>Continuar</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
 */