import { useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Grid, IconButton, MenuItem, Paper, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faCircleDot, faCopy, faMoneyBillWave, faPaperPlane, faPerson, faReceipt, faStar, faTimeline, faTriangleExclamation, faTruckFast, faUser } from "@fortawesome/free-solid-svg-icons";
import OrderStatus from "./status";
import OrderDetailsDataTable from "./detailsDataTable";
import OrderDetailsPaymentsDataTable from "./detailsPaymentsDataTable";
import GoogleMaps from "../../../components/maps/GoogleMaps";
import MapContainer from '../../../components/map';

import PropTypes from 'prop-types';
import { getOrderDetails, updateOrderStatus } from "../../../controllers/company/OrdersController";
import { RestorePageOutlined } from "@mui/icons-material";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import QUDProgress from "../../../components/progress";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";


import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styled from "styled-components";

const CustomTimeline = styled(VerticalTimelineElement)`
    .vertical-timeline-element-icon{
        box-shadow: 0 0 0 4px #000, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05);
    }
`;


/**Tab panel para los detalles del pedido */
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box sx={{pt:5}}>
                {children}
            </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function ButtonStatus({status_id,status,handleClick})
{
    let nextStatus = 1;
    if(status_id === 1)
        nextStatus = 2;
    else if(status_id === 2)
        nextStatus = 3;
    else if(status_id === 3)
        nextStatus = 4;
    else if(status_id === 4)
        nextStatus = 5;
    else if(status_id === 5)
        nextStatus = 6;
    else
        return null

    return(
        <Button
            variant="contained"
            color="primary"
            sx={{ml:2}}
            onClick={()=>handleClick(nextStatus)}
        >
            <Typography>{status}</Typography>
        </Button>
    )
}



export default function OrderDetails()
{
    const theme = useTheme();
    const { code } = useParams();
    const [initialCenter,setInitialCenter] = useState({lat:10.189640,lng:-64.688094});
    const [value, setValue] = useState(0);

    const [isLoading,setIsLoading] = useState();
    const [order,setOrder] = useState([]);
    const [orderStatus,setOrderStatus] = useState('');
    const [details,setDetails] = useState([]);
    const [payments,setPayments] = useState([]);
    const [customer,setCustomer] = useState([]);
    const [tracking,setTracking] = useState([]);

    useEffect(()=>{
        window.scrollTo(0,0);
        handleGetOrderDetails();
    },[]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleGetOrderDetails = async () => {
        setIsLoading(true);
        let response = await getOrderDetails(code);
        if(response.success === true)
        {
            setOrder(response.data.order);
            setOrderStatus(response.data.order_status);
            setDetails(response.data.details);
            setPayments(response.data.payments);
            setCustomer(response.data.customer);
            setTracking(response.data.tracking);
            setInitialCenter({lat:response.data.order.origen_latitud, lng:response.data.order.origen_longitud})
        }
        setIsLoading(false);
    }


    const handleUpdateOrderStatus = async (estatus) => {
        setIsLoading(true);
        let response = await updateOrderStatus(order.id_pedido,estatus);
        if(response.success === true)
        {
            handleGetOrderDetails()
        }
        setIsLoading(false);
    }

    const handleStatusAction = (status) => {
        
    }



    return(
        <Container maxWidth='lg'>
            <CustomBreadcrumbs returnTo='/orders' crumbs={[{name:'Pedidos',link:'/orders'}]}/>
            <br/>
            <SectionTitle title='Detalles Pedido'/>

            {/**Datos Generales del pedido */}
            <Grid container spacing={3} mt={-5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={3}>
                        <Grid container spacing={3}>
                            {/**Datos del Pedido */}
                            <Grid item lg={6} xl={6}>
                                <Typography variant="h6" color={'cancel.dark'}>Pedido: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{code}</span></Typography>
                                <Typography variant="h6" color={'cancel.dark'}>Tipo: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{order.tipo_pedido}</span></Typography>
                                <Typography variant="h6" color={'cancel.dark'}>Entrega: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{order.tipo_entrega}</span></Typography>
                                <Typography variant="h6" color={'cancel.dark'}>Monto: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{order.monto} USD</span></Typography>
                            </Grid>

                            <Grid item lg={6} xl={6}>
                                <Typography textAlign={'right'} variant="h6" color={'link.main'}><FontAwesomeIcon icon={faCircleDot}/> <span style={{fontWeight:'bold'}}>{orderStatus}</span></Typography>
                                <Typography textAlign={'right'} variant="body1" color={'cancel.dark'}>{'15/02/2024'}</Typography>
                                <Grid container spacing={3} mt={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                    {
                                        order.status === 'Realizado' &&
                                        <Grid item lg={3}>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="medium"
                                                //handleClick={handleUpdateOrderStatus}
                                            >
                                                <Typography>Rechazar</Typography>
                                            </Button>
                                        </Grid>
                                    }
                                    {
                                        order.length !== 0 &&
                                        <Grid item lg={6}>
                                            <TextField
                                                fullWidth
                                                label='Estatus del pedido'
                                                select
                                                value={order.status_id}
                                                onChange={(e)=>handleUpdateOrderStatus(e.target.value)}
                                                disabled={order.status_id === 0 || order.status_id === 6}
                                            >
                                                <MenuItem value={0}>Rechazado</MenuItem>
                                                <MenuItem value={1}>Realizado</MenuItem>
                                                <MenuItem value={2}>Aceptado</MenuItem>
                                                <MenuItem value={3}>Por Enviar</MenuItem>
                                                <MenuItem value={4}>Enviado</MenuItem>
                                                <MenuItem value={5}>Entregado</MenuItem>
                                                <MenuItem value={6}>Finalizado</MenuItem>
                                            </TextField>
                                        </Grid>
                                    }
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            

            <Grid container spacing={3} mt={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab icon={<FontAwesomeIcon size="2x" icon={faReceipt} />} iconPosition="start" label={<Typography fontWeight={'bold'}>Detalles</Typography>} {...a11yProps(0)} />
                            <Tab icon={<FontAwesomeIcon size="2x" icon={faTruckFast} />} iconPosition="start"  label={<Typography fontWeight={'bold'}>Entrega</Typography>} {...a11yProps(1)} />
                            <Tab icon={<FontAwesomeIcon size="2x" icon={faUser} />} iconPosition="start"  label={<Typography fontWeight={'bold'}>Cliente</Typography>} {...a11yProps(2)} />
                            <Tab icon={<FontAwesomeIcon size="2x" icon={faMoneyBillWave} />} iconPosition="start"  label={<Typography fontWeight={'bold'}>Pagos</Typography>} {...a11yProps(3)} />
                            <Tab icon={<FontAwesomeIcon size="2x" icon={faBarsStaggered} />} iconPosition="start"  label={<Typography fontWeight={'bold'}>Seguimiento</Typography>} {...a11yProps(4)} />
                        </Tabs>
                    </Box>

                    {/**Detalles del pedido */}
                    <CustomTabPanel value={value} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {
                                    details.length !== 0
                                    ?<OrderDetailsDataTable details={details} total={order.monto}/>
                                    :
                                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        <FontAwesomeIcon size="2x" icon={faTriangleExclamation}/>
                                        <Typography>No se encontraron detalles</Typography>
                                    </Box>
                                }   
                            </Grid>
                        </Grid>
                    </CustomTabPanel>

                    {/**Datos de Entrega */}
                    <CustomTabPanel value={value} index={1}>
                        <Grid container spacing={3} mt={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper elevation={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Typography variant="h6" gutterBottom>Detalles de entrega</Typography>
                                            <Typography variant="h6" color={'cancel.dark'}>Tipo de entrega: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{order.delivery}</span></Typography>
                                            <Box mt={2}>
                                                <Typography variant="h6" color={'cancel.dark'}>
                                                    Direccion de Origen
                                                </Typography>
                                                <Typography variant="h6" fontWeight={'bold'}>{order.origen_direccion}</Typography>
                                            </Box>

                                            <Box mt={2}>
                                                <Typography variant="h6" color={'cancel.dark'}>
                                                    Direccion de Destino
                                                </Typography>
                                                <Typography variant="h6" fontWeight={'bold'}>{order.destino_direccion}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <MapContainer
                                                center={initialCenter}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                    

                    {/**Datos de Cliente */}
                    <CustomTabPanel value={value} index={2}>
                        <Paper elevation={3}>
                            <Grid container spacing={3}>
                                <Grid item sx={12} sm={12} md={4} lg={3} xl={3}>
                                    <Avatar
                                        alt="QUD"
                                        src={imagesURL+'users/'+customer.image}
                                        sx={{ width: 150, height: 150 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography variant="h6" color={'cancel.dark'}>Nombre: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{customer.customer}</span></Typography>
                                    <Typography variant="h6" color={'cancel.dark'}>Teléfono : <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{customer.phone_number}</span></Typography>
                                    <Typography variant="h6" color={'cancel.dark'}>Correo: <span style={{fontWeight:'bold',color:theme.palette.primary.main}}>{customer.email}</span></Typography>
                                    
                                </Grid>
                                <Grid item sx={12} sm={12} md={4} lg={3} xl={3}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} xl={6}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                target='_blank'
                                                    //href={"tel:"+user.telefono}
                                                href={`https://wa.me/${customer.phone_number}`}
                                            >
                                                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                    <FontAwesomeIcon size="2x" icon={faWhatsapp}/>
                                                    <Typography variant="h6">Contactar</Typography>
                                                </Box>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </CustomTabPanel>


                    {/**Datos de Pagos */}
                    <CustomTabPanel value={value} index={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {
                                    payments.length !== 0
                                    ? <OrderDetailsPaymentsDataTable payments={payments}/>
                                    : <Typography variant="h6">No hay pagos registrados</Typography>
                                }
                            </Grid>
                        </Grid>
                    </CustomTabPanel>


                    {/**Datos de Seguimiento */}
                    <CustomTabPanel value={value} index={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <VerticalTimeline
                                    layout="1-column-left"
                                    lineColor={theme.palette.cancel.main}
                                    
                                >
                                    {
                                        tracking.length !== 0 &&
                                        tracking.map(item=>
                                            <VerticalTimelineElement
                                                icon={<FontAwesomeIcon color={theme.palette.primary.main} icon={faPaperPlane}/>}
                                                iconStyle={{
                                                    boxShadow:`0 0 0 4px ${theme.palette.cancel.main}, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05)`,
                                                    background:theme.palette.link.lightTransparent
                                                }}
                                                date={item.created_at}
                                            >
                                                <Typography variant='h6' fontWeight={'bold'}>{item.nombre_estatus}</Typography>
                                                <Typography variant='body2' fontWeight={'bold'}>{item.user}</Typography>
                                                <Typography variant='body1'>
                                                    {item.observaciones}
                                                </Typography>
                                            </VerticalTimelineElement>
                                        )
                                    }
                                </VerticalTimeline>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                </Grid>
            </Grid>
            
            <QUDProgress open={isLoading}/>
        </Container>
    )
}