import React, { useEffect, useReducer, useState } from "react";
import { Box, Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomBreadcrumbs from "../../../components/breadcrumbs";

import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import { useHistory } from "react-router-dom";
import { AddCategory, UpdateCategory } from "../../../controllers/company/SubCategoriesController";
import ModalDialog from "../../../components/modalDialog";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";




//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    uuid:'',
    imagen:'',
    nombre : '',          errorNombre : false,
    descripcion : '',     errorDescripcion : false,
    estatus : '',         errorEstatus : false,
    
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }


export default function CategoriesUpdate()
{
    const [state,setState] = useReducer(reducer,initialState);
    const [rutaImg,setRutaImg] = useState('');
    const [img,setImg] = useState([]);

    const [cover,setCover] = useState([]);
    const [coverError,setCoverError] = useState(true);

    const {
        uuid,
        imagen,
        nombre, errorNombre, descripcion, errorDescripcion,
        estatus, errorEstatus,

        isLoading,
        open,
        title,
        message,
        success

    } = state;


    useEffect(()=>{
        setCategory();
    },[]);

    const history = useHistory();

    const setCategory = () => {
        let category = JSON.parse(localStorage.getItem('categoria'));
        setRutaImg(imagesURL+'subcategorias/'+category.imagen);
        setState({field:'uuid',value:category.uuid});
        setState({field:'imagen',value:category.imagen});
        setState({field:'nombre',value:category.nombre});
        setState({field:'descripcion',value:category.descripcion});
        setState({field:'estatus',value:category.estatus});
    }


    const updateCover = (incommingFiles) => {

        setCover(incommingFiles);
        if(incommingFiles.length === 0)
            setCoverError(true);
        else
        {
            setCoverError(false);
            setImg(incommingFiles[0].file);
        }
            
    };



    const handleImagen = async (event) =>
    {
        await setImg(event.target.files[0]);
        await setRutaImg(URL.createObjectURL(event.target.files[0]));
    }

    
    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }
        
        return ban;
    }
    
    
    const handleAddProduct = async () => {
        setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            let response = await UpdateCategory(uuid,nombre,descripcion,estatus,img);
            if(response.success === true)
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:response.message.message});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:response.message.message});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
        }
        setState({field:'isLoading',value:false});
    }

    const clear = () => {
        setState({field:'nombre',value:''}); setState({field:'errorNombre',value:false});
        setState({field:'descripcion',value:''}); setState({field:'errorDescripcion',value:false});
        setState({field:'estatus',value:''}); setState({field:'errorEstatus',value:false});

        setRutaImg(''); setImg([]);

        setState({field:'open',value:false});
    }


    const handleClose = () => {
        if(success === true)
            history.push('/categories');
        else
            setState({field:'open',value:'false'});
    }


    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/categories'
                crumbs={[
                    {name:'Categorías',link:'/products'},
                    {name:'Agregar',link:'/products/add'}
                ]}
            />
            <br/>
            <Typography variant='h4' component='h4' sx={{textAlign:'center'}}>
                Agregar categoría
            </Typography>
            <br/>
            <Grid container spacing={3} justifyContent='center'>
                {/**Cover image */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Dropzone
                        maxFiles={1}
                        label="Foto de portada"
                        accept="image/*"
                        localization="Es-es"
                        onChange={updateCover} value={cover}
                        
                    >
                        
                        {
                            cover.length > 0 
                            ?
                                cover.map((file) => (
                                    <Box sx={{width:'100%', height:{xs:150,sm:200,md:200,lg:250,xl:250},p:2}}>
                                        <FileMosaic style={{width:'100%',height:'100%'}} {...file} preview  />
                                    </Box>
                                ))
                            :
                                <Box sx={{width:'100%', height:{xs:150,sm:200,md:200,lg:250,xl:250},p:2}}>
                                    <img style={{width:'100%',height:'100%'}} src={imagesURL+'subcategorias/'+imagen} alt={'QUD'}/>
                                </Box>
                        }
                    </Dropzone>
                </Grid>

                {/**Nombre - Descripcion - Unidad de medida - Categoria - Precio - Estatus */}
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Nombre */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Nombre'
                                name='nombre'
                                id='nombre'
                                required
                                value={nombre}
                                onChange = { onChange }
                                onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                error = {errorNombre}
                                helperText={errorNombre && 'Ingrese el nombre del category'}
                            />
                        </Grid>

                        {/**Descripcion */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label='Descripción'
                                name='descripcion'
                                id='descripcion'
                                required
                                value={descripcion}
                                onChange = { onChange }
                                onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                error = {errorDescripcion}
                                helperText={errorDescripcion && 'Ingrese la descripción del category'}
                            />
                        </Grid>

                        {/**Estatus */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                select
                                id="estatus"
                                name='estatus'
                                label="estatus"
                                variant="outlined"
                                value = {estatus}
                                onChange = { onChange }
                                onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                                error = {errorEstatus}
                                helperText={errorEstatus && 'Seleccione el estatus'}
                            >
                                <MenuItem value=''>Seleccione un estatus</MenuItem>
                                <MenuItem value='1'>Activo</MenuItem>
                                <MenuItem value='0'>Inactivo</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                loading={isLoading}
                                fullWidth
                                variant="contained"
                                onClick={()=>handleAddProduct()}
                            >
                                <Typography>
                                    Actualizar categoría
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>handleClose()}
            />

            {/*<ModalDialog
                open={open}
                title={title}
                msg={message}

                close='Cerrar'
                handleClose={()=>success === true ? history.push('/categories') : setState({field:'open',value:'false'})}
                
                action='Aceptar'
                handleAction={()=>success === true ? history.push('/categories') : setState({field:'open',value:'false'})}
            />*/}
        </Container>
    )
}