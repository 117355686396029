import { Button, Container, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import AddCircleIcon from '@mui/icons-material/AddCircle';

import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import AssociatesDataTable from "./table";

export default function BusinessCards()
{
    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Asociados',link:'/associates'}]}/>
            <br/>
            <SectionTitle title='Asociados'/>
            <Button
                variant='contained'
                endIcon={<AddCircleIcon/>}
                onClick={()=>redirect('associates/add')}
            >
                <Typography>Agregar</Typography>
            </Button>
            <br/><br/>
            <AssociatesDataTable/>
        </Container>
    )
}