import { useContext, useState } from "react";
import { Button, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import UserContext from "../../../navigation/context/userContext";
import AuxAlert from "../../../components/alert";
import { UpdateDatos } from "../../../controllers/company/CompanyProfileController";

import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";

export default function DatosGenerales(props)
{
    const {userData,setUserData} = useContext(UserContext)
    const [categories,setCategories] = useState(props.categories);
    
    const [category,setCategory] = useState(props.category);
    const [errorCategory,setErrorCategory] = useState(false);

    const [tradeName,setTradeName] = useState(props.tradeName);
    const [errorTradeName,setErrorTradeName] = useState(false);

    const [slogan,setSlogan] = useState(props.slogan);
    const [errorSlogan,setErrorSlogan] = useState(false);

    const [description,setDescription] = useState(props.description);
    const [errorDescription,setErrorDescription] = useState(false);

    const [isDisabled,setIsDisabled] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] =useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);

    const formVerification = () => {
        var ban = 0;

        if(description === '')
        {
            setErrorDescription(true)
            ban = 1;
        }
            
        if(category === '')
        {
            setErrorCategory(true);
            ban = 1;
        }
        
        return ban;
    }
    
    const handleUpdateDatosGenerales = async () => {
        if(formVerification() === 0)
        {
            setIsLoading(true);
            let response = await UpdateDatos(tradeName,category,description,slogan);
            if(response.success === true)
            {
                setSuccess(true);
                setTitle('Operación Exitosa!');
                setMessage(response.message);
                setOpen(true);
                await setTimeout( () => 
                    setOpen(false),7000
                );
            }
            else
            {
                setSuccess(false);
                setTitle('Operación Fallida!');
                setMessage(response.message);
                setOpen(true);
            }
            setIsLoading(false);
        }
    }

    

    return(
        <Paper sx={{position:'relative'}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' justifyContent='space-between'>
                    <Typography variant='h6' sx={{fontWeight:'bold'}}>Datos generales</Typography>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        onClick={()=>handleUpdateDatosGenerales()}
                        startIcon={<SaveIcon />}
                    >
                        Guardar
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        label='Nombre Legal'
                        value={userData.user.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        label='RIF'
                        value={userData.user.documento}
                    />
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label='Nombre Comercial'
                        value={tradeName}
                        onChange = { (e) => setTradeName(e.target.value) }
                        onBlur={ () => (tradeName === '' ? setErrorTradeName(true) : setErrorTradeName(false) ) }
                        error = {errorTradeName}
                        helperText={errorTradeName && 'Ingrese el nombre comercial'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        disabled={isDisabled}
                        fullWidth
                        select
                        id="categoria"
                        name='categoria'
                        label="Categoría"
                        placeholder='Seleccione una categoría'
                        variant="outlined"
                        value = {category}
                        onChange = { (e) => setCategory(e.target.value) }
                        onBlur={ () => (category === '' ? setErrorCategory(true) : setErrorCategory(false) ) }
                        error = {errorCategory}
                        helperText={errorCategory && 'Seleccione la categoría de la empresa'}
                    >
                        <MenuItem value=''>Seleccione una Categoría</MenuItem>
                        {
                            categories.length !== 0 &&
                            categories.map((item)=>
                                <MenuItem value={item.idcategoria}>{item.nombre}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label='Eslogan'
                        value={slogan}
                        onChange = { (e) => setSlogan(e.target.value) }
                        //onBlur={ () => (slogan === '' ? setErrorSlogan(true) : setErrorSlogan(false) ) }
                        //error = {errorSlogan}
                        //helperText={errorSlogan && 'Ingrese el eslogan'}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        disabled={isDisabled}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label='Descripción'
                        value={description}
                        onChange = { (e) => setDescription(e.target.value) }
                        onBlur={ () => (category === '' ? setErrorDescription(true) : setErrorDescription(false) ) }
                        error = {errorDescription}
                        helperText={errorDescription && 'Debes ingresar la descripción de la empresa'}
                    />
                </Grid>
            </Grid>
            
            <AuxAlert
                open={open}
                success={success}
                title={title}
                message={message}
            />
        </Paper>
    )
}