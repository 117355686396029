import { useState } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

export default function AuxAlert(props)
{
    const [open,setOpen] = useState(props.open);
    const handleClose = () => {
        setOpen(false);
    }

    return(
        <Snackbar open={props.open} anchorOrigin={{vertical:'top',horizontal:'right'}} autoHideDuration={6000} onClose={handleClose}>
            <Alert 
                //style={{position:'absolute',right:0,top:100,zIndex:10}} 
                severity={props.success === true ? "success" : "error"}
            >
                <AlertTitle>{props.title}</AlertTitle>
                {props.message}
            </Alert>
        </Snackbar>
    )
}
