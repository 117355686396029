import { useEffect, useState } from "react";
import { Box, Button, Container, Divider, Grid, LinearProgress, Paper, Rating, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReviewsIcon from '@mui/icons-material/Reviews';
import { getAll } from "../../../controllers/company/ReviewsController";
import ReviewsDataTable from "./dataTable";
import QUDProgress from "../../../components/progress";

export default function Reviews()
{
    const {t, i18n} = useTranslation();

    const [reviews,setReviews] = useState([]);
    const [reviewsCount,setReviewsCount] = useState(0);
    const [reviewsAvg,setReviewsAvg] = useState(0);
    const [reviewsValue,setReviewsValue] = useState([]);

    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        handleGetReviews();
    },[]);

    const handleGetReviews = async () => {
        setIsLoading(true);
        let response = await getAll();
        if(response.success === true)
        {
            setReviews(response.data.reviews);
            setReviewsCount(response.data.reviews_count);
            setReviewsAvg(response.data.reviews_avg);
            setReviewsValue(response.data.reviews_values);
        }
        setIsLoading(false);
    }
    

    return(
        <Container>
            <CustomBreadcrumbs crumbs={[{name:`${t('reviews.title')}`,link:'/reviews'}]}/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={10} xl={10}>
                                <Typography variant="h4" component={'h1'} fontWeight={'bold'} gutterBottom>
                                    <ReviewsIcon fontSize="20px" color="link"/>
                                    &nbsp;{t('reviews.panel')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container spacing={3} mt={1}>
                            <Grid item lg={4} xl={4} display={'flex'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography fontWeight={'bold'}>{t('reviews.totalReviews')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h4" fontWeight={'bold'}>
                                            {reviewsCount}
                                        </Typography>
                                        <Typography variant="subtitle1">{t('reviews.totalReviewsYear')}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem/>
                            </Grid>
                            
                            <Grid item lg={4} xl={4} display={'flex'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography fontWeight={'bold'}>{t('reviews.averageRating')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h4" fontWeight={'bold'}>
                                            {reviewsAvg}
                                            &nbsp;
                                            <Rating precision={0.5} value={reviewsAvg}/>
                                        </Typography>
                                        <Typography variant="subtitle1">{t('reviews.averageRatingYear')}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem/>
                            </Grid>
                            
                            <Grid item lg={4} xl={4} display={'flex'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {
                                        reviewsValue.length !== 0 &&
                                        reviewsValue.map((item) => {
                                            const percentage = (item.value / reviewsCount) * 100;
                                            return (
                                            <Box key={item.label} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ minWidth: 35 }}>{item.label}</Box>
                                                <Box sx={{ width: '100%', mr: 1 }}>
                                                    <LinearProgress variant="determinate" value={percentage} />
                                                </Box>
                                                <Box sx={{ minWidth: 35 }}>
                                                    <Typography variant="caption">{item.value}</Typography>
                                                </Box>
                                            </Box>
                                            );
                                        })
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        reviews.length !== 0
                        ?<ReviewsDataTable reviews={reviews}/>
                        :null
                    }
                </Grid>
            </Grid>
            <QUDProgress open={isLoading}/>
        </Container>
    )
}