import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import SectionTitle from "../../../../components/sectionTitle";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { CreateSponsor, GetEvent } from "../../../../controllers/company/EventsController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import ResponseComponent from "../../../../components/responseComponent";

export default function SponsorAdd()
{
    const [event,setEvent] = useState([]);
    const [isLoadingData,setIsLoadingData] = useState(false);

    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');
    const [secondaryText,setSecondaryText] = useState('SI');

    const [rutaImg,setRutaImg] = useState('');
    const [img,setImg] = useState([]);

    const [link,setLink] = useState('');

    let { id } = useParams();

    let history = useHistory();
    const redireccionar = (ruta) => {
        history.push(ruta);
    }
    
    useEffect(()=>{
        handleGetEvent();
    },[]);

    const handleGetEvent = async () => {
        setIsLoadingData(true);
        let response = await GetEvent(id);
        if(response.success === true)
        {
            setEvent(response.data);

            //setRutaImg(imagesURL+'/eventos/'+ response.data.imagen);
            console.log(response);
        }
        setIsLoadingData(false)
    }

    const handleImagen = async (event) =>
    {
        await setImg(event.target.files[0]);
        await setRutaImg(URL.createObjectURL(event.target.files[0]));
    }

    const handleSubmit = async () => {
        setIsLoadingData(true);
        let response = await CreateSponsor(event.id_evento,img,link);
        if(response.success === true)
        {
            setTitle('Operación Exitosa');
            setMessage(response.message.message);
            setSuccess(true);
            setShow(true);
        }
        else if(response.success === false)
        {
            setTitle('Operación Fallida');
            setMessage(response.message.message);
            setSuccess(false);
            setShow(true);
        }
        else
        {
            setTitle('Operación Fallida');
            setMessage('En estos momentos no se puede procesar su solicitud. Intente nuevamente mas tarde.');
            setSuccess(false);
            setShow(true);
        }
        setIsLoadingData(false)
    }

    const clear = () => {
        setShow(false);
        setImg([]);
        setRutaImg('');
        setLink('');
    }

    const handleClose = () => {
        if(success === true)
            redireccionar('/events/sponsors/'+event.id_evento);
        else
            clear();
    }


    return(
        <Container>
            <ResponseComponent
                show={show}
                title={title}
                message={message}
                success={success}
                
                secondaryText={secondaryText}
                secondaryAction={()=>clear()}
                actionText={actionText}
                onClick={()=> handleClose()}
            />
            <CustomBreadcrumbs
                returnTo='/events'
                crumbs={[
                    {name:'Eventos',link:'/events'},
                    {name:event.nombre},
                    {name:'Patrocinadores',link:'/events/sponsors/'+event.id_evento},
                    {name:'Agregar'},
                ]}
            />
            <br/>
            <SectionTitle title={event.nombre} subtitle='(Agregar Patrocinador)'/>
            <br/>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item lg={6} xl={6} justifyContent={'center'}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} m={1} width={'100%'}>
                        {/**Imagen del Producto */}
                            <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <form encType="multipart/form-data">
                                    <input
                                        accept="image/*"
                                        style={{display: 'none',}}
                                        id="imagen"
                                        type="file"
                                        onChange={handleImagen}
                                    />
                                    <label htmlFor="imagen" style={{cursor:'pointer'}}>
                                            {
                                                rutaImg === ''
                                                ?
                                                <Box sx={{width:250,height:250}}>
                                                    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',border:'dotted',borderRadius:5,borderWidth:1,borderColor:'#000'}}>
                                                        <FontAwesomeIcon style={{fontSize:70}} icon={faImages} />
                                                        <Typography>Selecciona una imagen</Typography>
                                                        <Typography>(512x512 - 1024x1024)</Typography>
                                                        <Typography>px</Typography>
                                                    </div>
                                                </Box>
                                                :
                                                <Box sx={{width:250,height:250}}>
                                                <img src={rutaImg} style={{width:'100%',height:'100%'}} alt="Imagen de fondo" />
                                                </Box>
                                            }
                                    </label>
                                </form>
                                    
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Enlace" variant="outlined"
                                value={link}
                                onChange={(e)=>setLink(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                onClick={()=>handleSubmit()}
                            >
                                Agregar
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}