import CustomerRoutes from "../../apiRoutes/CustomerRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function getAll()
{
    let response = await GET(CustomerRoutes.Reservations.GetAll);
    return response;
}


export async function create(merchantID, date, time, people, observations)
{
    const params = [
        {name:'merchant_id',value:merchantID},
        {name:'date',value:date},
        {name:'time',value:time},
        {name:'people',value:people},
        {name:'observations',value:observations}
    ];

    const response = await POST(CustomerRoutes.Reservations.Create,params);
    return response;
}
