import React, { useEffect, useReducer, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Divider, Grid, MenuItem, Paper, Slide, Tab, TextField, Typography, useTheme } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useHistory } from "react-router-dom";
import SectionTitle from "../../../components/sectionTitle";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, DateTimePicker, DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

import GoogleMapReact from 'google-map-react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { getCategoriasEvento, getCiudades } from "../../../controllers/AuxController";
import { Add, GetEvent, Update, getDataToStore } from "../../../controllers/company/EventsController";
import ModalDialog from "../../../components/modalDialog";

import MapContainer from '../../../components/map';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTicket } from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Avatar, Dropzone, FileMosaic } from "@files-ui/react";
import AddTickets from "./add_ticket";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import MapGoogle from "../../../components/maps/MapGoogle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const initialState = {
    nombre : '',        errorNombre : false,
    dirigidoA:'',       errorDirigidoA: false,
    edad:'',            errorEdad: false,
    tipoEvento:'',      errorTipoEvento: false,
    asientos:'',        errorAsientos: false,
    fecha: new moment().format(),
    horaApertura: moment().format(),
    hora: moment().format(),
    error1: false,
    
    categoriaEvento:'', errorCategoriaEvento: false,
    descripcion : '',   errorDescripcion : false,
    imagen: [],         errorImagen: false,
    youtubeUrl:'',
    error2: false,

    ciudad:{},          errorCiudad: false,
    direccion:'',       errorDireccion: false,
    estatus:'',         errorEstatus: false,
    error3: false,
    
    rutaImagen: '',
    tickets:[],
    error4: false,

    coordenadas:{lat : 10.153135, lng : -64.678742},
    errorCoordenadas : false,
    //center : {lat: 10.1976900, lng: -64.6940700},
    initialCenter : {lat:10.153135,lng:-64.678742},
    center : {lat : 10.153135, lng : -64.678742},
    markerPosition : {lat:10.153135,lng:-64.678742},
    markerPositionInicial : {lat:10.153135,lng:-64.678742},
  
    zoom : 16,
      
    isLoading : false,
    open : false,
    result : false, message : '', title : '',

    ciudades: [],
    categorias: []
    
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}



export default function EventsUpdate()
{
    const theme = useTheme();
    const  currentDate = moment();
    let { id } = useParams();
    //const [value, setValue] = useState(moment().format());
    const [value, setValue] = useState(null);
    const [valueError,setValueError] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [descripcion,setDescripcion] = useState('');

    const [rutaImg,setRutaImg] = useState('');
    const [cover,setCover] = useState([])
    const [img,setImg] = useState([]);

    const [coordinates,setCoordinates] = useState({lat : 10.153135, lng : -64.678742})

    const [state,setState] = useReducer(reducer,initialState);

    const {
        nombre,errorNombre,
        dirigidoA, errorDirigidoA,
        edad,errorEdad,
        tipoEvento, errorTipoEvento,
        asientos,errorAsientos,
        fecha,horaApertura, hora,
        error1,

        errorDescripcion,
        
        
        categoriaEvento, errorCategoriaEvento,
        youtubeUrl,
        ciudad,errorCiudad,
        direccion,errorDireccion,
        estatus, errorEstatus,
        imagen, errorImagen, rutaImagen,
        tickets,
        isLoading, result,
        open, message, title, success,
        coordenadas,errorCoordenadas, initialCenter,center,
        markerPosition,markerPositionInicial,
        ciudades, categorias,

        error2, error3, error4
    } = state;

    useEffect(()=>{
        handleGetDataToStore();
       
        //handleChangeAccordion('panel1');
    },[]);

    const handleGetDataToStore = async () => {
        let response = await getDataToStore();
        if(response.success === true)
        {
            await setState({field:'ciudades',value:response.data.cities});
            await setState({field:'categorias',value:response.data.categories});
            handleGetEvent();
        }
    }

    const handleGetEvent = async () => {
        setState({field:'isLoading',value:true});
        let response = await GetEvent(id);
        if(response.success === true)
        {
            setState({field:'ciudades',value:response.data.cities});
            setState({field:'categorias',value:response.data.categories});

            setState({field:'nombre',value:response.data.event.nombre});
            setState({field:'dirigidoA',value:response.data.event.dirigido_a});
            setState({field:'edad',value:response.data.event.edad});
            setState({field:'asientos',value:response.data.event.asientos});
            setState({field:'fecha',value:response.data.event.fecha});
            let _hora_aperutura = moment(currentDate.format('YYYY-MM-DD')+' '+response.data.event.hora_apertura);
            let _hora_inicio = moment(currentDate.format('YYYY-MM-DD')+' '+response.data.event.hora);
            setState({field:'horaApertura',value:_hora_aperutura});
            setState({field:'hora',value:_hora_inicio});


            setDescripcion(response.data.event.descripcion);
            setState({field:'tipoEvento',value:response.data.event.tipo_evento});
            setImg(imagesURL+'/eventos/'+ response.data.event.imagen);
            setState({field:'youtubeUrl',value:response.data.event.youtube});
            
            setState({field:'categoriaEvento',value:response.data.event.id_categoria_e});
            
            let city = await response.data.cities.find(item=>item.idCiudad === response.data.event.id_ciudad);
            console.log('LA CIUDAD ES ===> ',city)
            setState({field:'ciudad',value:response.data.cities.find(item=>item.idCiudad === response.data.event.id_ciudad)});
            setState({field:'direccion',value:response.data.event.direccion});

            setState({
                field:'coordenadas',
                value:{
                    lat:parseFloat(parseFloat(response.data.event.latitud)),
                    lng:parseFloat(parseFloat(response.data.event.longitud))
                }
            });
        }
        setState({field:'isLoading',value:false});
    }


    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    const handleImagen = async (incomingFiles) =>
    {
        setCover(incomingFiles);
        setImg(incomingFiles);
        //await setImg(event.target.files[0]);
        //await setRutaImg(URL.createObjectURL(event.target.files[0]));
    }

    const onMapClick = (mapProps, map, clickEvent) => {
        setState({field:'coordenadas',value:{lat: parseFloat(clickEvent.latLng.lat()),lng:parseFloat(clickEvent.latLng.lng())}});
    }

    useEffect(()=>{
        if(value !== null)
        {
            setValue(value);
            setValueError(false);
            setState({field:'direccion',value:value.label})
            geocodeByPlaceId(value.value.place_id)
            .then(results => getLatLng(results[0]) )
            .then(({lat,lng}) => setCoordinates({lat,lng}) )
            .catch(error => console.error('ERROR ===> ',error))
        }
    },[value]);
    
    
    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }
    
    useEffect(()=>{
        if(ciudad !== null)
        {
            setState({
                field:'coordenadas',
                value:{
                    lat : parseFloat(ciudad?.latitud),
                    lng : parseFloat(ciudad?.longitud)
                }
            })
            setCoordinates({lat:parseFloat(ciudad?.latitud),lng:parseFloat(ciudad?.longitud)});
            setValue(null)
        }
        else
            setCoordinates({lat : 10.153135, lng : -64.678742});
    },[ciudad]);

    const handleChangeAccordion = (panel) => {
        if(expanded==='panel1')
        {
            let ban = formVerificationPanel1();
            if(ban === 1)
                return
        }
        if(expanded === 'panel2')
        {
            let ban = formVerificationPanel2();
            if(ban === 1)
                return;
        }
        if(expanded === 'panel3')
        {
            let ban = formVerificationPanel3();
            if(ban === 1)
                return;
        }
        //setExpanded(isExpanded ? panel : false);
        setExpanded(panel)
    };

    const formVerificationPanel1 = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }


        if(dirigidoA === '')
        {
            setState({field:'errorDirigidoA',value:true});
            ban = 1;
        }

        if(edad==='')
        {
            setState({field:'errorEdad',value:true});
            ban = 1;
        }
        if(tipoEvento==='')
        {
            setState({field:'errorTipoEvento',value:true});
            ban = 1;
        }
        if(asientos==='')
        {
            setState({field:'errorAsientos',value:true});
            ban = 1;
        }

        if(ban === 1)
            setState({field:'error1',value:true});
        else
            setState({field:'error1',value:false});

        return ban;
    }

    const formVerificationPanel2 = () => {
        var ban = 0;

        if(categoriaEvento === '')
        {
            setState({field:'errorCategoriaEvento',value:true});
            ban = 1;
        }


        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }

        if(ban === 1)
            setState({field:'error2',value:true});
        else
            setState({field:'error2',value:false});

        return ban;
    }

    const formVerificationPanel3 = () => {
        var ban = 0;

        if(ciudad === null)
        {
            setState({field:'errorCiudad',value:true});
            ban = 1;
        }


        if(direccion === '')
        {
            setState({field:'errorDireccion',value:true});
            ban = 1;
        }

        if(value === null)
        {
            setValueError(true);
            ban = 1;
        }

        if(ban === 1)
            setState({field:'error3',value:true});
        else
            setState({field:'error3',value:false});

        return ban;
    }

    const handleReview = (_tickets) => {
        setExpanded('panel5');
        if(_tickets.length !== 0)
            setState({field:'tickets',value:_tickets});
    }


    const formVerification = () => {
        var ban = 0;

        /*if(img === [])
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }*/

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }

        if(tipoEvento === '')
        {
            setState({field:'errorTipoEvento',value:true});
            ban = 1;
        }

        if(dirigidoA === '')
        {
            setState({field:'errorDirigidoA',value:true});
            ban = 1;
        }

        if(categoriaEvento === '')
        {
            setState({field:'errorCategoriaEvento',value:true});
            ban = 1;
        }

        if(ciudad === '')
        {
            setState({field:'errorCiudad',value:true});
            ban = 1;
        }

        if(direccion === '')
        {
            setState({field:'errorDireccion',value:true});
            ban = 1;
        }

        
        
        return ban;
    }

    const handleSubmit = async () => {
        //return console.log('IMG ===>>===> ',img);
        setState({field:'isLoading',value:true});
        
        /*let response = await Update(
            nombre,dirigidoA,edad,tipoEvento,asientos,
            moment(fecha).format('YYYY-MM-DD'),
            moment(horaApertura).format('HH:mm'),moment(hora).format('HH:mm'),
            categoriaEvento,descripcion,img,youtubeUrl,
            ciudad.idCiudad,direccion,coordenadas.lat,coordenadas.lng,tipoEvento,
            tickets
        );*/
        let response = await Update(
            nombre,dirigidoA,edad,tipoEvento,asientos,
            moment(fecha).format('YYYY-MM-DD'),
            moment(horaApertura).format('HH:mm'),moment(hora).format('HH:mm'),
            categoriaEvento,descripcion,img,youtubeUrl,
            ciudad.idCiudad,direccion,coordenadas.lat,coordenadas.lng,tipoEvento,
            id
        )

        if(response.success === true)
        {
            setState({field:'title',value:response.message.title});
            setState({field:'message',value:response.message.message});
            setState({field:'success',value:true});
            setState({field:'open',value:true});
        }

        setState({field:'isLoading',value:false});
    }


    const StepPanelControl = (props) =>
    {
        return(
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row-reverse'}>
                <Button variant="contained" sx={{ml:2}} onClick={()=>handleChangeAccordion(props.nextPanel)}>
                    <Typography>Siguiente</Typography>
                </Button>
                <Button variant='contained' sx={{bgcolor:'#e6e6e6'}} color="cancel" onClick={()=>setExpanded(props.prevPanel)}>
                    <Typography>Anterior</Typography>
                </Button>
            </Grid>
        )
    }

    const handleDone = () => {
        if(success === true)
            redirect('/events');
        else
            setState({field:'open',value:false});
    }

    const [isGoogleLoaded, setGoogleLoaded] = useState(false);
    useEffect(() => {
        const checkGoogleMaps = setInterval(() => {
          if (window.google) {
            setGoogleLoaded(true);
            clearInterval(checkGoogleMaps);
          }
        }, 1000);
    }, []);

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/events' crumbs={[{name:'Eventos',link:'/events'}]}/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xl={12}>
                                <Typography variant="h6" component={'h1'} gutterBottom>
                                    <FontAwesomeIcon icon={faTicket} color={theme.palette.link.main} />
                                    &nbsp;Editar evento
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                        {/**Informacion del evento */}
                        <Accordion elevation={4} expanded={expanded === 'panel1'} onChange={()=>handleChangeAccordion('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }} variant='h6' fontWeight={'bold'}>
                                    Información del evento
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item lg={12}>
                                        <Typography variant="h6">Cuéntanos acerca de tu evento.</Typography>
                                    </Grid>

                                    {/**Nombre */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            label='Nombre del Evento'
                                            name='nombre'
                                            id='nombre'
                                            required
                                            value={nombre}
                                            onChange = { onChange }
                                            onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                            error = {errorNombre}
                                            helperText={<Typography>{errorNombre ? 'Ingrese el nombre del evento' : 'Este es el nombre de tu evento. Será mostrado en la página del evento y en los resultados de búsqueda.'}</Typography>}
                                        />
                                    </Grid>

                                    {/**Dirigido A */}
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            id="dirigidoA"
                                            name='dirigidoA'
                                            label="Dirigido a"
                                            variant="outlined"
                                            value = {dirigidoA}
                                            onChange = { onChange }
                                            onBlur={ () => (dirigidoA === '' ? setState({field:'errorDirigidoA',value:true}) : setState({field:'errorDirigidoA',value:false}) ) }
                                            error = {errorDirigidoA}
                                            helperText={<Typography>{errorDirigidoA && 'Indica a quien esta dirigido el evento'}</Typography>}
                                        >
                                            <MenuItem value=''>Selecciona una opción</MenuItem>
                                            <MenuItem value='0'>Personas</MenuItem>
                                            <MenuItem value='1'>Comercios</MenuItem>
                                        </TextField>
                                    </Grid>

                                    {/**Edad */}
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            id="edad"
                                            name='edad'
                                            label="Restricciones de edad"
                                            variant="outlined"
                                            value = {edad}
                                            onChange = { onChange }
                                            onBlur={ () => (edad === '' ? setState({field:'errorTipoEvento',value:true}) : setState({field:'errorTipoEvento',value:false}) ) }
                                            error = {errorTipoEvento}
                                            helperText={<Typography>{errorTipoEvento && 'Selecciona la restricción de edad'}</Typography>}
                                        >
                                            <MenuItem value=''>Selecciona una restricción de edad</MenuItem>
                                            <MenuItem value='0'>Todas las edades</MenuItem>
                                            <MenuItem value='1'>Menores de 18 acompañados</MenuItem>
                                            <MenuItem value='2'>18+</MenuItem>
                                            <MenuItem value='5'>21+</MenuItem>
                                            <MenuItem value='3'>Otro</MenuItem>
                                        </TextField>
                                    </Grid>

                                    {/**Tipo de evento */}
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            id="tipoEvento"
                                            name='tipoEvento'
                                            label="Tipo de Entrada"
                                            variant="outlined"
                                            value = {tipoEvento}
                                            onChange = { onChange }
                                            onBlur={ () => (tipoEvento === '' ? setState({field:'errorTipoEvento',value:true}) : setState({field:'errorTipoEvento',value:false}) ) }
                                            error = {errorTipoEvento}
                                            helperText={<Typography>{errorTipoEvento && 'Seleccione el tipo de entrada'}</Typography>}
                                        >
                                            <MenuItem value=''>Seleccione el tipo de entrada</MenuItem>
                                            <MenuItem value='0'>Entrada Libre</MenuItem>
                                            <MenuItem value='1'>Entrada con Boleto</MenuItem>
                                        </TextField>
                                    </Grid>

                                    {/**Asientos */}
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            id="asientos"
                                            name='asientos'
                                            label="¿Hay asientos disponibles?"
                                            variant="outlined"
                                            value = {asientos}
                                            onChange = { onChange }
                                            onBlur={ () => (asientos === '' ? setState({field:'errorAsientos',value:true}) : setState({field:'errorAsientos',value:false}) ) }
                                            error = {errorAsientos}
                                            helperText={<Typography>{errorAsientos && 'Selecciona una opción'}</Typography>}
                                        >
                                            <MenuItem value=''>Selecciona una opción</MenuItem>
                                            <MenuItem value='0'>No, solo de pie</MenuItem>
                                            <MenuItem value='1'>Sí, los asientos son por orden de llegada</MenuItem>
                                            <MenuItem value='2'>Sí, asientos disponibles con compra VIP</MenuItem>
                                            <MenuItem value='3'>Otro</MenuItem>
                                        </TextField>
                                    </Grid>

                                    {/**Fecha */}
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label="Fecha del evento"
                                                value={fecha}
                                                onChange={(newValue) => setState({field:'fecha',value: newValue }) }
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    {/**Hora de Apertura */}
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <TimePicker
                                                label="Hora de apertura"
                                                value={horaApertura}
                                                onChange={(newValue) => setState({field:'horaApertura',value:newValue})}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    {/**Hora de Inicio */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <TimePicker
                                                label="Hora de inicio"
                                                value={hora}
                                                onChange={(newValue) => { setState({field:'hora',value:newValue}); console.log('newValue ===> ',newValue) }}
                                                renderInput={(params) => <TextField fullWidth helperText={<Typography>Ingresa tu horario de inicio establecido. Así, los asistentes tendrán una idea de cuándo llegar y estar presentes para el espectáculo.</Typography>} {...params} />}
                                                
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    {
                                        error1 &&
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography color={'error.main'}>
                                                LLena todos los campos obligatorios para que puedas continuar
                                            </Typography>
                                        </Grid>
                                    }
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row-reverse'}>
                                        <Button variant="contained" onClick={()=>handleChangeAccordion('panel2')}>
                                            <Typography>Siguiente</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>


                        {/**Marketing */}
                        <Accordion elevation={4} expanded={expanded === 'panel2'} onChange={()=>handleChangeAccordion('panel2')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                 <Typography sx={{ width: '33%', flexShrink: 0 }} variant='h6' fontWeight={'bold'} component={'h1'}>
                                    Marketing
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6" component={'h2'} fontWeight={'bold'}>
                                            ¡Destaca tu evento y atrae a más asistentes!
                                        </Typography>
                                        <Typography variant="h6" component={'p'}>
                                            Completa la información de marketing de tu evento para que sea más fácil de encontrar y destacar entre la multitud.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                                        <Grid container spacing={3} pb={5}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="categoriaEvento"
                                                    name='categoriaEvento'
                                                    label="Categoría del Evento"
                                                    variant="outlined"
                                                    value = {categoriaEvento}
                                                    onChange = { onChange }
                                                    onBlur={ () => (categoriaEvento === '' ? setState({field:'errorCategoriaEvento',value:true}) : setState({field:'errorCategoriaEvento',value:false}) ) }
                                                    error = {errorCategoriaEvento}
                                                    helperText={errorCategoriaEvento && 'Seleccione la categoria del evento'}
                                                >
                                                    <MenuItem value=''>Seleccione la categoría del evento</MenuItem>
                                                    {
                                                        categorias.lenght !== 0 &&
                                                        categorias.map((item)=>
                                                            <MenuItem value={item.id_categoria_evento}>{item.nombre}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography fontWeight={'bold'}>Descripción</Typography>
                                                <Typography fontStyle={'italic'}>Ingresa detalles adicionales del evento</Typography>
                                                <ReactQuill
                                                    theme="snow"
                                                    placeholder="Incluye información detallada de tu evento, por ejemplo del artista, agenda y cualquier otra información importante para generar una mayor expectativa entre los asistentes. Adicionalmente, trata de incluir palabras clave que hagan que tu evento sea más fácil de buscar y encontrar."
                                                    id="descripcion"
                                                    value={descripcion}
                                                    onChange={setDescripcion}
                                                    onBlur={()=>setState({field:'errorDescripcion',value:descripcion===''?true:false})}
                                                    style={{height:300}}
                                                />
                                                {
                                                    errorDescripcion &&
                                                    <Typography color={'error.main'}>Ingresa la descripción del evento</Typography>
                                                }
                                            </Grid>

                                            
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} xl={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography>Imagen del evento</Typography>
                                                
                                                <Avatar
                                                    //style={{width:256,height:256}}
                                                    src={img}
                                                    
                                                    alt="Avatar"
                                                    onChange={handleImagen}
                                                    //value={imageProfile}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    label='YouTube video url (opcional)'
                                                    name='youtubeUrl'
                                                    id='youtubeUrl'
                                                    value={youtubeUrl}
                                                    onChange = { onChange }
                                                    helperText={<Typography>Un video publicitario es una herramienta poderosa para captar la atención de los asistentes potenciales y generar interés en el evento</Typography>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <StepPanelControl prevPanel='panel1' nextPanel='panel3'/>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        
                        {/**Ubicacion */}
                        <Accordion elevation={4} expanded={expanded === 'panel3'} onChange={()=>handleChangeAccordion('panel4')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }} variant='h6' fontWeight={'bold'} component={'h1'}>
                                    Ubicación
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            Para una mejor experiencia de los asistentes, proporciona la dirección completa del lugar del evento.
                                        </Typography>
                                    </Grid>

                                    {/**Ciudad + Direccion */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="ciudad"
                                                    name='ciudad'
                                                    label="Ciudad"
                                                    variant="outlined"
                                                    value = {ciudad}
                                                    onChange = { onChange }
                                                    onBlur={ () => (ciudad === '' ? setState({field:'errorCiudad',value:true}) : setState({field:'errorCiudad',value:false}) ) }
                                                    error = {errorCiudad}
                                                    helperText={<Typography>{errorCiudad && 'Seleccione la ciudad'}</Typography>}
                                                >
                                                    <MenuItem value={null}>Seleccione la ciudad</MenuItem>
                                                    {
                                                        ciudades.lenght !== 0 &&
                                                        ciudades.map((item,index)=>
                                                            <MenuItem key={index} value={item}>{item.nombre}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                                </Grid>
                                                {
                                                    isGoogleLoaded &&
                                                    <>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <Typography>Dirección del Evento</Typography>
                                                            <GooglePlacesAutocomplete
                                                                
                                                                apiKey="AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE"
                                                                selectProps={{
                                                                    value,
                                                                    onChange: setValue //onChangeGoogleComplete //
                                                                }}
                                                                onLoadFailed={(error)=>console.log('ERROR ===> ',error)}
                                                            /> 
                                                            {
                                                                valueError &&
                                                                <Typography color={'error.main'}>Ingresa la dirección del evento</Typography>
                                                            }
                                                        </Grid>
                                                    </>
                                                }
                                            

                                            {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    label='Dirección del Evento'
                                                    name='direccion'
                                                    id='direccion'
                                                    required
                                                    multiline
                                                    rows={6}
                                                    value={direccion}
                                                    onChange = { onChange }
                                                    onBlur={ () => (direccion === '' ? setState({field:'errorDireccion',value:true}) : setState({field:'errorDireccion',value:false}) ) }
                                                    error = {errorDireccion}
                                                    helperText={<Typography>{errorDireccion && 'Ingrese la dirección del evento'}</Typography>}
                                                />
                                            </Grid>*/}
                                        </Grid>
                                    </Grid>

                                    {/**Mapa de ubicacion */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box 
                                            sx={{
                                                width:'100%',
                                                height:250,
                                                borderRadius:10,
                                            }}
                                        >
                                            {
                                                (coordinates.lat && coordinates.lng) &&
                                                <MapGoogle
                                                center={coordinates}
                                                coordinates={coordinates}
                                            />
                                            }
                                            
                                        </Box>
                                    </Grid>

                                    <StepPanelControl prevPanel='panel2' nextPanel='panel4'/>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        

                        {/**Review */}
                        <Accordion elevation={4} expanded={expanded === 'panel4'} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }} variant='h6' fontWeight={'bold'} component={'h1'}>
                                    Revisar
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            Revisa la información de tu evento antes de crearlo.
                                        </Typography>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">Información del evento</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography>Nombre del evento</Typography>
                                        <Typography fontWeight={'bold'}>{nombre}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography>Dirección</Typography>
                                        <Typography fontWeight={'bold'}>{direccion}, {ciudad?.nombre}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Typography>Fecha del evento</Typography>
                                        <Typography fontWeight={'bold'}>{moment(fecha).format('DD/MM/YYYY')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Typography>Hora de apertura</Typography>
                                        <Typography fontWeight={'bold'}>{moment(horaApertura).format('hh:mm a')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Typography>Hora de inicio</Typography>
                                        <Typography fontWeight={'bold'}>{moment(hora).format('hh:mm a')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}></Grid>

                                    
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
                                        <Divider/>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row-reverse'}>
                                        <Button variant="contained" sx={{ml:2}} onClick={()=>handleSubmit()}>
                                            <Typography>Guardar y publicar evento</Typography>
                                        </Button>
                                        <Button variant='text' color="primary" onClick={()=> setExpanded('panel1')}>
                                            <FontAwesomeIcon icon={faPenToSquare}/>&nbsp;
                                            <Typography>Hacer cambios</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            </Grid>

            <QUDProgress
                open={isLoading}
            />
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>handleDone()}
            />
        </Container>
    )
}