import { useEffect, useState } from "react";
import qz, { printers } from 'qz-tray';
import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';
import { printWithQZTrayFunction } from "../controllers/PrinterController";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function PrintWithQZTray({ merchant, event, tickets }){
    const [printers,setPrinters] = useState([]);
    const [selectedPrinter, setSelectedPrinter] = useState('');
    const [isConnected, setIsConnected] = useState(false);


    useEffect(() => {
        // Función para verificar conexión de QZ
        const checkConnection = async () => {
            setIsConnected(qz.websocket.isActive());
        };
        checkConnection();
    }, []);

    const handleConnect = async () => {
        if (!isConnected) {
            try {
                await qz.websocket.connect();
                setIsConnected(true);
                const availablePrinters = await qz.printers.find();
                setPrinters(availablePrinters);
            } catch (error) {
                console.error("Error al conectar con QZ Tray:", error);
            }
        } else {
            // Si ya está conectado, obtenemos las impresoras disponibles
            const availablePrinters = await qz.printers.find();
            setPrinters(availablePrinters);
        }
    };


    const handleSelectedPrinter = async (value) => {
        setSelectedPrinter(value)
        await qz.websocket.disconnect();
    }



    const handlePrint = async () => {
        if(!selectedPrinter){
            alert("Por favor, selecciona una impresora.");
            return;
        }

        printWithQZTrayFunction(merchant,event,tickets,selectedPrinter);

    }

    return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
            {/** */
                isConnected === true
                ?
                <>
                    <Typography variant="h5" gutterBottom>
                        Selecciona una impresora para imprimir
                    </Typography>

                    <FormControl variant="outlined" sx={{ minWidth: 240, mb: 3 }}>
                        <InputLabel>Impresora</InputLabel>
                        <Select
                            value={selectedPrinter}
                            onChange={(e) => handleSelectedPrinter(e.target.value)}
                            label="Impresora"
                        >
                            <MenuItem value="">
                                <em>Selecciona una impresora</em>
                            </MenuItem>
                            {printers.map((printer, index) => (
                                <MenuItem key={index} value={printer}>
                                    {printer}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handlePrint} 
                        sx={{ minWidth: 240 }}
                    >
                        Imprimir
                    </Button>
                </>

                :
                <Button variant="contained" color="primary" onClick={handleConnect} disabled={isConnected}>
                    Imprimir desde W10 (USB)
                </Button>
            }
        
    </Box>
    );

}