import { Box, Button, Grid, Typography } from "@mui/material";
import { height } from "@mui/system";
import { imagesURL } from "../apiRoutes/apiBase";
import { faInstagram, faReact, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEarth } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SocialComponent(props)
{
    const tam = 45;

    const handleSetIcon = (social) => {
        if(social === 'Instagram')
            return faInstagram
        else if(social === 'WhatsApp')
            return faWhatsapp
        else if(social === 'Website')
            return faEarth
        else if(social === 'Twitter')
            return faTwitter
        else
            return faReact
    }

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <Button
                variant="text"
                startIcon={<FontAwesomeIcon icon={handleSetIcon(props.social)}/>}
                sx={{color:'primary.contrastText',textTransform:'none',ml:-0.5}}
                href={props.link} target="_blank"
            >
                <Typography fontWeight={'bold'}>{props.username}</Typography>
            </Button>
            
        </Grid>
        /*<Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
            <Box
                sx={{
                    width:tam,
                    height:tam,
                    borderRadius:10,
                    '&:hover':{cursor:'pointer'}
                }}
                component='a'
                href={props.link}
                target='_blank'
            >
                <img style={{width:tam,height:tam,borderRadius:10}} src={imagesURL+'redesSociales/'+props.src} alt={props.alt} />
            </Box>
        </Grid>*/
    )
}