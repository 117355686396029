import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";

export async function GetAll()
{
    let response = await GET(CompanyRoutes.Associates.GetAll);
    return response;
}

export async function Add(email,position)
{
    const params = [
         {name:'email',value:email},
         {name:'position',value:position}
    ];

    let response = await POST(CompanyRoutes.Associates.Add,params);
    return response;
}


export async function GetByUUID(uuid)
{
    let response = await GET(CompanyRoutes.Associates.GetByUUID+uuid);
    return response;
}