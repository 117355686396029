import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { GetAllCoupons } from "../../../controllers/customer/CouponsController";
import CouponsDataTable from "./DataTable";
import QUDProgress from "../../../components/progress";

export default function ClientCoupons()
{
    const [coupons,setCoupons] = useState([]);

    const [isLoading, setIsLoading]  = useState(false);

    useEffect(()=>{
        handleGetCoupons();
    },[]);

    const handleGetCoupons = async () => {
        setIsLoading(true);
        let response = await GetAllCoupons();
        console.log(response.data.coupons[0])
        if(response.success === true)
        {
            setCoupons(response.data.coupons)
        }
        setIsLoading(false);
    }

    return(
        <Container sx={{pt:10}}>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Cupones',link:'/coupons'}]}/>
            <br/>
            <SectionTitle title={'Cupones'}/>
            {
                coupons.length !== 0
                ?<CouponsDataTable coupons={coupons}/>
                :<Typography variant="body1" textAlign={'center'}>No se encontraron resultados</Typography>
            }
            <QUDProgress open={isLoading}/>
        </Container>
    )
}