import { useEffect, useState } from "react";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { getDataToObtainAFreeCoupon } from "../../../controllers/customer/CouponsController";
import { useParams } from "react-router-dom";

export default function FreeCoupon()
{
    const { uuid } = useParams();
    const [coupon,setCoupon] = useState([]);

    const [name,setName] = useState('');
    const [lastName,setLasName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');

    useEffect(()=>{
        handleGetPreloadedCoupon();
    },[]);

    const handleGetPreloadedCoupon = async () => {
        
        let response = await getDataToObtainAFreeCoupon(uuid);
        if(response.success === true)
        {
            setCoupon(response.data);
        }
    }

    return(
        <Container>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                        {coupon.merchant_name}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={5}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Nombre'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Apellido'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Telefono'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Correo'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Button
                                fullWidth
                                variant="contained"
                            >
                                <Typography variant="h6">Obtener cupón</Typography>
                            </Button>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}