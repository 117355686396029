import { useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Container, Grid, IconButton, Link, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { GetData } from "../../../controllers/SmartCardsController";
import { imagesURL } from "../../../apiRoutes/apiBase";
import SocialComponent from "../../../components/socialComponent";
import Carousel from "../../../components/carousel";
import Horarios from "../../../components/horarios";

import MapContainer from '../../../components/map';
import ProfileImage from "../../../components/profileImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCalendar, faCalendarAlt, faCircleCheck, faClock, faDiamondTurnRight, faEnvelope, faLocation, faMapLocation, faMapLocationDot, faMapMarkerAlt, faMapSigns, faPhone, faPhoneAlt, faStar, faTags, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";



import { motion } from "framer-motion"
import ProductsCarousel from "../../../components/carousel";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import QUDProgress from "../../../components/progress";
import Review from "../../../components/review";
import Reserve from "../../../components/reserve";

import { useTranslation } from "react-i18next";


function ActionButtonQUD(props){
    
    return(
        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
            <Button
                fullWidth
                sx={{padding:0,color:'#212121'}}
                onClick={props.onClick}
                
                target={props.target}
                href={props.href}
            >
                <Box
                    sx={{
                        display:'flex',flexDirection:'column',alignItems:'center',
                        '&:hover':{cursor:'pointer'},
                        textTransform:'none',
                        width:'100%'
                    }}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            width:'100%',
                            border:'solid',
                            borderColor:'primary.main',
                            color:'#212121',
                            borderWidth:{xs:1,sm:1,md:1,lg:0.5,xl:0.5},
                            display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',
                            '&:hover':{borderColor:'secondary.light'}
                        }}
                    >
                        <FontAwesomeIcon style={{fontSize:28}} icon={props.icon}/>
                        <Typography color={'#212121'} fontWeight={'bold'} variant={'body1'}>{props.action}</Typography>
                    </Paper>
                </Box>
            </Button>
        </Grid>
    )
}


export default function MerchantProfile()
{
    const {t, i18n} = useTranslation();
    const [user,setUser] = useState([]);
    const [socials,setSocials] = useState([]);
    const [products,setProducts] = useState([]);
    const [workSchedueles,setWorkSchedules] = useState([]);

    const [openReview,setOpenReview] = useState(false);
    const [openReserve,setOpenReserve] = useState(false);

    const [message,setMessage] = useState(undefined);
    const [isLoading,setIsLoading] = useState(false);

    const theme = useTheme();

    let { uuid } = useParams();
    
    const history = useHistory();
    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        handleGetData();
        handleReviewLocalStorage();
        handleReserveLocalStorage();
    },[]);


    const handleGetData = async () => {
        setIsLoading(true);
        let response = await GetData(uuid);
        if(response.success === true)
        {
            console.log('Los Datos obtenidos son => ',response.data);
            setUser(response.data.user);
            setSocials(response.data.redes);
            setProducts(response.data.productos);
            setWorkSchedules(response.data.horarios);
        }
        else
        {
            setMessage(response.message)
        }
        setIsLoading(false);
    }

    const handleReviewLocalStorage = () => {
        if(localStorage.getItem('openModalReview') === 'true')
            setOpenReview(true)
    }

    const handleCloseReview = () => {
        localStorage.removeItem('openModalReview')
        setOpenReview(false)
    }

    const handleReserveLocalStorage = () => {
        if(localStorage.getItem('openModalReserve') === 'true')
            setOpenReserve(true)
    }

    const handleCloseReserve = () => {
        localStorage.removeItem('openModalReserve')
        setOpenReserve(false)
    }


    const settings = {
        itemsToShow:4,
        dots: true,
        infinite: true,
        //enableAutoPlay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: false,
        breakPoints :[
            { width: 1, itemsToShow: 2 },
            { width: 550, itemsToShow: 3, itemsToScroll: 3 },
            { width: 850, itemsToShow: 4 },
            { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
            { width: 1450, itemsToShow: 5 },
            { width: 1750, itemsToShow: 6 },
        ]
        
    };

        
    return(
        
        <Box sx={{ overflowX:'hidden',pb:5,pt:14}}>
            <QUDProgress open={isLoading}/>

            {/**Movil */}
            <Container sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IconButton onClick={()=>{redirect('/search/'+user.categoria)}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                        </IconButton>
                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Avatar
                                variant="rounded"
                                alt={user.apellido}
                                src={ imagesURL+'empresas/'+user.imagen2}
                                sx={{ width: 120, height: 120 }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h5" textAlign={'center'} fontWeight={'bold'}>
                            {user.apellido}&nbsp;
                            {user.certificado === 1 && <FontAwesomeIcon size="2xs" color={theme.palette.link.main} icon={faCircleCheck}/>}
                        </Typography>
                        <Typography textAlign={'center'}>({user.categoria})</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} display={'flex'} flexDirection={'row'} sx={{overflowX:'scroll'}}>
                        <Button
                            sx={{display:'flex',flexDirection:'column',mr:1}}
                            href={`tel:${user.telefono}`}
                        >
                            <FontAwesomeIcon size="2x" color={theme.palette.link.main} icon={faPhone}/>
                            <Typography variant="body1">{t('actions.call')}</Typography>
                        </Button>

                        <Button
                            sx={{display:'flex',flexDirection:'column',mr:1}}
                            target='_blank'
                            href={`https://wa.me/${user.telefono}`}
                        >
                            <FontAwesomeIcon size="2x" color={theme.palette.link.main} icon={faWhatsapp}/>
                            <Typography variant="body1">{t('actions.contact')}</Typography>
                        </Button>

                        <Button
                            sx={{display:'flex',flexDirection:'column',mr:1}}
                            target="_blank"
                            href={'http://maps.google.com/maps?daddr='+parseFloat(user.latitud)+','+parseFloat(user.longitud)}
                        >
                            <FontAwesomeIcon size="2x" color={theme.palette.link.main} icon={faDiamondTurnRight}/>
                            <Typography variant="body1">{t('actions.directions2')}</Typography>
                        </Button>
                        
                        <Button
                            sx={{display:'flex',flexDirection:'column',mr:1}}
                            onClick={()=>setOpenReserve(true)}
                        >
                            <FontAwesomeIcon size="2x" color={theme.palette.link.main} icon={faCalendarAlt}/>
                            <Typography variant="body1">{t('actions.book')}</Typography>
                        </Button>

                        <Button
                            sx={{display:'flex',flexDirection:'column',mr:1}}
                            onClick={()=>setOpenReview(true)}
                        >
                            <FontAwesomeIcon size="2x" color={theme.palette.link.main} icon={faStar}/>
                            <Typography variant="body1">{t('actions.review')}</Typography>
                        </Button>
                        
                        <Button
                            sx={{display:'flex',flexDirection:'column',mr:1}}
                            onClick={()=>redirect('/merchants/'+user.uuid+'/menu')}
                        >
                            <FontAwesomeIcon size="2x" color={theme.palette.link.main} icon={faTags}/>
                            <Typography variant="body1">{t('actions.catalog')}</Typography>
                        </Button>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography><FontAwesomeIcon icon={faMapLocation}/> {user.ciudad}, {user.pais}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography><FontAwesomeIcon icon={faStar}/> {parseFloat(5).toFixed(1)}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6">{user.descripcion}</Typography>
                    </Grid>
                    
                </Grid>
            </Container>

            {/**Imagen de portada + ficha comercial */}
            <Container
                maxWidth='xl'
                sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection:'column',
                        minHeight:{xs:'38vh',sm:'40vh',md:'55vh',lg:'90vh',xl:'85vh'},
                        justifyContent: 'center',
                        bgcolor: 'background.default',
                        color: 'text.primary',
                        p: {xs:1.5,sm:2,md:3,lg:3,xl:3},
                        backgroundImage:"url(" + imagesURL+'empresas/'+user.imagen + ")",
                        borderRadius:5,
                        backgroundPosition:'center',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'cover',
                        position:'relative',
                        marginBottom:1,
                    }}
                >
                    
                    <Grid container spacing={3} sx={{flex:'auto'}}>
                        <Grid item lg={4} sx={{flex:'auto'}}>
                            <motion.div
                                initial={{ opacity: 0,translateX:200 }}
                                whileInView={{ opacity: 1,translateX:0 }}
                                viewport={{ once: false }}
                                style={{height:'100%'}}
                                transition={{ delay: 0.7 }}
                            >
                                <Paper sx={{height:'100%'}}>
                                    <Grid container spacing={1} display={'flex'}>
                                        
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <IconButton onClick={()=>{redirect('/search/'+user.categoria)}}>
                                                <FontAwesomeIcon icon={faArrowLeft}/>
                                            </IconButton>
                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                                <Avatar
                                                    variant="rounded"
                                                    alt={user.apellido}
                                                    src={ imagesURL+'empresas/'+user.imagen2}
                                                    sx={{ width: 100, height: 100 }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h4" fontWeight={'bold'}>
                                                {user.apellido}&nbsp;
                                                {user.certificado === 1 && <FontAwesomeIcon size="2xs" color={theme.palette.link.main} icon={faCircleCheck}/>}
                                            </Typography>
                                            <Typography>({user.categoria})</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography>
                                                {user.descripcion}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                                            <Typography><FontAwesomeIcon icon={faMapLocation}/> {user.ciudad}, {user.pais}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography><FontAwesomeIcon icon={faStar}/> {parseFloat(5).toFixed(1)}</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        startIcon={<FontAwesomeIcon icon={faWhatsapp}/>}
                                                        target='_blank'
                                                        //href={"tel:"+user.telefono}
                                                        href={`https://wa.me/${user.telefono}`}
                                                    >
                                                        {t('actions.contact')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        startIcon={<FontAwesomeIcon
                                                        icon={faMapLocation}/>}
                                                        target="_blank"
                                                        href={'http://maps.google.com/maps?daddr='+parseFloat(user.latitud)+','+parseFloat(user.longitud)}
                                                    >
                                                        {t('actions.directions')}
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        startIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                                                        onClick={()=>setOpenReserve(true)}
                                                    >
                                                        {t('actions.book')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        startIcon={<FontAwesomeIcon icon={faStar}/>}
                                                        onClick={()=>setOpenReview(true)}
                                                    >
                                                        {t('actions.review')}
                                                    </Button>
                                                </Grid>
                                                
                                                {
                                                    products.length !== 0 &&
                                                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                        <Button
                                                            fullWidth
                                                            variant="outlined"
                                                            startIcon={<FontAwesomeIcon icon={faTags}/>}
                                                            onClick={()=>redirect('/merchants/'+user.uuid+'/menu')}
                                                        >
                                                            {t('actions.catalog')}
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            
            {/**Productos destacados */}
            <Container sx={{mb:5,marginTop:5}}>
                <Grid container spacing={3} justifyContent='center'>
                    {/**Products */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                        {
                            products.length !== 0 &&
                            <ProductsCarousel items={products} merchant_uuid={user.uuid}/>
                        }
                    </Grid>
                </Grid>
            </Container>

            
            {/**CONTACTANOS */}
            <Box 
                sx={{
                    //position:'relative',
                    height:280,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    bgcolor:'primary.main',
                    //zIndex:1
                }}
            >
                <Typography variant="h4" color={'#FFF'}>
                    {user.apellido}
                </Typography>
            </Box>
            
            <Container sx={{mt:-10}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Paper elevation={4} sx={{padding:0}}>
                            <Grid container spacing={3}>
                                {/**Horarios */}
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                                    <Grid container spacing={3}>
                                        <Horarios
                                            horarios={workSchedueles}
                                        />
                                    </Grid>
                                </Grid>

                                {/**Datos de contacto */}
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{bgcolor:'primary.main',borderBottomRightRadius:10,borderTopRightRadius:10,position:'relative'}}>
                                    <Box sx={{pl:4,pb:3,pr:4,color:'primary.contrastText',position:'relative',zIndex:100}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant="h5" fontWeight={'bold'}>{t('helpers.contactInformation')}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography fontWeight={'bold'}>
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp;&nbsp;
                                                    {user.direccion}
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography fontWeight={'bold'}><FontAwesomeIcon icon={faPhone} />&nbsp;{user.telefono}</Typography>
                                            </Grid>
                                            
                                            {
                                                socials.length !== 0 &&
                                                socials.map(item =>
                                                    <SocialComponent
                                                        //src={item.img}
                                                        //alt={item.nombre}
                                                        social={item.nombre}
                                                        username={item.username}
                                                        link={item.enlace}
                                                        color='#FFF'
                                                    /> 
                                                )
                                            }
                                        </Grid>
                                        
                                    </Box>
                                    <Box
                                        sx={{
                                            width:'100%',height:'100%',
                                            display:'flex',
                                            //justifyContent:'center',
                                            alignItems:'center',
                                            backgroundImage: "url(" + imagesURL+'empresas/'+user.imagen2 + ")",
                                            backgroundPosition:'center',
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'cover',
                                            opacity:0.1,
                                            position:'absolute',
                                            bottom:0, left:0,top:0,
                                            borderTopRightRadius:10,
                                            borderBottomRightRadius:10
                                        }}
                                    ></Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Grid container spacing={3} mt={5}>
                    {
                        user.length !== 0 &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                           
                                <Box
                                    sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}
                                >
                                    <Typography variant="h4" sx={{fontWeight:'bold'}}>{t('helpers.location')}</Typography>
                                    <Button size="small" variant='text' target="_blank" href={'http://maps.google.com/maps?daddr='+parseFloat(user.latitud)+','+parseFloat(user.longitud)} >
                                        <Typography color='#0076B6' variant='caption' >
                                            {t('actions.directions')}
                                        </Typography>
                                    </Button>

                                </Box>
                                <Typography variant="h5" component='p' color={'#454545'} mb={3}>{user.direccion}</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <MapContainer
                                            center={{lat:user.latitud, lng:user.longitud}}
                                            coordenadas={{lat:user.latitud, lng:user.longitud}}
                                        />
                                    </Grid>
                                </Grid>
                        </Grid>
                    }
                </Grid>
            </Container>

            <Review
                type='merchant'
                toUUID={user.uuid}
                name={user.apellido}
                image={imagesURL+'empresas/'+user.imagen2}
                open={openReview}
                handleClose={()=>handleCloseReview()}
            />
            <Reserve
                type='merchant'
                toUUID={user.uuid}
                name={user.apellido}
                image={imagesURL+'empresas/'+user.imagen2}
                open={openReserve}
                handleClose={()=>handleCloseReserve()}
            />
        </Box>
    )
}