import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';



import { useHistory } from 'react-router-dom';


import { useTheme } from '@mui/material';



import "./homeStyles.css";
import Categories from './Categories';



import Carousel from 'react-elastic-carousel'
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faStore } from '@fortawesome/free-solid-svg-icons';

import logo from '../../assets/images/logos/qud4.png'
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const StyledCarousel = styled(Carousel)`
    
    .rec-dot_active{
        background-color: #bfebff;
        box-shadow: 0 0 1px 3px rgba(20, 122, 194,1);
    }

    .rec-arrow{
        background-color: #e3f6ff
    }

    .rec-arrow:hover {
        background-color: #147ac2
    }

    .rec-arrow:focus{
        background-color: #147ac2
    }
`;


export default function Home(props)
{
    const history = useHistory();
    const theme = useTheme();

    useEffect(()=>{
        redirect('/eventos')
    },[]);

    const redirect = (route) => {
        history.push(route);
    }

    const settings = {
        itemsToShow:1,
        //dots: false,
        infinite: true,
        enableAutoPlay: true,
        autoPlaySpeed: 5000,
        //speed:300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        showArrows: false,
        enableSwipe: true
    };



    return(
        <Box sx={{overflowX:'hidden'}}>
            <Container sx={{ mt: 12 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper 
                            elevation={4}
                            sx={{
                                padding: 0,        // Elimina cualquier padding dentro del Paper
                                margin: 0,         // Elimina cualquier margen
                                //boxShadow: 3,      // Mantiene la sombra
                                //borderRadius: 2    // Mantiene los bordes redondeados
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    height: { xs: "80vh", lg: "60vh", xl: "60vh" },
                                    width: "100%",
                                    background: "linear-gradient(135deg, #0C0E11, #225070, #2C2D31, #0C0E11)",
                                    backgroundSize: "400% 400%",
                                    animation: "gradientShift 10s ease",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    borderRadius:3
                                }}
                                >
                                <Grid container spacing={3} justifyContent={'center'} alignItems={'center'} sx={{p:1}}>
                                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                                        <Typography component={'h1'} fontWeight={'bold'} textAlign={'center'} variant='h3' sx={{ display: { xs: 'none', md: 'block' } }}>
                                            Tu entrada a nuevas experiencias
                                        </Typography>
                                        <Typography component={'h1'} fontWeight={'bold'} textAlign={'center'} variant='h4' sx={{ display: { xs: 'block', md: 'none' } }}>
                                            Tu entrada a nuevas experiencias
                                        </Typography>
                                        <Typography variant='h6' textAlign={'center'} sx={{ display: { xs: 'none', md: 'block' } }}>
                                            Descubre los mejores eventos en tu ciudad y apoya a tus comercios locales favoritos
                                        </Typography>
                                        <Typography variant='body1' textAlign={'center'} sx={{ display: { xs: 'block', md: 'none' } }}>
                                            Descubre los mejores eventos en tu ciudad y apoya a tus comercios locales favoritos
                                        </Typography>
                                        <Box sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'center' }}>
                                            <Button color="secondary" LinkComponent={'a'} target="_blank" href="https://www.instagram.com/qud.life/">
                                            <FontAwesomeIcon size="3x" icon={faInstagram} />
                                            </Button>
                                            <Button sx={{ ml: 1 }} color="secondary" LinkComponent={'a'} target="_blank" href="https://wa.me/584129442394">
                                            <FontAwesomeIcon size="3x" icon={faWhatsapp} />
                                            </Button>
                                        </Box>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid container spacing={3} justifyContent={'center'}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography variant="h5" textAlign={'center'}>Obtén acceso anticipado</Typography>
                                                    <Typography variant="body1" color={'#b0b0b0'} fontWeight={'bold'} textAlign={'center'}>Selecciona el tipo de cuenta</Typography>
                                                </Grid>
                                                <Grid item xs={10} sm={5} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button fullWidth size="large" variant="outlined" color='secondary' onClick={() => redirect('/auth/personSignUp')}>
                                                    <FontAwesomeIcon size="2x" color={'#c9c9c9'} icon={faPerson} />
                                                    <Typography fontWeight={'bold'} color={'#c9c9c9'} variant="body1" ml={2}>Personal</Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={10} sm={5} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="outlined" color='secondary' onClick={() => redirect('/auth/merchantSignUp')}>
                                                    <FontAwesomeIcon size="2x" color={'#c9c9c9'} icon={faStore} />
                                                    <Typography fontWeight={'bold'} color={'#c9c9c9'} variant="body1" ml={2}>Comercial</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <style>{`
                                    @keyframes gradientShift {
                                        0% {
                                            background-position: 0% 50%;
                                        }
                                        50% {
                                            background-position: 100% 50%;
                                        }
                                        100% {
                                            background-position: 0% 50%;
                                        }
                                    }
                                `}</style>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>


            
           
            {/**-------------------CATEGORIAS------------------- */}
            <br/>
            <Categories/>
            <br/>
        </Box>
    )
}


