import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";

/*
|--------------------------------------------------------------------------
| List of functions
|--------------------------------------------------------------------------
| - GetAllFromMerchant
| - Add
| EN - Routes for coupons management
| ES - Rutas para la gestion de cupones
|
*/

/**GetAllFromMerchant */
export async function GetAllFromMerchant()
{
    let response = await GET(CompanyRoutes.Coupons.GetAllFromMerchant);
    return response;
}

/**Add */
export async function Add(title,amount,description,receiver,startDate,endDate)
{
    const params = [
        {name:'title',value:title},
        {name:'amount',value:amount},
        {name:'description',value:description},
        {name:'receiver',value:receiver},
        {name:'startDate',value:startDate},
        {name:'endDate',value:endDate},
        //{name:'',value:},
    ];

    let response = await POST(CompanyRoutes.Coupons.Add,params);
    return response;
}