import ve from '../assets/images/countries/venezuela.png';
import us from '../assets/images/countries/usa.png';
import co from '../assets/images/countries/colombia.png';
import cl from '../assets/images/countries/chile.png';
import sv from '../assets/images/countries/elsalvador.png';

export default function CountryFlag(props)
{
    let flag = '';
    if(props.country === 've')
        flag=ve;
    else if(props.country === 'us')
        flag=us;
    else if(props.country === 'co')
        flag=co;
    else if(props.country === 'cl')
        flag=cl;
    else
        flag=sv;

    return(
        <img style={{width:25}} src={flag} alt={props.country}/>
    )
}