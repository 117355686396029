import { MorocotasRoutes } from "../apiRoutes/apiMorocotas";
import { GET, POST } from "./ConsumeExternalApi";

export async function morocotas_getUser(email,token)
{
    let response =  await GET(MorocotasRoutes.USER.user+email,token);
    return response;
}

export async function morocotas_getUserTransactions(currency_id,number_rows,token)
{
    var date = new Date();
    //var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var previousYear = year - 1;
    //var lastDay = new Date(year,month,0).getDate();
    var start_date = previousYear+'-'+'1'+'-'+'1';
    var end_date = year+'-'+'12'+'-'+'31';

    let params = [
        {name:'currency_id',value:currency_id},
        {name:'number_rows',value:number_rows},
        {name:'start_date',value:start_date},
        {name:'end_date',value:end_date},
    ];

    var response = await POST(MorocotasRoutes.TRANSACTIONS.user,params,token);

    return response;
}


export async function pay(to,amount,observations,pin,token)
{
    const params = [
        {name:'pin',value:pin},
        {name:'currency_id',value:2},
        {name:'gateway_id',value:'WEB'},
        {name:'to',value:to},
        {name:'amount',value:amount},
        {name:'observations',value:observations}
    ];

    let response = await POST(MorocotasRoutes.TRANSACTIONS.pay,params,token);
    return response;

}