import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Alert, AlertTitle, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';
import { GetAll } from '../../../controllers/company/AssociatesController';

//export default class TablaProductos extends Component
export default function AssociatesDataTable()
{
    const {userData,setUserData} = useContext(UserContext)
    const [copySuccess, setCopySuccess] = useState('');
    const [associates,setAssociates] = useState([]);

    useEffect( () => {
        handleGetAll()
    },[]);

    const handleCopy = (uuid) => {
        const url = 'https://qud.life/businesscard/'+uuid;
        navigator.clipboard.writeText(url);
        setCopySuccess(true);
        setTimeout(()=>{setCopySuccess(false)},5000)
        //e.clipboardData.setData("Text", 'HOLA MUNDO!');
    }

    const columns = [
        
        {
            name : 'name',
            label : 'Nombre',
        },
        {
            name : 'last_name',
            label : 'Apellido'
        },
        {
            name : 'position',
            label : 'Cargo'
        },
        {
            name : 'email',
            label : 'Email'
        },
        {
            name : 'status',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='Activo' color='success' />
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = associates[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => ChangeStatus(associates[dataIndex].idSubCategoria)  }  
                            >
                                <IconButton>
                                {
                                    associates[dataIndex].estatus === 1
                                    ? <FontAwesomeIcon icon={faTrash}/>
                                    : <FontAwesomeIcon icon={faPlay}/>
                                }
                                </IconButton>
                            </Link>
                            
                            
                                <IconButton edge='end' onClick={()=>handleCopy(associates[dataIndex].uuid)}>
                                    <FontAwesomeIcon icon={faCopy}/>
                                </IconButton>
                        </div>
                    );
                },

                print : false
            }
        }
    ];

    const ChangeStatus = async (id) => {
        
        const params = [
            {name:'idSubCategoria',value:id},
        ];

        let response = await POST(CompanyRoutes.associates.ChangeStatus,params);

        if(response.success === true)
            handleGetAll();
    }


    const handleGetAll = async () => {
        let response = await GetAll();
        if(response.success === true)
        {
            setAssociates(response.data.associates);
        }
    }


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <>
            <MUIDataTable
                title={"Listado de Categorías"}
                data={associates}
                columns={columns}
                options={options}
            />
            {
                copySuccess === true &&
                <Alert sx={{position:'absolute',right:10,top:80}} severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Enlace copiado — <strong>compartelo con tus clientes!</strong>
                </Alert>
            }
        </>
    )
}