import { Alert, AlertTitle, Button, Container, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../navigation/context/userContext";

import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
//import { UpdateImagen } from "../../../controllers/company/CompanyProfileController";
import CompanyRoutes from "../../../apiRoutes/CompanyRoutes";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { GetImagenes } from "../../../controllers/company/CompanyProfileController";

export default function CompanyProfileImages()
{
    const {userData, setUserData} = useContext(UserContext);
    const [rutaImgFondo,setRutaImgFondo] = useState('');
    const [imgFondo,setImgFondo] = useState([]);

    const [rutaImgRecuadro,setRutaImgRecuadro] = useState('');
    const [imgRecuadro,setImgRecuadro] = useState([]);

    const [message,setMessage] = useState('');
    const [showAlert,setShowAlert] = useState(false);

    useEffect(()=>{
        getImagenes();
    },[]);

    const UpdateImagen = async (variable,valor) =>
    {
        console.log('Cambiando las imagenes')
        var token = 'Bearer '+ userData.token;
    
          var data = new FormData();
          //data.append('imagenFondo',this.state.imagenFondo);          
          data.append(variable,valor);
    
          try 
          {
                let response = await fetch(
                    CompanyRoutes.Profile.UpdatePicture,
                    {
                        method : 'POST',
                        headers :
                        {
                            'Accept' : 'application/json',
                            //'Content-Type' : 'multipart/form-data; boundary=<calculated when request is sent>',
                            'Authorization' : token,
                        },
                        body : data
                    }
                );
              
                let responseJson = await response.json();          
                
                if(responseJson.success === true)
                {    
                    setShowAlert(true);
                    setMessage(responseJson.message);
                    await setTimeout( () => 
                        setShowAlert(false),7000
                    );
                }
              
          } 
          catch (error) 
          {
              this.setState({progres:false});
              alert(error)
          }
    }

    const getImagenes = async () => {
        let response = await GetImagenes();
        
        if(response.success === true)
        {
            if(response.data.imagenes.imgFondo !== null)
            setRutaImgFondo(imagesURL + 'empresas/'+response.data.imagenes.imgFondo);

            if(response.data.imagenes.imgRecuadro !== null)
            setRutaImgRecuadro(imagesURL + 'empresas/'+response.data.imagenes.imgRecuadro);
        }
    }


    const handleImagenFondo = async (event) =>
    {
        let _imagenFondo = event.target.files[0];
        await setImgFondo(event.target.files[0]);
        await setRutaImgFondo(URL.createObjectURL(event.target.files[0]));
        
        UpdateImagen('imagenFondo',_imagenFondo);
        //UpdateImagenFondo('imagenFondo',_imagenFondo);
    }

    const handleImagenRecuadro = async (event) =>
    {
        let _imagenRecuadro = event.target.files[0];
        await setImgRecuadro(event.target.files[0]);
        await setRutaImgRecuadro(URL.createObjectURL(event.target.files[0]));
        
        UpdateImagen('imagenRecuadro',_imagenRecuadro);
        //UpdateImagenFondo('imagenFondo',_imagenFondo);
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div 
                    style={{
                        position:'relative', 
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center', 
                        width:'100%',
                        backgroundColor:'#FFF',
                        marginBottom:70
                    }}
                >
                    {
                        showAlert === true
                        ?
                        <Alert style={{position:'absolute',right:0,top:100,zIndex:10}} severity="success">
                            <AlertTitle>Operación Exitosa!</AlertTitle>
                            {message}
                        </Alert>
                        : null
                    }

                    <div style={{width:'100%',height:250}}>
                        <form>
                            <input
                                accept="image/*"
                                style={{display: 'none',}}
                                id="imagenFondo"
                                name='imagenFondo'
                                type="file"
                                onChange={ handleImagenFondo /*this.handleImagenEmpresa*/}
                            />
                            <label htmlFor="imagenFondo">
                                
                                    {
                                        rutaImgFondo === ''
                                        ?
                                        <div style={{width:'100%',height:250,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',border:'dotted',borderWidth:1,borderColor:'#000'}}>
                                            <PhotoSizeSelectLargeIcon style={{fontSize:70}}/>
                                            <Typography>Selecciona una imagen de fondo para tu comercio</Typography>
                                        </div>
                                        :
                                        <img src={rutaImgFondo} style={{width:'100%',height:250,objectFit:'cover'}} alt="Imagen de fondo" />
                                    }
                                {/*<Button style={{padding:0,width:'100%',background:'#FFF000'}} variant="contained" color="primary" component="span">
                                    
                                </Button>*/}
                            </label>
                        </form>
                    </div>


                    {/**Imagen del Recuador / Perfil */}
                    <div style={{width:130,height:130,  position:'absolute',
                        bottom:-60,left:100,borderRadius:120}}
                    >
                        <form encType="multipart/form-data">
                            <input
                                accept="image/*"
                                style={{display: 'none',}}
                                id="imagenRecuadro"
                                type="file"
                                onChange={handleImagenRecuadro}
                            />
                            <label htmlFor="imagenRecuadro">
                                <Button style={{padding:0,width:'100%',backgroundColor:'#FFF'}} variant="contained" color="primary" component="span"
                                
                                >
                                    {
                                        rutaImgRecuadro === ''
                                        ?
                                        <div style={{width:130,height:130,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',border:'dotted',borderWidth:1,borderColor:'#000'}}>
                                            <PhotoSizeSelectLargeIcon style={{fontSize:70}}/>
                                        </div>
                                        :
                                        <img src={rutaImgRecuadro} style={{width:130,height:130}} alt="Imagen recuadro" />
                                    }
                                    
                                </Button>
                            </label>
                        </form>
                            
                    </div>

                </div>
            </Grid>
        </Grid>
    )
}