import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetDatos()
{
    let response = await GET(CompanyRoutes.Profile.GetDatos);
    return response;
}

export async function GetImagenes()
{
    let response = await GET(CompanyRoutes.Profile.GetImagenes);
    return response;
}


export async function UpdateImagen(variable,valor)
{
    const params = [
        {variable:variable,value:valor}
    ];

    let response = await POST(CompanyRoutes.Profile.UpdatePicture,params);

    return response;
}


export async function UpdateDatos(nombreComercial,categoria,descripcion,slogan)
{
    let params = [
        {name:'apellido',value:nombreComercial},
        {name:'idTipoEmpresa',value:categoria},
        {name:'descripcion',value:descripcion},
        {name:'slogan', value:slogan}
    ];

    let response = await POST(CompanyRoutes.Profile.UpdateDatosEmpresa,params)
    return response;
}

export async function UpdateCoordenadas(direccion,coordenadas)
{
    let params = [
        {name:'direccion',value:direccion},
        {name:'lat',value:coordenadas.lat},
        {name:'lng',value:coordenadas.lng}
    ];

    let response = await POST(CompanyRoutes.Profile.UpdateCoordenadas,params);
    return response;
}

export async function UpdateDatosContacto(telefono)
{
    let params = [
        {name:'telefono',value:telefono}
    ];

    let response = await POST(CompanyRoutes.Profile.UpdateDatosContacto,params);
    return response;
}




/**REDES SOCIALES */
export async function GetRedesSociales()
{
    let response = await GET(CompanyRoutes.Profile.GetRedesSociales);
    return response;
}

export async function AddRedSocial(idRedSocial,username,enlace,estatus)
{
    const params = [
        {name:'idRedSocial',value:idRedSocial},
        {name:'username',value:username},
        {name:'enlace',value:enlace},
        {name:'estatus',value:estatus}
    ];

    let response = await POST(CompanyRoutes.Profile.AddRedSocial,params);
    return response;
}

export async function UpdateRedSocial(uuid,idRedSocial,username,enlace,estatus)
{
    const params = [
        {name:'uuid',value:uuid},
        {name:'idRedSocial',value:idRedSocial},
        {name:'username',value:username},
        {name:'enlace',value:enlace},
        {name:'estatus',value:estatus}
    ];

    let response = await POST(CompanyRoutes.Profile.UpdateRedSocial,params);
    return response;
}



/**HORARIOS */
export async function GetHorarios(usuario_uuid)
{
    let response = await GET(CompanyRoutes.Profile.GetHorarios);
    return response;
}

export async function AddHorario(dia,inicio,fin,estatus)
{
    const params = [
        {name:'dia',value:dia},
        {name:'inicio',value:inicio},
        {name:'fin',value:fin},
        {name:'estatus',value:estatus}
    ];

    let response = await POST(CompanyRoutes.Profile.AddHorario,params);
    return response;
}

export async function UpdateHorario(uuid,dia,inicio,fin,estatus)
{
    const params = [
        {name:'uuid',value:uuid},
        {name:'dia',value:dia},
        {name:'inicio',value:inicio},
        {name:'fin',value:fin},
        {name:'estatus',value:estatus}
    ];

    let response = await POST(CompanyRoutes.Profile.UpdateHorario,params);
    return response;
}