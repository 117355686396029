import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faStop, faThList, faTicket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

import { changeStatus, getAll } from '../../../controllers/company/TourismController';
import { imagesURL } from '../../../apiRoutes/apiBase';
import DonwloadableQR from './downloadableQR';
import { GetAllCoupons } from '../../../controllers/customer/CouponsController';



//export default class TablaProductos extends Component
export default function CouponsDataTable(props)
{
    const [coupons,SetCoupons] = useState(props.coupons);
    const [coupon,SetCoupon] = useState(null);

    const [open,setOpen] = useState(false);


    const handleGetcoupons = async () => {
        setOpen(false);
        let response = await GetAllCoupons();
        if(response.success === true)
        {
            SetCoupons(response.data.coupons);
        }
        
    }

    const handleOpenQR = (_coupon) => {
        SetCoupon(_coupon);
        setOpen(true);
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:100,height:100}} src={imagesURL + 'empresas/' + coupons[dataIndex].merchant_imagen}/>
                        </div>
                    );
                },
                filter:false
            }
        },
        {
            name : 'title',
            label : 'Cupón',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography><strong style={{fontSize:17}}>{coupons[dataIndex].title}</strong></Typography>
                            <Typography variant='body1'>{coupons[dataIndex].description}</Typography>
                        </div>
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'merchant',
            label : 'Emitido por',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'start_date',
            label : 'Fecha emsión',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'end_date',
            label : 'Válido hasta',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : '',
            label : 'Código QR',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography
                            sx={{'&:hover':{cursor:'pointer'}}} color={'link.main'}
                            onClick={()=>handleOpenQR(coupons[dataIndex])}
                        >
                            Canjear
                        </Typography>
                    );
                },
                filter:false
            }
        },   
        {
            name : 'status',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Emitido'
                    ? <Chip label='Disponible' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        
    ];



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <>
            <MUIDataTable   
                title={"Listado de Cupones"}
                data={coupons}
                columns={columns}
                options={options}
            />
            {
                coupon !== null &&
                <DonwloadableQR open={open} coupon={coupon} handleClose={()=>handleGetcoupons()}/>
            }
        </>
    )
}