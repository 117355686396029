import { useState } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import MapContainer from '../../../components/map';
import SaveIcon from '@mui/icons-material/Save';
import AuxAlert from "../../../components/alert";
import { UpdateCoordenadas } from "../../../controllers/company/CompanyProfileController";
import { LoadingButton } from "@mui/lab";

export default function Ubicacion(props)
{
    const [address,setAddress] = useState(props.address);
    const [errorAddress,setErrorAddress] = useState(false);
    const [coordinates,setCoordinates] = useState(props.coordinates)
    const [center,setCenter] = useState(props.center);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] =useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);


    const mapClicked = (mapProps, map, clickEvent) => {
        setCoordinates({lat: parseFloat(clickEvent.latLng.lat()),lng:parseFloat(clickEvent.latLng.lng())});
    }

    const formVerification = () => {
        var ban = 0;

        if(address === '')
        {
            setErrorAddress(true)
            ban = 1;
        }
        
        return ban;
    }

    const handleUpdateCoordenadas = async () => {
        if(formVerification() === 0)
        {
            setIsLoading(true);
            let response = await UpdateCoordenadas(address,coordinates);
            if(response.success === true)
            {
                setSuccess(true);
                setTitle('Operación Exitosa!');
                setMessage(response.message);
                setOpen(true);
                await setTimeout( () => 
                    setOpen(false),7000
                );
            }
            else
            {
                setSuccess(false);
                setTitle('Operación Fallida!');
                setMessage(response.message);
                setOpen(true);
            }
            setIsLoading(false);
        }
    }

    return(
        <Paper>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' justifyContent='space-between'>
                    <Typography variant='h6' sx={{fontWeight:'bold'}}>Ubicación</Typography>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={() => handleUpdateCoordenadas() }
                    >
                        Guardar
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label='Dirección'
                        value={address}
                        onChange = { (e) => setAddress(e.target.value) }
                        onBlur={ () => (address === '' ? setErrorAddress(true) : setErrorAddress(false) ) }
                        error = {errorAddress}
                        helperText={errorAddress && 'Debes ingresar la dirección'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MapContainer
                        center={center}
                        coordenadas={coordinates}
                        mapClicked={ (mapProps, map, clickEvent) => mapClicked(mapProps, map, clickEvent)  }
                    />
                </Grid>
            </Grid>
            <AuxAlert
                open={open}
                success={success}
                title={title}
                message={message}
            />
        </Paper>
    )
}