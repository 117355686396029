import { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, Link, Paper, Rating, TextField, Typography } from "@mui/material";
import ResponseComponent from "../../../components/responseComponent";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import QUDProgress from "../../../components/progress";
import { getGastromediaEvent, getSponsors } from "../../../controllers/customer/EventosController";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { review_create } from "../../../controllers/customer/ReviewsController";
import UserContext from "../../../navigation/context/userContext";
import SignInModal from "../../auth/signInModal";

export default function EventsGastromedia()
{
    const {userData} = useContext(UserContext);
    const [data,setData] = useState([]);
    const [sponsors,setSponsors] = useState([]);

    const [questions,setQuestions] = useState([]);
    const [comment,setComment] = useState('');

    const [isLoadingData,setIsLoadingData] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');


    const { id } = useParams();

    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route);
    }

    const _PREGUNTAS = [
        {uuid:1,question:'Pregunta numero 1'},
        {uuid:2,question:'Pregunta numero 2'},
        {uuid:3,question:'Pregunta numero 3'},
    ];

    useEffect(()=>{
        handleGetData();
        handleGetQuestions();
    },[]);

    const handleGetData = async () => {
        setIsLoadingData(true);
        let response = await getGastromediaEvent(id);
        if(response.success === true)
        {
            setData(response.data);
            handleGetSponsors(response.data.evento_id);
        }
        setIsLoadingData(false);
    }

    const handleGetSponsors = async (evento_id) => {
        let response = await getSponsors(evento_id);
        if(success === true)
        {
            setSponsors(response.data)
        }
    }

    const handleGetQuestions = async () => {
        //consultar al servidor por las preguntas del evento
        //si el response es true...continuar con algo parecido
        let _questions = [];
        await Promise.all(
            _PREGUNTAS.map(item=>
                _questions.push({
                    uuid: item.uuid,
                    question: item.question,
                    points: 0
                })
            )
        );
        setQuestions(_questions);
    }

    const handleRate = (_uuid,_points) => {
        const newState = questions.map(item=> {
            if(item.uuid === _uuid)
            {
                return {...item,points:_points}
            }
            return item;
        })

        setQuestions(newState)
    }

    const formVerification = () => {
        let ban = 0;
        questions.map(item=>{
            if(item.points < 1)
                return ban = 1
        });

        if(ban === 1)
        {
            setTitle('Operación Fallida');
            setMessage('Debe responder todas las preguntas para poder enviar su calificación');
            setActionText('Aceptar');
            setSuccess(false);
            setShow(true);
        }

        return ban;
    }

    const handleCalculateScore = async () => {
        let score = 0;
        let points = 0;
        const count = questions.length;
        await Promise.all(
            questions.map(item=>
                points = points + item.points
            )
        );

        score = points / count;
        return score;
    }

    const handleSubmit = async () => {
        setIsLoadingData(true);
        if(formVerification() === 1)
            return

        const score = await handleCalculateScore();
        let response = await review_create('product',data.producto_id,score,comment);
        if(response.success === true)
        {
            setTitle('Operación Exitosa');
            setMessage('Su calificación ha sido enviada correctamente');
            setActionText('Finalizar');
            setSuccess(true);
            setShow(true);
            
        }
        setIsLoadingData(false);
    }

    const handleSuccess = () => {
        if(success === false)
            setShow(false);

        redireccionar('/tastyfest');
    }

    return(
        <Container sx={{pb:5}}>
            <QUDProgress open={isLoadingData}/>
            <ResponseComponent
                show={show}
                title={title}
                message={message}
                success={success}
                actionText={actionText}
                onClick={()=> handleSuccess()}
            />
            <Grid container spacing={3} mt={15}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h4" fontWeight={'bold'} component={'h1'} textAlign={'center'}>{data.evento_nombre}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <Avatar sx={{width:150,height:150}}>
                            <img style={{width:'100%',height:'100%'}} src={imagesURL+'empresas/'+data.comercio_imagen} alt="QUD"/>
                        </Avatar>
                        {/**
                        <Avatar sx={{width:150,height:150}}>
                            <img style={{width:'100%',height:'100%'}} src={imagesURL+'productos/'+data.producto_imagen} alt="QUD"/>
                        </Avatar> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>{data.producto_nombre}</Typography>
                    <Typography variant="body1" textAlign={'center'}>({data.comercio_nombre})</Typography>
                </Grid>
                {/**Preguntas */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item lg={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {
                                        questions.length !== 0 &&
                                        questions.map((item)=>
                                            <Box sx={{mt:2}}>
                                                <Typography variant="h6" component="legend">{item.question}</Typography>
                                                <Rating
                                                    size="large"
                                                    name="simple-controlled"
                                                    value={item.points}
                                                    onChange={(event,newValue)=> handleRate(item.uuid,newValue)}
                                                    /*onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                    }}*/
                                                />
                                            </Box>
                                        )
                                    }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            label='Comentario'
                                            id="comment"
                                            name="comment"
                                            value={comment}
                                            onChange={(e)=>setComment(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            size="large"
                                            onClick={()=>handleSubmit()}
                                        >
                                            <Typography>Enviar Calificación</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                {/**Sponsors */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        {
                            sponsors.length !== 0 &&
                            sponsors.map(item=>
                                <Grid item display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                    <Link href={item.url} target="_blank">
                                    <Avatar variant="rounded" sx={{width:70,height:70,bgcolor:'transparent'}}>
                                        <img style={{width:'100%',height:'100%'}} src={imagesURL+'eventos/patrocinadores/'+item.ruta_imagen} alt='QUD'/>
                                    </Avatar>
                                    </Link>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>

            <SignInModal/>
            
        </Container>
    )
}