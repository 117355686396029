import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import SectionTitle from "../../../../components/sectionTitle";
import QUDProgress from "../../../../components/progress";
import { GetEvent, GetSponsorsByEvent } from "../../../../controllers/company/EventsController";
import AddButton from "../../../../components/addButton";
import SponsorsDataTable from "./dataTable";

export default function Sponsors()
{
    const [event,setEvent] = useState([]);
    const [sponsors,setSponsors] = useState([]);
    const [isLoadingData,setIsLoadingData] = useState(false);

    let { id } = useParams();
    
    useEffect(()=>{
        handleGetEvent();
        handleGetSponsors();
    },[]);

    const handleGetEvent = async () => {
        setIsLoadingData(true);
        let response = await GetEvent(id);
        if(response.success === true)
        {
            setEvent(response.data);
            //setRutaImg(imagesURL+'/eventos/'+ response.data.imagen);
            console.log(response);
        }
        setIsLoadingData(false)
    }

    const handleGetSponsors = async () => {
        setIsLoadingData(true);
        let response = await GetSponsorsByEvent(id);
        if(response.success === true)
        {
            setSponsors(response.data);
        }
    }

    return(
        <Container>
            <QUDProgress open={isLoadingData} />
            <CustomBreadcrumbs
                returnTo='/events'
                crumbs={[
                    {name:'Eventos',link:'/events'},
                    {name:event.nombre},
                    {name:'Patrocinadores',link:'/events/sponsors/'+event.id_evento}]}
            />
            <br/>
            <SectionTitle title={event.nombre} subtitle='(Gestión de Patrocinadores)'/>
            <br/>
            {
                event.length !== 0 &&
                <AddButton title='Agregar' route={'/events/sponsors/'+event.id_evento+'/add'} />
            }
            {
                sponsors.length !== 0 &&
                <SponsorsDataTable event_id={event.id_evento} sponsors={sponsors}/>
            }
            
        </Container>
    )
}