import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";

import login_photo from '../../assets/images/website/loginui.png';
import logo from '../../assets/images/logos/black.png';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson, faStore } from "@fortawesome/free-solid-svg-icons";

export default function SelectUserType()
{
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    return(
        <Container maxWidth='xl' sx={{mt:15,mb:3}}>
            <Grid container spacing={3}>
                <Grid item lg={5} xl={5} sx={{display:{xs:'none',sm:'none',md:'none',lg:'block',xl:'block'}}} >
                    <Box sx={{width:'100%'}}>
                        <img
                            style={{width:'100%',borderRadius:20}}
                            src={login_photo}
                            alt=""
                        />
                    </Box>
                </Grid>

                <Grid item lg={7} xl={7} display={'flex'} alignItems={'center'}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item lg={7} xl={7} display={'flex'} justifyContent={'center'}>
                            <Box sx={{width:85,height:85,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img
                                    style={{width:85,height:85}}
                                    src={logo}
                                    alt=""
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <Typography variant="h4" textAlign={'center'}>Obtén acceso anticipado</Typography>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <Typography variant="h6" color={'primary.contrastAux'} fontWeight={'bold'} textAlign={'center'}>Selecciona el tipo de cuenta que deseas registrar</Typography>
                        </Grid>
                        <Grid item lg={4} sx={{display:'flex',justifyContent:'center'}}>
                            <Button fullWidth size="large" variant="outlined" onClick={()=>redirect('/auth/personSignUp')}>
                                <FontAwesomeIcon size="3x" icon={faPerson}/>
                                <Typography fontWeight={'bold'} variant="h6" ml={2}>Personal</Typography>
                            </Button>
                        </Grid>
                        <Grid item lg={4} sx={{display:'flex',justifyContent:'center'}}>
                            <Button fullWidth variant="outlined" onClick={()=>redirect('/auth/merchantSignUp')}>
                                <FontAwesomeIcon size="3x" icon={faStore}/>
                                <Typography fontWeight={'bold'} variant="h6" ml={2}>Comercial</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}