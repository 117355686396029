import { useContext } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import UserContext from "../../../navigation/context/userContext";
import CompanyProfileImages from "./images";
import CompanyInfo from "./info";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import Socials from "./Socials";
import WorkingHours from "./workingHours";

export default function CompanyProfile()
{
    const {userData,setUserData}  =useContext(UserContext)
    return(
        <Container maxWidth='lg'>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Perfil',link:'/profile'}]}/>
            <br/>
            <CompanyProfileImages/>

            <Typography variant="h4" component='h4' sx={{textAlign:'center'}}>
                {userData.user.apellido}
            </Typography>
            
            {/**Datos de la empresa + Datos de Contacto */}
            <Grid container spacing={3} justifyContent='center'>
                {/**Redes Sociales - Horarios 
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Socials/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <WorkingHours/>
                        </Grid>
                    </Grid>
                    
                </Grid>*/}

                {/**Datos de la empresa */}
                <CompanyInfo/>
            </Grid>
            
        </Container>
    )
}