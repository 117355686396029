import { useContext, useState } from 'react';
import { Grid, Box, Button, IconButton } from '@mui/material';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import AuthContext from '../../navigation/context/authContext';

import facebook_img from '../../assets/images/socials/facebook.png';
//import google_img from '../../assets/images/socials/google.png';
import google_img from '../../assets/images/stores/cwg.png';
import apple_img from '../../assets/images/socials/apple.png';
import QUDProgress from '../../components/progress';


const firebaseConfig = {
    apiKey: "AIzaSyBXJWt1k9g-zqwSJbrTtVmlGJAT1mBOJPg",
    authDomain: "qud-life.firebaseapp.com",
    projectId: "qud-life",
    storageBucket: "qud-life.appspot.com",
    messagingSenderId: "544615446583",
    appId: "1:544615446583:web:809b651e0b0cc5f00d27b2"
  };

export default function SocialAuth()
{
    const [isLoading,setIsLoading] = useState(false)
    const app = initializeApp(firebaseConfig);
    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    const appleProvider = new OAuthProvider('apple.com');

    const { signIn } = useContext(AuthContext);
    
    const handleGoogleAuth = async () => {
        setIsLoading(true);
        const auth = getAuth(app);
        auth.useDeviceLanguage();
        await signInWithPopup(auth, googleProvider).
        then(async (result)=>{
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            
            // The signed-in user info.
            const user = result.user;

            //Obtenemos los datos del usuario *nombre *email
            let name = user.displayName;
            let email = user.email;
            let uid = user.uid;
            const oAuth = true;
            const provider = 'google';
            await signIn({email,name,provider,uid,oAuth});
            //Continuar con la logica como en QUD ...

            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error)=>{
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
        });
    }

    const handleFacebookAuth = async () => {
        setIsLoading(true);
        const auth = getAuth(app);
        auth.useDeviceLanguage();
        await signInWithPopup(auth, facebookProvider).
        then(async (result)=>{
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            
            // The signed-in user info.
            const user = result.user;

            //Obtenemos los datos del usuario *nombre *email
            let name = user.displayName;
            let email = user.email;
            let uid = user.uid;
            const oAuth = true;
            await signIn({email,name,oAuth});
            //Continuar con la logica como en QUD ...

            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error)=>{
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
        });
        setIsLoading(false);
    }

/**
 * Identificador de apple para repezmaister@gmail.com
 * providerId: "apple.com"
 * uid: "000574.55003ba668d846468ebbcd04740d1b16.1617"
 * 
 * 
 * uid: "YiFXI0ykNiR2zdv5puIJnHhtEUn1"
 */
    const handleAppleAuth = async () => {
        setIsLoading(true);
        const auth = getAuth(app);
        auth.useDeviceLanguage();
        await signInWithPopup(auth, appleProvider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;
            console.log('APPLE USER ===> ',user);
            let appleUid = '';
            let providerData = user.providerData.filter((item) => item.providerId ==='apple.com');
            console.log('providerData ===> ',providerData[0]);
            
            let name = providerData[0].displayName;
            let email = providerData[0].email;
            let uid = providerData[0].uid;
            const oAuth = true;
            const provider = 'apple';

            signIn({email,name,oAuth,provider,uid});
            // Apple credential
            /*const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            return console.log('credentialData ===> ',credential);*/
            // IdP data available using getAdditionalUserInfo(result)
            // ...
            
        })
        .catch((error) => {
            setIsLoading(false)
            console.log('ERROR ===> ',error)
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The credential that was used.
            const credential = OAuthProvider.credentialFromError(error);

            // ...
        });
    }

    

    return(
        <Grid container spacing={3} justifyContent={'center'}>
            <Grid item>
                <Button sx={{width:210}} onClick={()=>handleGoogleAuth()}>
                    <img style={{width:'100%'}} src={google_img} alt='google'/>
                </Button>
            </Grid>
            {/*<Grid item >
                <Button onClick={()=>handleFacebookAuth()}>
                    <img style={{width:40}} src={facebook_img} alt='facebook'/>
                </Button>
            </Grid>
            <Grid item >
                <Button onClick={()=>handleAppleAuth()}>
                    <img style={{width:40,borderRadius:40}} src={apple_img} alt='apple'/>
                </Button>
            </Grid>*/}
            <QUDProgress open={isLoading}/>
        </Grid>
        
    )
}