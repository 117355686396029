import { createRef } from "react";
import { Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptchaGoogle({onChange,onAbort,onExpired}){

    const recaptchaRef = createRef();
    
    return(
        <Box sx={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',mb:2,mt:1}}>
            <form onSubmit={() => { recaptchaRef.current.execute(); }}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    //sitekey="6LcRVLcZAAAAAM_yRXD-b9qiclDoB-gErol4_w65"
                    sitekey="6LeeuIMqAAAAABYqE0eYtiW7l5STGCZY72o53tif"
                    onChange={onChange}
                    onAbort={onAbort}
                    onExpired={onExpired}
                    asyncScriptOnLoad={onAbort}
                />
            </form>
        </Box>
    )
}