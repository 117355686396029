import { Box, Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faClose, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default function MerchantMenuProductsDetails(props)
{
    return(
        <Dialog
            open={props.open}
            fullWidth
            maxWidth='md'
            onClose={props.onClose}
        >
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                <IconButton aria-label="delete" onClick={props.onClose}>
                    <FontAwesomeIcon icon={faClose}/>
                </IconButton>
            </Box>
            <Grid container spacing={3}>
                <Grid item lg={4} xl={4}>
                    <Box>
                        <img
                            style={{width:'100%',height:'auto',borderRadius:10}}
                            src={imagesURL+'productos/'+props.product.imagen}
                            alt={props.product.nombre}
                        />
                    </Box>
                </Grid>
                <Grid item lg={8} xl={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Typography variant="h5">{props.product.nombre}</Typography>    
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Typography variant="h5">{props.product.precio} $</Typography>    
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="body1">{props.product.descripcion}</Typography>    
                        </Grid>
                        
                        {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <IconButton>
                                    <FontAwesomeIcon icon={faMinusCircle}/>
                                </IconButton>
                                <Typography variant="h5" fontWeight={'bold'} ml={1} mr={1}>
                                    1
                                </Typography>
                                <IconButton>
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                </IconButton>
                            </Box>
                            <Button variant="outlined">
                                <Typography>Añadir al carrito</Typography>
                            </Button>
                        </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}