import { useEffect, useState } from "react";
import { Avatar, Container, Grid, Link, Typography } from "@mui/material";
import { getSponsors } from "../../../controllers/customer/EventosController";
import QUDProgress from "../../../components/progress";
import { imagesURL } from "../../../apiRoutes/apiBase";

export default function GastromediaAlly()
{
    const [sponsors,setSponsors] = useState([]);

    const [isLoadingData,setIsLoadingData] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');

    useEffect(()=>{
        handleGetSponsors();
    },[]);

    const handleGetSponsors = async () => {
        setIsLoadingData(true);
        let response = await getSponsors(6);
        if(success === true)
        {
            setSponsors(response.data)
        }
        setIsLoadingData(false);
    }

    return(
        <Container sx={{mt:15}}>
            <QUDProgress open={isLoadingData}/>
            <Typography variant="h4" textAlign={'center'} gutterBottom>Gastromedia</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" textAlign={'justify'} gutterBottom>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus commodo et felis a aliquet. Donec pretium dolor varius orci semper, ut fringilla velit suscipit. Nullam porttitor libero ac velit pulvinar cursus. Aenean ac imperdiet nibh, at venenatis nisl. In dignissim semper ligula nec imperdiet. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc molestie, lectus a cursus volutpat, sapien dolor scelerisque magna, dictum ornare lorem ex viverra leo. Aliquam magna lectus, dictum at dapibus ac, accumsan ac dui. Donec malesuada ante ut magna hendrerit sagittis.
                    </Typography>
                </Grid>

                {/**Sponsors */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                    <Typography variant="h5" textAlign={'center'}>Patrocinadores</Typography>
                    <Grid container spacing={3} justifyContent={'center'} mt={3}>
                        {
                            sponsors.length !== 0 &&
                            sponsors.map(item=>
                                <Grid item display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                    <Link href={item.url} target="_blank">
                                    <Avatar variant="rounded" sx={{width:70,height:70,bgcolor:'transparent'}}>
                                        <img style={{width:'100%',height:'100%'}} src={imagesURL+'eventos/patrocinadores/'+item.ruta_imagen} alt='QUD'/>
                                    </Avatar>
                                    </Link>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}