import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faStop, faThList, faTicket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

import { imagesURL } from '../../../../apiRoutes/apiBase';
import { GetSponsorsByEvent } from '../../../../controllers/company/EventsController';

//export default class TablaProductos extends Component
export default function SponsorsDataTable(props)
{
    const [sponsors,setSponsors] = useState(props.sponsors);


    const handleGetSponsors = async () => {
        
        let response = await GetSponsorsByEvent(props.event_id);
        if(response.success === true)
        {
            setSponsors(response.data);
        }
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:100,height:100}} src={imagesURL + 'eventos/patrocinadores/' + sponsors[dataIndex].ruta_imagen}/>
                        </div>
                    );
                },
                filter:false
            }
        },
        {
            name : 'url',
            label : 'Enlace',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Activo'
                    ? <Chip label='Activo' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => handleChangeStatus(sponsors[dataIndex].id)  }  
                            >
                                <IconButton>
                                {
                                    sponsors[dataIndex].estatus === 'Activo'
                                    ? 
                                    <Tooltip title='Inactivar' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Activar' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                }
                                </IconButton>
                            </Link>

                            
                            <Link 
                                to={'/sponsors/update/'+sponsors[dataIndex].id} 
                                //onClick={ ()=> localStorage.setItem('evento',JSON.stringify(sponsors[dataIndex])) }
                                replace
                            >
                                <Tooltip title='Editar' placement='top'>
                                    <IconButton>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const handleChangeStatus = async (id) => {

        /*let response = await ChangeStatus(id);
        if(response.success === true)
            handleGetEvents();*/
    }




    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
        
            title={"Listado de Eventos"}
            data={sponsors}
            columns={columns}
            options={options}
        />
    )
}