import { useEffect, useState } from "react";
import { Box, Button, Card, CardActionArea, CardContent, Container, Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { imagesURL } from "../../../apiRoutes/apiBase";
import SectionTitle from "../../../components/sectionTitle";
import { GetMerchant, GetMerchantCategories } from "../../../controllers/MerchantsController";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ProfileImage from "../../../components/profileImage";

export default function MerchantSelect()
{
    const [height,setHeight] = useState(250);
    const [merchant,setMerchant] = useState([]);

    const history = useHistory();
    const {uuid} = useParams();

    useEffect(() => {
        handleGetCategories();
    },[]);


    function redireccionar(ruta)
    {
        history.push(ruta);
    }

    const handleGetCategories = async () => {
        let response = await GetMerchant(uuid);
        if(response.success === true)
        {
            setMerchant(response.data.merchant);
        }
    }


    return(
        <Box>
            <ProfileImage imagen={merchant.imagen} imagen2={merchant.imagen2}/>
            <Container sx={{mb:5,marginTop:10}}>
                <IconButton onClick={()=>redireccionar('/merchants')}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </IconButton>
                <SectionTitle title={merchant.negocio} subtitle='Selecciona una opción'/>
                <Grid container justifyContent='center' spacing={3}>
                    <Grid item xs={8} sm={8} md={8} lg={6} xl={6} mt={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={()=>redireccionar('/merchants/'+merchant.id+'/menu')}
                        >
                            Ver productos / servicios
                        </Button>
                        <br/><br/>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={()=>redireccionar('/merchants/'+merchant.id+'/profile')}
                        >
                            Ver perfil
                        </Button>
                        <br/><br/>
                        <Button fullWidth variant="contained" onClick={()=>alert('En construccion')}>
                            Dejar un comentario
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}