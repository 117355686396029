import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faStop, faThList, faTicket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

import { changeStatus, getAll } from '../../../controllers/company/TourismController';
import { imagesURL } from '../../../apiRoutes/apiBase';
import DonwloadableQR from './downloadableQR';



//export default class TablaProductos extends Component
export default function PlacesDataTable(props)
{
    const [places,setPlaces] = useState(props.places);
    const [place,setPlace] = useState(null);

    const [open,setOpen] = useState(false);


    const handleGetplaces = async () => {
        
        let response = await getAll();
        if(response.success === true)
        {
            setPlaces(response.data);
        }
    }

    const handleOpenQR = (_place) => {
        setPlace(_place);
        setOpen(true);
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:100,height:100}} src={imagesURL + 'tourism/places/' + places[dataIndex].image}/>
                        </div>
                    );
                },
                filter:false
            }
        },
        {
            name : 'name',
            label : 'Nombre',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'category',
            label : 'Categoría',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'city',
            label : 'Ciudad',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : '',
            label : 'Código QR',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography
                            sx={{'&:hover':{cursor:'pointer'}}} color={'link.main'}
                            onClick={()=>handleOpenQR(places[dataIndex])}
                        >
                            Visualizar
                        </Typography>
                    );
                },
                filter:false
            }
        },   
        {
            name : 'status',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Activo'
                    ? <Chip label='Activo' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link 
                                onClick = { () => handleChangeStatus(places[dataIndex].uuid)  }  
                            >
                                <IconButton>
                                {
                                    places[dataIndex].status === 'Activo'
                                    ? 
                                    <Tooltip title='Inactivar' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Activar' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                }
                                </IconButton>
                            </Link>

                            
                            <Link 
                                to={'/tourism/update/'+places[dataIndex].uuid} 
                                //onClick={ ()=> localStorage.setItem('evento',JSON.stringify(places[dataIndex])) }
                                replace
                            >
                                <Tooltip title='Editar' placement='top'>
                                    <IconButton>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const handleChangeStatus = async (uuid) => {

        let response = await changeStatus(uuid);
        if(response.success === true)
            handleGetplaces();
    }




    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <>
            <MUIDataTable   
                title={"Listado de Sitios Turísticos"}
                data={places}
                columns={columns}
                options={options}
            />
            {
                place !== null &&
                <DonwloadableQR open={open} place={place} handleClose={()=>setOpen(false)}/>
            }
        </>
    )
}