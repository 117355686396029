import { useContext, useEffect } from "react";
import UserContext from "../../navigation/context/userContext";

import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";

import img_QUDBoy from '../../assets/images/dashboard/qudBoy.jpeg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faTicket, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Categories from "../website/Categories";

import pub1 from '../../assets/images/pub1.png';
import pub2 from '../../assets/images/pub2.png';
import artemisWave from '../../assets/svgs/artemisWaves2.svg';
import artemis_marker from '../../assets/images/allies/artemis_girl.png';
import { useTranslation } from "react-i18next";

export default function ClientHome()
{
    const {t, i18n} = useTranslation();
    const { userData } = useContext(UserContext)

    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        window.scrollTo(0,0);
    })

    return(
        <Box pt={3}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>Panel de control</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                            onClick={()=>redirect('profile')}
                        >
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <Typography variant='h6' component={'p'} fontWeight={'bold'}>{t('helpers.profile')}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                            onClick={()=>redirect('coupons')}
                        >
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <Typography variant='h6' component={'p'} fontWeight={'bold'}>{t('helpers.coupons')}</Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                            onClick={()=>redirect('reservations')}
                        >
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <Typography variant='h6' component={'p'} fontWeight={'bold'}>{t('helpers.reservations')}</Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                            onClick={()=>redirect('tickets')}
                        >
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <Typography variant='h6' component={'p'} fontWeight={'bold'}>{t('helpers.tickets')}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <br/>
            <Categories/>
        </Box>
    )
}