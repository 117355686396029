import { useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Divider, Grid, List, ListItem, ListItemText, ListSubheader, Paper, Typography, useTheme } from "@mui/material";

import img_fondo from '../../../../assets/images/allies/exitart/jorgeBanner.png';
import img_jorge from '../../../../assets/images/allies/exitart/jorge2.jpg';
import img_pricing from '../../../../assets/images/allies/exitart/parrilla.png';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheckSquare, faMapLocation, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { GetEventByID, getSponsors } from "../../../../controllers/customer/EventosController";
import { imagesURL } from "../../../../apiRoutes/apiBase";
import QUDProgress from "../../../../components/progress";
import moment from "moment";
import { Helmet, HelmetProvider, HelmetData } from 'react-helmet-async';

const helmetData = new HelmetData({});

export default function JorgeSilvaCook(props)
{
    const [isLoading,setIsLoading] = useState(false);
    const [event,setEvent] = useState([]);
    const [sponsors,setSponsors] = useState([]);
    const history = useHistory();

    const princing = [
        {
            plan:'Tren al Sur',
            price: 65.00,
            people: 'persona (Pre-venta)',
            benefits:[
                'Dos bebidas cortesía',
                'Delantal',
                'Proteínas variadas para preparar',
                'Uso de estación de barril o parrillera'
            ]
        },
        {
            plan:'Pack Corporativo',
            price: 220.00,
            people: '4 personas',
            benefits:[
                'Dos bebidas cortesía',
                'Delantal',
                'Proteínas variadas para preparar',
                'Uso de estación de barril o parrillera',
                'Descuento de grupo corporativo'
            ]
        },
        {
            plan:'Zona espectador',
            price: 10.00,
            people: 'persona',
            benefits:[
                'Aprendizaje',
                'Dos bebidas cortesía',
                'Asiento en área asignada',
            ]
        },
    ];

    const theme = useTheme();

    const location = useLocation();
    const currentPath = location.pathname;
    const pageDescription = 'Taller de asados por @jorgesilvacook'

    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetEvent();
        handleGetSponsors();
    },[]);

    const handleGetEvent = async () => {
        setIsLoading(true);
        let response = await GetEventByID(16);
        if(response.success === true)
        {
            setEvent(response.data.event);
            console.log('FECHA Y HORA ===> ',moment(response.data.event.fecha2).toLocaleString('es-ve'))
        }
        setIsLoading(false);
    }

    const handleGetSponsors = async () => {
        let response = await getSponsors(16);
        if(response.success === true)
        {
            setSponsors(response.data);
        }
    }

    return(
        <Box sx={{overflowX:'hidden',mt:-6}}>
            <Helmet helmetData={helmetData}>
                <title>Asa2workshop</title>
                <meta name="description" content={pageDescription} />
            </Helmet>
            <QUDProgress open={isLoading}/>
            <Box
                sx={{
                    position:'relative',
                    height: '90vh',
                    width: '100vw',
                }}
            >
                <Box
                    sx={{
                        backgroundImage: `url(${img_fondo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '90vh',
                        width: '100vw',
                        filter: 'brightness(40%)',
                        backdropFilter: 'blur(10px)',
                        position:'absolute',
                        zIndex:-10
                    }}
                />
                <Container maxWidth='lg' sx={{height:'100%'}}>
                    <Grid container spacing={3} display={'flex'} flexDirection={'row'} alignContent={'center'} height={'100%'}>
                        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Typography variant="h2" fontWeight={'bold'} color={'#FFF'} mb={-1}>
                                TREN AL SUR
                            </Typography>
                            <Typography variant="h3" fontWeight={'bold'} color={'#FFF'} gutterBottom>
                                Barril y Parrilla
                            </Typography>
                            <Typography variant="h5" fontWeight={'bold'} color={'#feda44'} gutterBottom>
                                Hotel Belensate. Mérida <br/>03/05/2024 - 5:00 pm
                            </Typography>
                            <Button
                                variant="contained"
                                color='primary'
                                sx={{p:1,bgcolor:'#7D1418'}}
                                onClick={()=>redirect('eventos/16/tickets')}
                            >
                                <Typography variant="h4">&nbsp;&nbsp;Reserva tu cupo&nbsp;&nbsp;</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Container maxWidth='lg' sx={{mt:{xs:0,sm:0,md:5,lg:5,xl:5}}}>
                <Grid container spacing={3} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography component={'h1'} fontWeight={'bold'} color={'#7D1418'} sx={{mt:{xs:5}}}>
                            TALLER DE ASADOS
                        </Typography>
                        <Typography variant="h4" fontWeight={'bold'} component={'h2'}>
                            ¡Tú parrillas, aprendes y disfrutas!
                        </Typography>
                        <Typography variant='h6' component={'p'}>
                            ¡Prepárate para un viaje culinario por Suramérica!<br/><br/>
                            En este taller de asados, rendiremos tributo a las preparaciones autóctonas de Argentina, Uruguay, Perú, Brasil, Colombia y Venezuela.<br/>
                            Aprende de la mano de @jorgesilvacook y disfruta de una experiencia única donde tú serás el protagonista.<br/>
                            Descubre una forma distinta de disfrutar de las brasas, emulando sabores y técnicas tradicionales.<br/><br/>
                            ¡Cupos limitados! Reserva tu cupo ahora.
                        </Typography>
                        <Grid container spacing={3} mt={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                <Box sx={{bgcolor:'#f5f5f5',p:2,borderRadius:3,display:'flex',flexDirection:'row'}}>
                                    <FontAwesomeIcon size="2x" color="#7D1418" icon={faMapLocationDot}/>
                                    <Box ml={2}>
                                        <Typography variant="h6" fontWeight={'bold'}>Lugar</Typography>
                                        <Typography variant="body1" >Hotel Belensate. Mérida</Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Box sx={{bgcolor:'#f5f5f5',p:2,borderRadius:3,display:'flex',flexDirection:'row'}}>
                                    <FontAwesomeIcon size="2x" color="#7D1418" icon={faCalendarAlt}/>
                                    <Box ml={2}>
                                        <Typography variant="h6" fontWeight={'bold'}>Fecha</Typography>
                                        <Typography variant="body1" >Viernes 03 de mayo</Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color='link'
                                    sx={{p:1,bgcolor:'#7D1418'}}
                                    onClick={()=>redirect('eventos/16/tickets')}
                                >
                                    <Typography variant="h4">&nbsp;&nbsp;Reserva tu cupo&nbsp;&nbsp;</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <img
                                //src={imagesURL+'eventos/'+event.imagen}
                                src={img_jorge}
                                alt="Exitart"
                                style={{width:'100%',borderRadius:10}}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box
                sx={{
                    backgroundImage: {xs:'none',sm:'none',md:`url(${img_pricing})`,lg:`url(${img_pricing})`,xl:`url(${img_pricing})`},
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '70vh',
                    width: '100vw',
                    //filter: 'brightness(50%)',
                    //backdropFilter: 'blur(10px)',
                }}
            >
                <Container maxWidth='lg' sx={{mt:15,mb:5}}>
                    <Grid container spacing={3} justifyContent={'center'} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant='h4' fontSize={45} fontWeight={'bold'} color='primary.main' textAlign={'center'}>
                                Entradas disponibles
                            </Typography>
                        </Grid>
                        {
                            princing.length !== 0 &&
                            princing.map(item =>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{mt:{xs:5}}}>
                                    <Paper elevation={4} sx={{height:'100%'}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant="h6" fontWeight={'bold'} color={'#7D1418'}>
                                                    {item.plan}
                                                </Typography>
                                                <Box sx={{display:'flex',flexDirection:'row'}}>
                                                    <Typography variant="h4" fontWeight={'bold'}>{item.price} $</Typography>
                                                    <Typography variant="h6" fontWeight={'bold'} ml={1}>/{item.people}</Typography>
                                                </Box>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant="body1" fontWeight={'bold'}>
                                                    ¿Qué incluye?
                                                </Typography>
                                                {
                                                    item.benefits.map(benefit=>
                                                    <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',mt:1}}>
                                                        <FontAwesomeIcon color={'#7D1418'} icon={faCheckSquare}/>
                                                        <Typography variant="body1" fontWeight={'bold'} ml={1}>{benefit}</Typography>
                                                    </Box>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'} mt={10}>
                            <Button
                                variant="contained"
                                color='link'
                                sx={{p:1,bgcolor:'#7D1418'}}
                                onClick={()=>redirect('eventos/16/tickets')}
                                
                            >
                                <Typography variant="h4">&nbsp;&nbsp;Reserva tu cupo&nbsp;&nbsp;</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            
            <Container sx={{mt:15}}>
                
            </Container>
            
            {/** 
            <Container maxWidth='lg' sx={{mt:15}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography fontWeight={'bold'} color={'link.main'} sx={{mt:{xs:35,sm:8,md:5,lg:0,xl:0}}}>
                            Actividades
                        </Typography>
                        <Typography variant="h4" fontWeight={'bold'}>
                            Itinerario
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="h6" fontWeight={'bold'}>Sábado 27 de abril de 2024</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant="h6">02:00 pm - Entregas de Kits en el CC Plaza Mayor</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="h6" fontWeight={'bold'}>Domingo 28 de abril de 2024</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant="h6" gutterBottom>06:30 am - Salida de la carrera de 10K</Typography>
                        <Typography variant="h6">07:30 am - Salida de la caminata familiar de 2K</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    </Grid>
                </Grid>
            </Container>


            <Container maxWidth='lg'>
                <Grid container spacing={3} mt={5} mb={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography fontWeight={'bold'} color={'link.main'}>
                            Patrocinado por
                        </Typography>
                        <Typography variant="h4" fontWeight={'bold'}>
                            Nuestros socios y colaboradores
                        </Typography>
                    </Grid>
                    {
                        sponsors.length !==0 &&
                        sponsors.map(item=>
                            <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                <Button
                                    href={item.url}
                                    target="_blank"
                                >
                                    <img
                                        src={imagesURL+'eventos/patrocinadores/'+item.ruta_imagen}
                                        style={{width:'100%'}}
                                        alt="qud"
                                    />
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        */}
        </Box>
    )
}