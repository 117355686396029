import { useReducer, useState } from 'react';
import { Container, Box, Grid, Paper, Typography, TextField, MenuItem,Button, IconButton } from '@mui/material';
import { LocalizationProvider,DateTimePicker, MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import QUDProgress from '../../../components/progress';
import moment, { now } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Copyright from '../../../components/copyRight';


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    title: '',                  errorTitle: false,
    amount:'',                  errorAmount: false,
    description:'',             errorDescription: false,
    receiver:'',                errorReceiver: false,
    startDate: new Date(),      errorStartDate: false,
    time:'',                    errorTime:false,
    endDate:null,               errorEndDate: false,
    people: 1,
    
    
    isLoading : false,

    open: false,
    titleR: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }


export default function TransportBooking()
{
    const [state,setState] = useReducer(reducer,initialState);
    const [value, setValue] = useState(moment().format());
    const {
        title, errorTitle, amount, errorAmount, description, errorDescription,
        receiver, errorReceiver, startDate, errorStartDate, time, people,
        endDate, errorEndDate, isLoading, open, titleR, message, success
    } = state;

    const locations = [
        {id:1,name:'Barcelona'},
        {id:2,name:'Lecheria'},
        {id:3,name:'Puerto La Cruz'},
        {id:4,name:'Guanta'},
    ];

    const handleChange = (newValue) => {
        //let _newValue = moment(newValue).format();
        let _newValue =  moment(newValue.format('YYYY/MM/DD HH:mm:ss')).format("YYYY-MM-DD HH:mm:ss");
        //return console.log('TIME STAMP ===> ',_newValue);
        setValue(_newValue); 
    };

    const handleValue = (op) => {
        if(op === '+')
        {
            if(people === 4)
                return;
            else
            setState({field:'people',value:people+1});
        }
        else
        {
            if(people === 1)
                return;
            else
            setState({field:'people',value:people-1});;
        }
    }


    const handleStartDateChange = (newValue) => {
        //return console.log(newValue)
        setState({field:'startDate',value:newValue});
    };

    return(
        <Box sx={{overflowX:'hidden',mt:-8,pb:5}}>
            <QUDProgress open={isLoading}/>
            <Box sx={{width:'100%',height:350,display:'flex',flexDirection:'column',bgcolor:'primary.main',justifyContent:'center',alignItems:'center'}}>
                <Typography variant='h5' color={'primary.contrastText'} fontWeight={'bold'} >Viaja Segur@</Typography>
                <Typography variant='h5' color={'primary.contrastText'} fontWeight={'bold'} >En Todo</Typography>
                <Typography variant='h5' color={'primary.contrastText'} fontWeight={'bold'} >Momento</Typography>
            </Box>
            <Container maxWidth='xl'>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
                        <Paper elevation={4} sx={{mt:-10,mb:2}}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-currency"
                                        select
                                        label="Origen"
                                        defaultValue=""
                                        >
                                            {locations.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-currency"
                                        select
                                        label="Destino"
                                        defaultValue=""
                                        >
                                            <MenuItem key="" value="">
                                                Seleccione el Origen
                                            </MenuItem>
                                            {locations.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>

                                

                                {/**DateTime */}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateTimePicker
                                            label="Fecha y Hora"
                                            //inputFormat="DD/MM/YYYY"
                                            minDate={new Date()}
                                            value={value}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {/**People */}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',bgcolor:'#ededed',borderRadius:2,p:2}}>
                                        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                                            <Typography variant='h3' fontWeight={'bold'}>{people}</Typography>
                                            <Typography variant='body1'>Persona{people > 1 ? 's' :''}</Typography>
                                        </Box>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <IconButton color="primary" aria-label="minus" onClick={()=>handleValue('-')}>
                                                <FontAwesomeIcon icon={faMinusCircle}/>
                                            </IconButton>
                                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',ml:1,mr:1}}>
                                                <Typography variant="h5" fontWeight={'bold'}>
                                                    {people}
                                                </Typography>
                                                
                                            </Box>
                                            <IconButton color="primary" aria-label="plus" onClick={()=>handleValue('+')}>
                                                <FontAwesomeIcon icon={faPlusCircle}/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>

                                {/**Observations */}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-currency"
                                        multiline
                                        rows={2}
                                        label="Observaciones"
                                        defaultValue=""
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'flex-end'}>
                                    <Button
                                        disabled={isLoading}
                                        variant="contained"
                                        color="cancel"
                                        //onClick={props.handleClose}
                                    >
                                        <Typography variant='body2' fontWeight={'bold'}>Cancelar</Typography>
                                    </Button>
                                    <Button
                                        disabled={isLoading}
                                        sx={{ml:2}}
                                        variant="contained"
                                        //onClick={()=>handleSubmit()}
                                    >
                                        <Typography variant='body2' fontWeight={'bold'}>Solicitar</Typography>
                                    </Button>
                                </Grid>
                                
                            </Grid>
                        </Paper>
                        <Copyright/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        
    )
}