import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';


import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WalletIcon from '@mui/icons-material/Wallet';
import { Box, Divider, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import AuthContext from '../../navigation/context/authContext';

import CategoryIcon from '@mui/icons-material/Category';
import LocalOffer from '@mui/icons-material/LocalOffer';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faGripHorizontal, faMapLocation, faShoppingCart, faTags, faTicket } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material';
import UserContext from '../../navigation/context/userContext';


export default function ManagementListItems(props){
    const theme = useTheme();
    const { signOut } = useContext(AuthContext);
    const { userData } = useContext(UserContext);
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <div>
            {
                props.open &&
                <ListItem>
                    <Typography variant='body1' color={'#878787'} >Gestión</Typography>
                </ListItem>
            }
            
            {
                userData.user.idTipoUsuario === 2 &&
                <>
                    <ListItem button onClick={()=>redireccionar('/categories')}>
                        <Tooltip title='Categorías' placement='right'>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    color={theme.palette.primary.contrastText}
                                    size={'lg'}
                                    icon={faGripHorizontal}
                                />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Categorías" />
                    </ListItem>

                    <ListItem button onClick={()=>redireccionar('/products')}>
                        <Tooltip title='Productos' placement='right'>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    color={theme.palette.primary.contrastText}
                                    size={'lg'}
                                    icon={faTags}
                                />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Productos" />
                    </ListItem>

                    <ListItem button onClick={()=>redireccionar('/orders')}>
                        <Tooltip title='Pedidos' placement='right'>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    color={theme.palette.primary.contrastText}
                                    size='lg'
                                    icon={faShoppingCart}
                                />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Pedidos" />
                    </ListItem>

                    <ListItem button onClick={()=>redireccionar('/reservations')}>
                        <Tooltip title='Reservaciones' placement='right'>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    color={theme.palette.primary.contrastText}
                                    size='lg'
                                    icon={faCalendarCheck}
                                />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Reservaciones" />
                    </ListItem>
                </>
            }
            
            
            <ListItem button onClick={()=>redireccionar('/events')}>
                <Tooltip title='Eventos' placement='right'>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            color={theme.palette.primary.contrastText}
                            size='lg'
                            icon={faTicket}
                        />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Eventos" />
            </ListItem>

            {
                userData.user.idTipoUsuario === 4 &&
                <ListItem button onClick={()=>redireccionar('/tourism')}>
                    <Tooltip title='Sitios Turísticos' placement='right'>
                        <ListItemIcon>
                            <FontAwesomeIcon
                                color={theme.palette.primary.contrastText}
                                size='lg'
                                icon={faMapLocation}
                            />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Sitios Turísticos" />
                </ListItem>
            }


        </div>
    )    
}
    