import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Avatar, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit, faGift, faPlay, faSmile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { GetProductos } from '../../../controllers/company/ProductsController';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';

//export default class TablaProductos extends Component
export default function CouponsDataTable(props)
{
    const coupons = props.coupons;

    const columns = [
        {
            name: 'created_at',
            label: 'Fecha de creación',
            options:
            {
                filter: false
            }
        },
        {
            name : 'title',
            label : 'Cupón',
            
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography><strong style={{fontSize:17}}>{coupons[dataIndex].title}</strong></Typography>
                            <Typography>{coupons[dataIndex].description}</Typography>
                        </div>
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'start_date',
            label : 'desde',
            options:{
                filter:false,
                //filterType:'dropdown',
                searchable:true
            }
        },
        {
            name : 'end_date',
            label : 'hasta',
            options:{
                filter:false,
                //filterType:'dropdown',
                searchable:true
            }
        },
        {
            name : 'receiver',
            label : 'Receptor',
            options:{
                filter:false,
                //filterType:'dropdown',
                searchable:true
            }
        },
        {
            name : 'status',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => {
                    if(value === 'Anulado')
                        return(
                            <Avatar sx={{bgcolor:'error.main'}}>
                                <FontAwesomeIcon icon={faBan}/>
                            </Avatar>
                        )
                    else if(value === 'Emitido')
                        return(
                            <Avatar sx={{bgcolor:'primary.main'}}>
                                <FontAwesomeIcon icon={faGift}/>
                            </Avatar>
                        )
                    else
                        return(
                            <Avatar sx={{bgcolor:'success.main'}}>
                                <FontAwesomeIcon icon={faSmile}/>
                            </Avatar>
                        )
                }
            }
        },
        
    ];

  


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={"Listado de Productos"}
            data={coupons}
            columns={columns}
            options={options}
        />
    )
}