import { useEffect, useState } from "react";
import { Box, Button, Container, MenuItem, Paper, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ClientOrdersDataTable from "./dataTable";
import QUDProgress from "../../../components/progress";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getOrders } from "../../../controllers/customer/PedidosController";

export default function ClientOrders()
{
    const [isLoading,setIsLoading] = useState(false);
    const [orders,setOrders] = useState([]);

    const {tipo_pedido} = useParams();

    useEffect(()=>{
        handleGetOrders();
    },[tipo_pedido]);

    const handleGetOrders = async () => {
        setIsLoading(true);
        let response = await getOrders(tipo_pedido);
        if(response.success === true)
        {
            setOrders(response.data);
        }
        setIsLoading(false);
    }
    
    const type = ['Boletos','Taxis','Pedidos'];

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Pedidos',link:'/orders'}]}/>
            <br/>
            <SectionTitle title={`Mis ${type[tipo_pedido-1]}`}/>
            <Grid container spacing={3}>
                <Grid xs={12}>
                    {
                        orders.length !== 0 &&
                        <ClientOrdersDataTable orders={orders}/>
                    }
                    {
                        (isLoading === false && orders.length === 0) &&
                        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:5,mb:10}}>
                            <FontAwesomeIcon size="3x" icon={faTriangleExclamation}/>
                            <Typography variant="h4" textAlign={'center'}>No se encontraron resultados</Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
            <QUDProgress open={isLoading}/>
        </Container>
    )
}